import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'common/styling/theme';

import { VerificationPanel, SecurityPanelContent, TwoStepSecurityPanel } from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const ChangePasswordPage = ({ profile, tfAuthSettings, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const isTfaSetupRequired = Boolean(profile && !profile?.tfaSettings?.enabled && profile?.tfaSettings?.setupRequired);

  const isTfaEnabled = profile?.tfaSettings?.enabled;
  const selectedType = profile?.tfaSettings?.type;

  return (
    <VerificationPanel captionId="justSecurity" warningMessageTextId="justSecurity">
      {isTfaSetupRequired && (
        <div className="TfaWarning">
          <FormattedMessage id="authYouNeedEnableTfa">
            {txt => <h3 className="TfaWarning__title">{txt}</h3>}
          </FormattedMessage>
        </div>
      )}
      <SecurityPanelContent />
      {typeof tfAuthSettings?.mandatory !== 'undefined' && (
        <TwoStepSecurityPanel
          isTfaEnabled={isTfaEnabled}
          selectedType={selectedType}
          initialValues={{ isEnabled: isTfaEnabled }}
        />
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </VerificationPanel>
  );
};

ChangePasswordPage.propTypes = {
  profile: PropTypes.object,
  theme: PropTypes.object,
  tfAuthSettings: PropTypes.object,
};

ChangePasswordPage.defaultProps = {
  profile: {},
  theme: {},
  tfAuthSettings: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    profile: state.user.profile,
    tfAuthSettings: state.interfaceConfig.tfAuthSettings,
  }))
)(ChangePasswordPage);
export { ChangePasswordPage };
