import css from 'styled-jsx/css';

const staticStyles = css`
  .ResetTwoFactorAuthSuccess {
    display: flex;
    justify-content: center;
  }
`;

export { staticStyles };
