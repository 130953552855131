import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates-gte-react-17';
import Select from 'react-select';
import { injectIntl } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import CalendarIcon from '../DateRangeField/CalendarIcon';
import { staticStyles, getDynamicStyles } from './style';

const formatDate = (firstDate, secondDate) =>
  `${firstDate.utc().format('YYYY-MM-DD')}_${secondDate.utc().format('YYYY-MM-DD')}`;

const monthOptions = moment.monthsShort().map((label, value) => ({ value, label }));
const yearsOptions = [...Array(20).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const convertToDisplay = rangeDate => {
  const dates = rangeDate.split('_');
  const startDateStr = dates[0] ? moment(dates[0]).format('ll') : '';
  const endDateStr = dates[1] ? moment(dates[1]).format('ll') : '';

  return `${startDateStr} - ${endDateStr}`;
};

const BaseDateRangeField = ({ placeholderId, value, onChange, disabled, areFutureDaysAvailable, intl, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [dateStr, setDateStr] = useState(value ? convertToDisplay(value) : intl.formatMessage({ id: placeholderId }));
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startDateStr = startDate ? startDate.format('ll') : '';
    const endDateStr = endDate ? endDate.format('ll') : '';
    setDateStr(`${startDateStr} - ${endDateStr}`);
    if (startDate && endDate) {
      onChange(formatDate(startDate, endDate));
      setIsCalendarVisible(false);
    }
  };

  const handleFocusChange = focused => {
    setFocusedInput(focused || 'startDate');
  };

  const toggleCalendar = () => {
    if (!isCalendarVisible) {
      setStartDate(null);
      setEndDate(null);
    }
    setIsCalendarVisible(!isCalendarVisible);
  };

  const isOutsideRange = day => {
    if (areFutureDaysAvailable) return false;
    return day.isAfter(moment()) && !moment(day).isSame(moment(), 'day');
  };

  /* eslint-disable */
  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div className="DateRangeField__years-navigation">
      <div className="DateRangeField__month-inner">
        <Select
          value={monthOptions[month.month()]}
          onChange={e => onMonthSelect(month, e.value)}
          isClearable={false}
          isSearchable={false}
          noOptionsMessage={() => null}
          hideSelectedOptions
          menuPlacement="bottom"
          placeholder=""
          classNamePrefix="Select"
          maxMenuHeight={240}
          options={monthOptions}
        />
      </div>
      <div className="DateField__years-inner">
        <Select
          value={{ value: month.year(), label: month.year() }}
          onChange={e => {
            onYearSelect(month, e.value);
          }}
          isClearable={false}
          isSearchable={false}
          noOptionsMessage={() => null}
          hideSelectedOptions
          menuPlacement="bottom"
          placeholder=""
          classNamePrefix="Select"
          maxMenuHeight={240}
          options={yearsOptions}
        />
      </div>
    </div>
  );
  /* eslint-enable */

  return (
    <div className="DateRangeField">
      <button
        className={classNames(
          'DateRangeField__picker',
          { 'DateRangeField__picker-disabled': disabled },
          { active: isCalendarVisible },
          { haveText: value }
        )}
        type="button"
        onClick={toggleCalendar}>
        <span className="DateRangeField__picker-text">{dateStr}</span>
        <CalendarIcon />
      </button>
      <div className="DateRangeField__inner">
        {isCalendarVisible && (
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDateChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            minimumNights={0}
            numberOfMonths={1}
            renderMonthElement={renderMonthElement}
            isOutsideRange={isOutsideRange}
            disabled={disabled}
            hideKeyboardShortcutsPanel
          />
        )}
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BaseDateRangeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  placeholderId: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  areFutureDaysAvailable: PropTypes.bool,
  theme: PropTypes.object,
};

BaseDateRangeField.defaultProps = {
  value: '',
  disabled: false,
  areFutureDaysAvailable: false,
  theme: {},
};

export default withTheme()(injectIntl(BaseDateRangeField));
export { BaseDateRangeField };
