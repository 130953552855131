import css from 'styled-jsx/css';
import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .DateRangeField {
    position: relative;
    margin-bottom: 10px;

    .DateRangeField__inner {
      direction: ltr;
      position: absolute;
      z-index: 10;
    }
    .DateRangeField__inner--relative {
      position: relative !important;
    }

    .DayPicker_weekHeader {
      top: 65px;
      z-index: 0;
    }

    .DayPicker_weekHeader_li {
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
    }

    .CalendarMonthGrid__horizontal {
      top: 15%;
    }

    .CalendarMonthGrid_month__horizontal {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        .CalendarMonth_caption {
          position: absolute;
          z-index: 10 !important;
          top: -35px;
          left: 70px;
        }
      }

      &:nth-child(3) {
        display: none;
      }
    }

    .CalendarMonth_table {
      position: absolute;
      top: 50px;
    }

    .DayPickerNavigation_button {
      z-index: 11 !important;
      top: 5%;

      &:nth-child(1) {
        left: 5%;
      }

      &:nth-child(2) {
        right: 5%;
      }
    }

    .DateRangeField__years-navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      select {
        border: none;
        background: none;
      }
      .Select__value-container {
        font-size: 14px;
        padding: 0 !important;
        width: 50px;
      }
      .Select__control {
        border: none;
      }
      .Select__menu {
        font-size: 14px;
        z-index: 10 !important;
        cursor: pointer;
      }
      .Select__option {
        padding: rem(10px 5px) !important;
      }
      .DateRangeField__month-inner {
        > div {
          width: 70px;
          margin-right: 10px;
        }
      }
      .DateRangeField__years-inner {
        width: 80px !important;
      }
    }

    .DateRangeField__picker {
      display: flex;
      width: 320px;
      align-items: center;
      padding-left: ${isRTL ? '0' : '10px'};
      padding-right: ${isRTL ? '10px' : '0'};
      position: relative;
      transition: all 0.4s ease;
      background-color: transparent;
      border: 1px solid;
      min-height: rem(54px);
      cursor: pointer;

      .CalendarIcon {
        svg {
          position: absolute;
          right: rem(${isRTL ? 'auto' : '10px'});
          left: rem(${isRTL ? '10px' : 'auto'});
          top: rem(17px);
          width: 18px;
          height: 17px;
          g {
            path {
              stroke: transparent !important;
            }
          }
        }
      }
    }

    .DateRangeField__presets {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .DefaultButton {
        width: 120px;
        margin: 5px !important;
        padding: 5px 10px;
      }
    }

    .CalendarDay__default {
      border: 1px solid transparent !important;
      font-weight: 500 !important;
      transition: all 0.4s ease !important;
    }

    .CalendarDay__blocked_out_of_range {
      cursor: not-allowed;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .DateRangeField__picker {
      border-radius: ${border.radius};
      border-color: ${colors.lightGray.hex} !important;
      &.active {
        border-color: ${colors.primary.hex} !important;
        .DateRangeField__mobile {
          color: ${colors.black.hex};
        }
        .CalendarIcon {
          svg {
            g {
              path {
                fill: ${colors.primary.hex};
              }
            }
            path {
              stroke: ${colors.primary.hex};
            }
          }
        }
        .DateRangeField__picker-text {
          color: ${colors.primary.hex};
        }
      }

      .DateRangeField__picker-error {
        border-color: ${colors.error.hex} !important;
        .DateInput_input,
        .DateField__mobile {
          color: ${colors.white.hex} !important;
          &::placeholder {
            color: ${colors.white.hex} !important;
          }
        }
        .CalendarIcon {
          svg {
            g {
              path {
                fill: ${colors.error.hex};
              }
            }
            path {
              stroke: ${colors.error.hex};
            }
          }
        }
      }

      .DateRangeField__picker-text {
        color: ${colors.gray.hex};
        font-size: 15px;
      }
    }

    .DateRangeField__picker-disabled {
      background: ${colors.disabled.hex};
      .DateRangeField__mobile {
        background: ${colors.disabled.hex};
        color: ${colors.disabled.hex};
      }
      .CalendarIcon {
        svg {
          g {
            path {
              fill: ${colors.gray.hex};
            }
          }
          path {
            stroke: ${colors.gray.hex};
          }
        }
      }
      &.haveText {
        .DateRangeField__mobile {
          color: ${colors.black.hex};
        }
      }
    }

    .DayPicker_weekHeader {
      color: ${colors.primary.hex};
    }

    .CalendarDay__selected {
      background: ${colors.primary.hex};
      border-color: ${colors.primary.hex};
      &:hover {
        background: ${colors.secondary.hex};
        border-color: ${colors.secondary.hex};
      }
    }

    .DateRangeField__error {
      border-color: ${colors.error.hex} !important;
      .DateInput_input,
      .DateRangeField__mobile {
        color: ${colors.white.hex} !important;
        &::placeholder {
          color: ${colors.white.hex} !important;
        }
      }
      .CalendarIcon {
        svg {
          g {
            path {
              fill: ${colors.error.hex};
            }
          }
          path {
            stroke: ${colors.error.hex};
          }
        }
      }
      .DateRangeField__label {
        color: ${colors.error.hex} !important;
      }
    }

    .CalendarDay__default {
      color: ${colors.gray.hex} !important;
    }

    .CalendarDay__default:hover {
      background: ${rgba([colors.gradientEnd.rgb, 0.25])} !important;
    }

    .CalendarDay__selected {
      background: ${rgba([colors.gradientEnd.rgb, 0.45])} !important;
    }

    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: ${rgba([colors.gradientEnd.rgb, 0.6])} !important;
    }

    .CalendarDay__selected_span {
      background: ${rgba([colors.gradientEnd.rgb, 0.25])} !important;
      &:hover {
        background: ${rgba([colors.gradientEnd.rgb, 0.45])} !important;
      }
    }

    .CalendarDay__blocked_out_of_range {
      background: ${rgba([colors.lightGray.rgb, 0.4])} !important;
      &:hover {
        background: ${rgba([colors.lightGray.rgb, 0.4])} !important;
      }
    }

    .Calendar__data-info {
      border-radius: ${border.radius};
      border: 1px solid ${colors.primary.hex};
      color: ${colors.primary.hex};
      &:hover {
        border-color: ${rgba([colors.primary.rgb, 0.8])};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
        color: ${rgba([colors.primary.rgb, 0.8])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
