import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { createInvestNewExports } from 'common/_redux';
import { withTheme } from 'common/styling/theme';
import { ModalHOC } from 'common';
import { Collapsible, TableLoader, RadioButton, DateRangeField, DefaultButton, BasicModal } from 'common/components';
import { FormattedMessage } from 'react-intl';

import { getMyInvestStrategiesNew, setInvestStrategyActionNew } from 'client/investNew/_redux';
import InvestStrategiesTable from '../InvestStrategiesTable';

import { staticStyles, getDynamicStyles } from './style';

const yearsOptions = [...Array(10).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const InvestStrategies = ({
  accounts,
  myInvestStrategiesNew,
  myInvestStrategiesNewAreLoaded,
  getMyInvestStrategiesNew,
  setInvestStrategyActionNew,
  createInvestNewExports,
  showModal,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [activeTab, setActiveTab] = useState('active');

  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(
    (query, tab) => {
      getMyInvestStrategiesNew({ is_active: tab === 'active' ? 'True' : 'False', ...query });
    },
    [getMyInvestStrategiesNew]
  );

  const getTableList = useCallback(
    query => {
      getMyInvestStrategiesNew({ is_active: activeTab === 'active' ? 'True' : 'False', ...query });
    },
    [getMyInvestStrategiesNew, activeTab]
  );

  const handleOpenCollapse = () => {
    getList(query, activeTab);
  };

  const onChangeStrategyAction = (e, strategyId, action, isRollover) => {
    e.stopPropagation();

    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: action === 'close' ? 'rammConfirmStrategyActionClose' : 'rammConfirmStrategyAction',
        ...(action !== 'close' && {
          values: {
            action,
          },
        }),
      },
      description: {
        id: isRollover && action === 'close' ? 'investRolloversCloseWarning' : '',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => setInvestStrategyActionNew({ strategyId, action }),
        type: action === 'close' ? 'error' : '',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const buttons = [
    {
      type: 'investments',
      textId: 'rammListOfInvestments',
    },

    { type: 'justExportXlsx', textId: 'justExportXlsx' },
    { type: 'justExportCsv', textId: 'justExportCsv' },
  ];

  const [exportExt, setExportExt] = useState(null);
  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState();

  const [isCreateFullExport, setIsCreateFullExport] = useState(true);

  useEffect(() => {
    if (isExportMenuModalOpen) {
      setSelectedTimePeriod(
        `${moment(moment().add(-7, 'day').startOf('day')).format('YYYY-MM-DD')}_${moment(
          moment().startOf('day')
        ).format('YYYY-MM-DD')}`
      );
    }
  }, [isExportMenuModalOpen]);

  const [selectedStrategyId, setSelectedStrategyId] = useState(null);

  const handleAction = ({ e, id, settings, action }) => {
    e.stopPropagation();

    switch (action) {
      case 'investments':
        history.push(`/investnew/${id}`);
        break;
      case 'justExportXlsx':
        setSelectedStrategyId(id);
        setIsExportMenuModalOpen(true);
        setExportExt('xlsx');
        break;

      case 'justExportCsv':
        setSelectedStrategyId(id);
        setIsExportMenuModalOpen(true);
        setExportExt('xlsx');
        break;
      case 'resume':
        onChangeStrategyAction(e, id, action);
        break;
      case 'close':
        onChangeStrategyAction(e, id, action, settings.rollovers);
        break;
      default:
        break;
    }
  };

  return (
    <div className="InvestStrategies">
      <Collapsible captionId="justStrategies" handleOpen={handleOpenCollapse}>
        <div className="InvestStrategies__tabs">
          <FormattedMessage id="justActive">
            {txt => (
              <button
                type="button"
                className={classNames('InvestStrategies__tab', {
                  active: activeTab === 'active',
                })}
                onClick={() => {
                  setActiveTab('active');
                  getList(query, 'active');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="justClosed">
            {txt => (
              <button
                type="button"
                className={classNames('InvestStrategies__tab', {
                  active: activeTab === 'closed',
                })}
                onClick={() => {
                  setActiveTab('closed');
                  getList(query, 'closed');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        {myInvestStrategiesNewAreLoaded ? (
          <InvestStrategiesTable
            buttons={buttons}
            activeTab={activeTab}
            data={myInvestStrategiesNew}
            loading={!myInvestStrategiesNewAreLoaded}
            location={location}
            history={history}
            getList={getTableList}
            onRowClick={strategyId => history.push(`/investnew/${strategyId}/`)}
            params={{
              search: query,
            }}
            accounts={accounts}
            onTableAction={handleAction}
          />
        ) : (
          <TableLoader />
        )}
      </Collapsible>
      <BasicModal
        captionId="selectTimePeriodOfExport"
        isOpen={isExportMenuModalOpen}
        onRequestClose={() => setIsExportMenuModalOpen(false)}>
        <div className="UserPageExports">
          <RadioButton
            name="investFrom"
            textId="createExportForAllTime"
            value="all"
            onChange={() => setIsCreateFullExport(true)}
            checked={isCreateFullExport}
          />

          <RadioButton
            name="investFrom"
            textId="createExportForTimePeriod"
            value="all"
            onChange={() => setIsCreateFullExport(false)}
            checked={!isCreateFullExport}
          />
          <div style={{ width: 'max-content', display: !isCreateFullExport ? 'flex' : 'none' }}>
            <DateRangeField
              yearsOptions={yearsOptions}
              input={{ value: selectedTimePeriod, onChange: setSelectedTimePeriod }}
              meta={{ touched: true, error: false }}
              noRedux
              relativeInner
              daysLimit={30}
            />
          </div>

          <DefaultButton
            textId="justExport"
            type="button"
            filled
            disabled={!selectedTimePeriod && !isCreateFullExport}
            onClick={() => {
              setIsExportMenuModalOpen(false);
              createInvestNewExports({
                timePeriod: isCreateFullExport ? undefined : selectedTimePeriod,
                strategyId: selectedStrategyId,
                ext: exportExt,
              });
            }}
          />
        </div>
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestStrategies.propTypes = {
  accounts: PropTypes.array.isRequired,
  myInvestStrategiesNew: PropTypes.object.isRequired,
  myInvestStrategiesNewAreLoaded: PropTypes.bool.isRequired,
  getMyInvestStrategiesNew: PropTypes.func.isRequired,
  setInvestStrategyActionNew: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestStrategies.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      accounts: state.client.common.accounts,
      addons: state.interfaceConfig.addons,
      myInvestStrategiesNew: state.client.investNew.myInvestStrategiesNew,
      myInvestStrategiesNewAreLoaded: state.client.investNew.myInvestStrategiesNewAreLoaded,
    }),
    {
      createInvestNewExports: ({ timePeriod, investmentId, strategyId, ext }) =>
        createInvestNewExports.request({ timePeriod, investmentId, strategyId, ext }),
      getMyInvestStrategiesNew: data => getMyInvestStrategiesNew.request(data),
      setInvestStrategyActionNew: data => setInvestStrategyActionNew.request(data),
    }
  )
)(InvestStrategies);
export { InvestStrategies };
