import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as actions from './actions';

const initialState = {
  adminEventList: {},
  adminEventListLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ADMIN_EVENT_LIST[REQUEST]:
      return { ...state, adminEventListLoaded: false };
    case actions.GET_ADMIN_EVENT_LIST[SUCCESS]:
      return { ...state, adminEventList: action.data, adminEventListLoaded: true };
    case actions.GET_ADMIN_EVENT_LIST[FAILURE]:
      return { ...state, error: action.error, adminEventListLoaded: false };

    default:
      return state;
  }
};

export default reducer;
