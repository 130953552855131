import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import DateSelector from './_components/DateSelector';

import { staticStyles } from './style';

const InputDate = ({ name, options, onSelect, isDisabled, className, withResetButton }) => (
  <div className={classnames('InputDate', className)}>
    <div className="InputDate__Title">
      <FM id={name}>{txt => <span className="InputDate__Title-text">{txt}</span>}</FM>
    </div>
    <DateSelector
      withResetButton={withResetButton}
      isDisabled={isDisabled}
      options={options}
      onSelect={onSelect}
      dropDownTitle={name}
    />
    <style jsx>{staticStyles}</style>
  </div>
);

InputDate.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  withResetButton: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

InputDate.defaultProps = {
  isDisabled: false,
  withResetButton: false,
  className: '',
};

export default InputDate;
