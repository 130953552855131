import React, { useMemo, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import classNames from 'classnames';

import { PageLayout, PageContentPreloader, SearchPanel, NoItemsPanel } from 'common/components';

import noPartnersImage from 'common/images/noPartners.svg';
import { PartnershipUsersTable, ClientsBalanceTab } from '../../_components';

import { getPartnershipClients, getPartnershipClientsBalance } from '../../_redux';

const PartnershipClientsPage = ({
  getPartnershipClientsBalance,
  partnershipClientsBalance,
  getPartnershipClients,
  partnershipClients,
  viewClientsUserTags,
  partnershipClientsAreLoaded,
  viewEmailPermission,
  viewPhonePermission,
  viewSubIbTreePermission,
  partnershipNetworkBalance,
  location,
  history,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(query => getPartnershipClients(query), [getPartnershipClients]);
  useEffect(() => {
    if (partnershipNetworkBalance) {
      getPartnershipClientsBalance();
    }
  }, []);

  useEffect(() => {
    getList({ search: query });
  }, [getList, query]);

  return (
    <PageLayout captionSlug="partnershipClients">
      <div
        className={classNames({
          PartnershipUsersTable__inner: partnershipClients.count,
        })}>
        <PageContentPreloader ready={partnershipClientsAreLoaded} firstLaunchOnly>
          {(!!partnershipClients.count || !!Object.keys(query).length) && (
            <SearchPanel
              location={location}
              history={history}
              getList={getList}
              filterName="status"
              params={{ search: query }}
            />
          )}
          {partnershipNetworkBalance && <ClientsBalanceTab partnershipClientsBalance={partnershipClientsBalance} />}
          {partnershipClients.count ? (
            <PartnershipUsersTable
              data={partnershipClients}
              getList={getList}
              loading={!partnershipClientsAreLoaded}
              location={location}
              history={history}
              params={{ search: query }}
              viewEmailPermission={viewEmailPermission}
              viewPhonePermission={viewPhonePermission}
              viewSubIbTreePermission={viewSubIbTreePermission}
              viewClientsUserTags={viewClientsUserTags}
            />
          ) : (
            <NoItemsPanel
              icon={noPartnersImage}
              captionSlug={
                Object.keys(query).length
                  ? 'partnershipNoClientsCaptionMatchingTheParameters'
                  : 'partnershipNoClientsCaption'
              }
              descriptionSlug={!Object.keys(query).length && 'partnershipNoClientsDescription'}
            />
          )}
        </PageContentPreloader>
      </div>
    </PageLayout>
  );
};

PartnershipClientsPage.propTypes = {
  getPartnershipClientsBalance: PropTypes.func.isRequired,
  getPartnershipClients: PropTypes.func.isRequired,
  partnershipClientsBalance: PropTypes.object,
  partnershipClients: PropTypes.object.isRequired,
  partnershipClientsAreLoaded: PropTypes.bool.isRequired,
  viewEmailPermission: PropTypes.bool.isRequired,
  viewPhonePermission: PropTypes.bool.isRequired,
  viewSubIbTreePermission: PropTypes.bool.isRequired,
  partnershipNetworkBalance: PropTypes.bool.isRequired,
  viewClientsUserTags: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

PartnershipClientsPage.defaultProps = {
  partnershipClientsBalance: null,
};

export default connect(
  state => ({
    partnershipClientsAreLoaded: state.client.partnership.partnershipClientsAreLoaded,
    partnershipClientsBalance: state.client.partnership.partnershipClientsBalance,
    partnershipClients: state.client.partnership.partnershipClients,
    viewDetailsPermission: state.interfaceConfig.partnership.viewDetailsPermission,
    viewEmailPermission: state.interfaceConfig.partnership.viewEmailPermission,
    viewPhonePermission: state.interfaceConfig.partnership.viewPhonePermission,
    viewClientsUserTags: state.interfaceConfig.partnership.viewClientsUserTags,
    viewSubIbTreePermission: state.interfaceConfig.partnership.viewSubIbTreePermission,
    partnershipNetworkBalance: state.interfaceConfig.features.includes('partnershipNetworkBalance'),
  }),
  {
    getPartnershipClientsBalance: () => getPartnershipClientsBalance.request(),
    getPartnershipClients: data => getPartnershipClients.request(data),
  }
)(PartnershipClientsPage);
export { PartnershipClientsPage };
