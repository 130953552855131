import * as ActionTypes from './actions';

export const initialState = {
  canMoveToVerifyStep: false,
  tfaContent: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.setTfaType.SUCCESS:
      return { ...state, tfaContent: action.payload };

    case ActionTypes.requestChangeTfaType.SUCCESS:
      return { ...state, canMoveToVerifyStep: true };

    case ActionTypes.SET_INITIAL_EMAIL_VERIFY_STEP:
      return { ...state, canMoveToVerifyStep: false };

    case ActionTypes.SET_EMAIL_VERIFY_STEP:
      return { ...state, canMoveToVerifyStep: true };

    default:
      return state;
  }
};

export default reducer;
