import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { DateCell, ActionsButton } from 'common/components';
import { EditIcon, BasketIcon, AttachIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import UserNoteDocuments from '../UserNoteDocuments';
import { staticStyles, getDynamicStyles } from './style';

const UserNote = ({
  noteId,
  created,
  createdBy,
  title,
  text,
  documents,
  isPinned,
  paymentRequest,
  internalTransfer,
  canEdit,
  canDelete,
  handleDownloadNote,
  handleEditNote,
  handleDeleteNote,
  handleRemoveNoteDocument,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const paymentOperations = {
    deposit: { link: '/backoffice/deposits', textId: 'justDeposit' },
    withdraw: { link: '/backoffice/withdrawals/list', textId: 'justWithdrawal' },
  };

  const paymentOperation = useMemo(() => {
    if (paymentRequest || internalTransfer) {
      const textId = internalTransfer ? 'boInternalTransfer' : paymentOperations[paymentRequest.operation].textId;
      const paymentLink = internalTransfer
        ? `/backoffice/transfers/${internalTransfer.id}`
        : `${paymentOperations[paymentRequest.operation].link}/${paymentRequest.id}`;
      const amount = internalTransfer
        ? `${internalTransfer.receiveAmount.amount} ${internalTransfer.receiveAmount.currency}`
        : `$${paymentRequest.amountInUsd.amount}`;

      return (
        <>
          <div className="UserNote__payment-link">
            <FormattedMessage id={textId} />
            <Link to={paymentLink}>#{internalTransfer ? internalTransfer.id : paymentRequest.id}</Link>
          </div>
          <span>{amount}</span>
        </>
      );
    }

    return null;
  }, [paymentRequest, internalTransfer]);

  return (
    <div className="UserNote">
      <div className="UserNote__content">
        {isPinned && (
          <div className="UserNote__content-isPinned">
            <AttachIcon />
          </div>
        )}
        <div className="UserNote__top-line">
          <div className="UserNote__createdBy">
            <div className="UserNote__avatar">
              <Gravatar md5={md5(createdBy.email)} size={60} />
            </div>
            <div className="UserNote__line">
              <FormattedMessage id="justAuthor" />
              <p>{createdBy.email}</p>
              {createdBy.departments?.length > 0 && (
                <FormattedMessage id="justDepartments">
                  {txt => (
                    <span className="UserNote__createdBy__departments">
                      {txt}: {createdBy.departments?.join(', ')}
                    </span>
                  )}
                </FormattedMessage>
              )}
            </div>
          </div>

          <div className="UserNote__line UserNote__title">
            <p>{title}</p>
          </div>

          <div className="UserNote__line UserNote__data-created">
            <DateCell value={created} />
          </div>

          <div className="UserNote__line UserNote__payment">{paymentOperation || '---'}</div>

          <div className="UserNote__line UserNote__data-action">
            {canEdit && (
              <ActionsButton
                tooltipId="Edit__tooltip"
                tooltipTextId="justEditNote"
                edit
                onClickFunc={() => handleEditNote(noteId)}>
                <EditIcon />
              </ActionsButton>
            )}
            {canDelete && (
              <ActionsButton
                tooltipId="Delete__tooltip"
                tooltipTextId="justDeleteNote"
                deleteType
                onClickFunc={() => handleDeleteNote(noteId)}>
                <BasketIcon />
              </ActionsButton>
            )}
          </div>
        </div>
        <div className="UserNote__bottom-line">
          <FormattedMessage id="justText" />
          <pre>{text}</pre>
        </div>
        {!!documents.length && (
          <div className="UserNote__files">
            <FormattedMessage id="menuTitleFiles" />
            <UserNoteDocuments
              documents={documents}
              onDownload={handleDownloadNote}
              onRemove={handleRemoveNoteDocument}
            />
          </div>
        )}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserNote.propTypes = {
  noteId: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isPinned: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired,
  handleDownloadNote: PropTypes.func.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
  handleRemoveNoteDocument: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  internalTransfer: PropTypes.object,
  paymentRequest: PropTypes.object,
  theme: PropTypes.object,
};

UserNote.defaultProps = {
  internalTransfer: null,
  paymentRequest: null,
  canEdit: false,
  canDelete: false,
  theme: {},
};

export default withTheme()(UserNote);
export { UserNote };
