import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  .TfaWarning {
    padding: 24px;
    margin: 25px 0 0;

    &__title {
      text-align: center;
      font-size: 18px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TfaWarning {
      background: ${rgba([colors.warning.rgb, 0.35])};
    }
  `;
};

export { staticStyles, getDynamicStyles };
