import React, { useEffect } from 'react';
import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { connect } from 'react-redux';
import { required } from 'redux-form-validators';
import { reduxForm, Field } from 'redux-form';
import { DefaultButton, ReduxTextField } from 'common/components';

import { manualWithdrawProfitFromChallenge, manualWithdrawActionErrorClear } from '../../_redux/actions';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ManualWithdrawProfitFromChallengeForm = ({
  userChallengeId,
  handleSubmit,
  submitting,
  manualWithdrawError,
  dispatch,
}) => {
  const submit = handleSubmit(({ amount }) => manualWithdrawProfitFromChallenge({ userChallengeId, amount }, dispatch));

  useEffect(() => () => dispatch(manualWithdrawActionErrorClear()), []);

  return (
    <form onSubmit={submit} className="ManualWithdrawProfitFromChallengeForm">
      <Field
        name="amount"
        type="number"
        textId="justAmount"
        component={ReduxTextField}
        validate={[required()]}
        disabled={submitting}
      />
      <div className="ManualWithdrawProfitFromChallengeForm__button-inner">
        <DefaultButton textId="justWithdraw" type="submit" disabled={submitting} loading={submitting} filled />
      </div>
      {manualWithdrawError && (
        <strong className="formError" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {manualWithdrawError.map((error, index) => {
            const customError =
              isHero && error === 'Profit should be more than target'
                ? 'Only Profits above your Initial Balance are withdrawable.'
                : error;

            return <span key={index}>{customError}</span>;
          })}
        </strong>
      )}
    </form>
  );
};

ManualWithdrawProfitFromChallengeForm.defaultProps = {
  error: '',
  manualWithdrawError: null,
};

export default compose(
  FormMessageHOC,
  connect(state => ({ manualWithdrawError: state.client.challenges.manualWithdrawError }), null),
  reduxForm({
    form: 'ManualWithdrawProfitFromChallengeFormModal',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(ManualWithdrawProfitFromChallengeForm);
