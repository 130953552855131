import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextAreaField {
    position: relative;
    margin-bottom: 10px;
    textarea:disabled {
      opacity: 1 !important;
    }
    textarea:disabled + label {
      cursor: default !important;
    }
  }

  .TextAreaField__label {
    display: inline-block;
    position: absolute;
    top: rem(17px);
    left: rem(${isRTL ? 'auto' : '13px'});
    right: rem(${isRTL ? '13px' : 'auto'});
    transition: all 0.25s ease;
    z-index: 5;
    cursor: text;
    &.haveText {
      top: -20px;
      font-size: rem(12px);
    }
    &.active {
      top: -20px;
      font-size: rem(12px);
    }
  }

  .TextAreaField__error {
    width: 100%;
    display: inline-block;
  }

  .TextAreaField__inp {
    &:-webkit-autofill + .TextAreaField__label {
      top: 6px;
      font-size: rem(12px);
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .TextAreaField__label {
      transition: none;
    }
  }

  @supports (-ms-ime-align: auto) {
    .TextAreaField__label {
      transition: none;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .TextAreaField {
      textarea:focus {
        border-color: ${colors.primary.hex};
      }

      &__error {
        textarea,
        textarea:focus {
          border-color: ${colors.error.hex};
        }
        label {
          color: ${colors.error.hex};
        }
      }
      &__haveText {
        textarea {
          color: ${colors.black.hex} !important;
        }
      }
      textarea:disabled {
        background: ${colors.disabled.hex};
        color: ${colors.black.hex};
        -webkit-text-fill-color: ${colors.black.hex};
      }
    }

    .TextAreaField__label {
      color: ${colors.gray.hex};
      &.haveText {
        color: ${colors.gray.hex};
      }
      &.active {
        color: ${colors.primary.hex};
      }
    }

    .TextAreaField__error {
      color: ${colors.error.hex};
      label {
        color: ${colors.error.hex};
      }
      textarea {
        border-color: ${colors.error.hex};
      }
    }

    .TextAreaField__inp {
      color: ${colors.white.hex};
      &.active {
        color: ${colors.black.hex};
      }
      &.haveText {
        color: ${colors.black.hex};
      }
      &:-webkit-autofill + .TextAreaField__label {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
