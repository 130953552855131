import React from 'react';
import { DefaultButton } from 'common/components';
import { injectIntl } from 'react-intl';

import { staticStyles } from './style';

const BudgetSelector = ({ data, onChange, selectedChallengeId, intl }) => (
  <div className="BudgetSelector">
    <div className="BudgetSelector__ChooseBudgetText">Choose budget:</div>
    <div className="BudgetSelector__Values">
      {data.map(({ id, initialAccountBalance }) => (
        <DefaultButton
          key={`${id} BudgetSelector`}
          selected={selectedChallengeId === id}
          textId={`${intl.formatNumber(initialAccountBalance.amount)} ${initialAccountBalance.currency}`}
          noTranslate
          onClick={() => onChange(id)}
        />
      ))}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default injectIntl(BudgetSelector);
