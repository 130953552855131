import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'redux';
import { TwoStepEmailForm, TwoStepGoogleForm } from 'auth/twoStepAuth/_components';

import { verifyTfaType, setTfaType, requestChangeTfaType } from 'auth/twoStepAuth/_redux';
import { resendOTPToken } from 'auth/login/_redux';

import { staticStyles, getDynamicStyles } from './style';

const ChangingAuthMethod = ({ onSuccess, onClose, selectedMethod, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step === 2 && selectedMethod === 'totp') {
      dispatch(setTfaType.request({ otpType: 'totp' }));
    }
  }, [step]);

  return (
    <div className="ChangingAuthMethod">
      <div className="ChangingAuthMethod__steps">
        <div className={classNames('ChangingAuthMethod__step', { ChangingAuthMethod__step__active: step === 1 })}>
          <div className="ChangingAuthMethod__step__number">1</div>
          <FormattedMessage id="authVerification" />
        </div>
        <div className="ChangingAuthMethod__steps__delimeter" />
        <div className={classNames('ChangingAuthMethod__step', { ChangingAuthMethod__step__active: step === 2 })}>
          <div className="ChangingAuthMethod__step__number">2</div>
          <FormattedMessage id="authMethodChanging" />
        </div>
      </div>
      {selectedMethod === 'email' && (
        <>
          {step === 1 && (
            <TwoStepGoogleForm
              isChangingFirstStep
              onSuccess={() => setStep(2)}
              onSubmit={verifyTfaType}
              onClose={onClose}
            />
          )}
          {step === 2 && (
            <TwoStepEmailForm
              onResend={() => resendOTPToken.request()}
              onGetCode={() => setTfaType.request({ otpType: 'email' })}
              onSuccess={onSuccess}
              onSubmit={verifyTfaType}
              onClose={onClose}
            />
          )}
        </>
      )}
      {selectedMethod === 'totp' && (
        <>
          {step === 1 && (
            <TwoStepEmailForm
              isChangingFirstStep
              onResend={() => requestChangeTfaType.request()}
              onGetCode={() => requestChangeTfaType.request()}
              onSuccess={() => setStep(2)}
              onSubmit={verifyTfaType}
              onClose={onClose}
            />
          )}
          {step === 2 && (
            <TwoStepGoogleForm isNewSetting onSuccess={onSuccess} onSubmit={verifyTfaType} onClose={onClose} />
          )}
        </>
      )}
      <style jsx>{dynamicStyles}</style>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ChangingAuthMethod.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  selectedMethod: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

ChangingAuthMethod.defaultProps = {
  theme: {},
};

export default compose(withTheme(), connect(null))(ChangingAuthMethod);
