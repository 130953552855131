import React, { useMemo } from 'react';
import { FormattedMessage as FM, injectIntl } from 'react-intl';

import { staticStyles } from './style';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengeProgress = ({ activeChallenge, windowWidth, intl }) => {
  const {
    profitTarget,
    totalProfit,
    level: { maximum_daily_loss: maximumDailyLoss, target },
  } = activeChallenge;
  const isSuccess = +totalProfit.amount >= 0;

  const scaleStyleValue = `${
    windowWidth < 450 ? `${(windowWidth / 450) * 100 - 15 < 70 ? 70 : (windowWidth / 450) * 100 - 15}%` : '100%'
  }`;

  const successWidth = useMemo(() => {
    const result = (+totalProfit.amount / +profitTarget.amount) * 400;

    return result > 400 ? '400px' : `${result}px`;
  }, []);

  const failureWidth = useMemo(() => {
    const result = (+totalProfit.amount.replace('-', '') / +maximumDailyLoss.amount) * 400;

    return result > 400 ? '400px' : `${result}px`;
  }, []);

  if (isSuccess) {
    return (
      <div className="ChallengeProgressSuccess">
        <div className="ChallengeProgressSuccess__MiniChart">
          <div className="ChallengeProgressSuccess__RoundSuccess" />
          <div className="ChallengeProgressSuccess__RelativeGrayLine" />
          <div className="ChallengeProgressSuccess__AbsoluteSuccessLine" />
        </div>
        <div className="Nadpisi">
          <div className="ChallengeProgressSuccess__ProfitTargetText">
            <FM id={isHero ? 'justProfit' : 'justProfitTarget'}>{txt => <div>{txt}</div>}</FM>
          </div>
        </div>

        <div className="ChallengeProgressSuccess__ProfitTargetValue">{`${intl.formatNumber(target.amount)} ${
          target.currency
        }`}</div>
        <style jsx>{staticStyles}</style>
        <style jsx>{`
          .ChallengeProgressSuccess__RoundSuccess {
            width: ${successWidth};
          }
          .ChallengeProgressSuccess__AbsoluteSuccessLine {
            left: ${successWidth};
          }
          .ChallengeProgressSuccess__MiniChart {
            scale: ${scaleStyleValue};
          }
        `}</style>
      </div>
    );
  }
  return (
    <div className="ChallengeProgressFailure">
      <div className="ChallengeProgressFailure__MiniChart">
        <div className="ChallengeProgressFailure__RoundFailure" />
        <div className="ChallengeProgressFailure__RelativeGrayLine" />
        <div className="ChallengeProgressFailure__AbsoluteFailureLine" />
      </div>
      <div className="Nadpisi displayNoneHero">
        <div className="ChallengeProgressFailure__ProfitTargetText">
          {/* <FM id="justYouAreSoClose">{txt => <div className="FailureColor">{txt}</div>}</FM> */}

          <FM id={isHero ? 'justMaximumLoss' : 'justMaximumDailyLoss'}>{txt => <div>{txt}</div>}</FM>
        </div>
      </div>

      <div className="ChallengeProgressFailure__ProfitTargetValue displayNoneHero">{`${intl.formatNumber(
        maximumDailyLoss.amount
      )} ${maximumDailyLoss.currency}`}</div>
      <span className="ChallengeProgressFailure__ZeroPoint displayNoneHero">0 {profitTarget.currency}</span>
      <style jsx>{staticStyles}</style>
      <style jsx>{`
        .ChallengeProgressFailure__RoundFailure {
          width: ${failureWidth};
        }
        .ChallengeProgressFailure__AbsoluteFailureLine {
          right: ${failureWidth};
        }
        .ChallengeProgressFailure {
          scale: ${scaleStyleValue};
        }
      `}</style>
    </div>
  );
};
export default injectIntl(ChallengeProgress);
