import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';

import { setCRMCustomFilter, setModifiedFastFilters } from 'crm/_redux/actions';

import { BaseButton, CheckBox } from 'crm/_components';

import { staticStyles } from './style';

const FilterChoose = ({ filterType, modifiedFiltersSelector, onRequestClose, setCustomFilter }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const modifiedFilters = useSelector(modifiedFiltersSelector);
  const [options, setOptions] = useState(modifiedFilters);

  const onClickOption = option => {
    setOptions(prevState => ({
      ...prevState,
      [option]: { ...options[option], isDisplayed: !options[option].isDisplayed },
    }));
  };

  const handleSave = () => {
    const params = queryString.parse(location.search);

    Object.keys(params).forEach(param => {
      const selectedFilter = Object.keys(modifiedFilters).find(filter => modifiedFilters[filter].query_param === param);

      if (selectedFilter) {
        if (!options[selectedFilter].isDisplayed) {
          delete params[param];
        }
      }
    });

    if (params.filterId) {
      delete params.filterId;
      dispatch(setCRMCustomFilter({}));
      setCustomFilter({ value: '', label: <FM id="crmCustomFilter" /> });
    }

    const stringified = queryString.stringify(params);

    sessionStorage.setItem(`fastFiltersQuery${filterType}`, stringified);
    history.replace({ ...location, search: stringified });

    onRequestClose();
    dispatch(setModifiedFastFilters(options));
  };

  return (
    <div className="FilterChoose">
      <div className="FilterChoose__options">
        {Object.keys(options).map(option => (
          <div className="FilterChoose__option" key={`FilterChoose__option-${option}`}>
            <CheckBox
              textId={option}
              checked={options[option].isDisplayed}
              onClick={() => {
                onClickOption(option);
              }}
            />
          </div>
        ))}
        <style jsx>{staticStyles}</style>
      </div>
      <BaseButton textId="justSave" className="FilterChoose__button" onClick={handleSave} />
    </div>
  );
};

FilterChoose.propTypes = {
  modifiedFiltersSelector: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  setCustomFilter: PropTypes.func.isRequired,
  filterType: PropTypes.func.isRequired,
};

export default FilterChoose;
