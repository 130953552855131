import axios from 'axios';
import queryString from 'query-string';

export const getPartnershipClientsBalance = () => axios.get('/partnership/balance/');

export const getUserTagsClient = userId => axios.get(`/partnership/user/${userId}/tags/`);
export const requestPartnership = () => axios.post(`/partnership/request/`);
export const getPartnershipInfo = () => axios.get(`/partnership/info/`);
export const getPartnershipClients = search => axios.get(`/partnership/clients/`, { params: { ...search } });
export const getUserPartnershipClients = (id, size) => axios.get(`/partnership/clients/${id}/?page_size=${size}`);

export const getPartnershipClientAccounts = id => axios.get(`/partnership/user/${id}/account/`);
export const getPartnershipClientAccount = (id, accountId) =>
  axios.get(`/partnership/user/${id}/account/${accountId}/`);
export const getPartnershipClientTrades = (id, accountId, status = 'open', start, end) =>
  axios.get(`/partnership/user/${id}/account/${accountId}/${status}_trades/`, {
    params: start && end ? { start, end } : undefined,
  });

export const getCurrentIBPrograms = () => axios.get(`/partnership/systems/current_ib/`);
export const getSimpleIBSystemSettings = () => axios.get(`/partnership/systems/simple_conditions_settings/`);
export const sendSimpleIBSystemSettings = settings =>
  axios.post(`/partnership/systems/simple_conditions_settings/`, { ...settings });

export const getPartnershipMaterials = (type, search) =>
  axios.get(`/partnership/materials/${type}/list/?${queryString.stringify(search)}`);

export const getPartnershipWithdrawalAccounts = partnerAccountId =>
  axios.get(`/partnership/systems/account/${partnerAccountId}/withdraw/`);
export const withdrawPartnershipAccount = (partnerAccountId, accountId, toWallet) =>
  axios.post(`/partnership/systems/account/${partnerAccountId}/withdraw/`, { accountId, toWallet });
export const requestEasyStart = () => axios.post(`/partnership/systems/easy_start/`);

export const changeRebate = (accountId, rebate) =>
  axios.put(`partnership/systems/account/${accountId}/change_rebate/`, { rebate });

export const getCampaigns = () => axios.get(`/partnership/materials/campaigns/list/`);

export const createBanners = data => axios.post(`/partnership/materials/banners_set/create/`, { ...data });

export const getPartnershipWithdrawalWalletAccounts = partnerAccountId =>
  axios.get(`/partnership/systems/account/${partnerAccountId}/withdraw/wallet_account/`);
export const withdrawPartnershipWalletAccount = (partnerAccountId, accountId) =>
  axios.post(`/partnership/systems/account/${partnerAccountId}/withdraw/wallet_account/`, { accountId });
