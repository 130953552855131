import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EditIcon, BasketIcon } from 'common/icons';
import {
  ValueLoader,
  LoginCell,
  MoneyCell,
  BaseTable,
  DefaultButton,
  Tooltip,
  ActionsButton,
  BasicModal,
} from 'common/components';

import { withTheme } from 'common/styling/theme';
import { getUserAccounts } from '../../_redux';
import EditTradeAccountPartnerCode from '../EditTradeAccountPartnerCode';
import RemoveTradeAccountPartnerCodeForm from '../RemoveTradeAccountPartnerCodeForm';
import UserNoInfoPanel from '../../../_components/UserNoInfoPanel';

import { staticStyles, getDynamicStyles } from './style';

const Leverage = ({ leverage }) => <>{leverage ? <span className="Leverage">1:{leverage}</span> : <span>—</span>}</>;

Leverage.propTypes = {
  leverage: PropTypes.number,
};

Leverage.defaultProps = {
  leverage: null,
};

const WrappedLeverage = ValueLoader(Leverage);

const UserAccounts = ({
  captionId,
  data,
  imageNoAccount,
  getUserAccounts,
  userId,
  blockFunc,
  unBlockFunc,
  deleteFunc,
  restoreFunc,
  isDemo,
  profile,
  noAccountsTextId,
  history,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [selectedTradeAccountId, setSelectedTradeAccountId] = useState(null);

  const [isChangePartnerCodeModalOpen, setIsChangePartnerCodeModalOpen] = useState(false);

  const [isRemovePartnerCodeModalOpen, setIsRemovePartnerCodeModalOpen] = useState(false);

  const openChangePartnerCodeModal = useCallback(
    () => setIsChangePartnerCodeModalOpen(true),
    [isChangePartnerCodeModalOpen]
  );
  const closeChangePartnerCodeModal = useCallback(
    () => setIsChangePartnerCodeModalOpen(false),
    [isChangePartnerCodeModalOpen]
  );

  const openRemovePartnerCodeModalOpen = useCallback(
    () => setIsRemovePartnerCodeModalOpen(true),
    [isRemovePartnerCodeModalOpen]
  );
  const closeRemovePartnerCodeModalOpen = useCallback(
    () => setIsRemovePartnerCodeModalOpen(false),
    [isRemovePartnerCodeModalOpen]
  );

  const [loadingButtons, setLoadingButtons] = useState({
    block: false,
    archive: false,
  });

  const handleBlock = (e, isBlocked, id) => {
    e.stopPropagation();
    setLoadingButtons({ archive: false, block: true });
    if (isBlocked) {
      unBlockFunc(userId, id);
    } else {
      blockFunc(userId, id);
    }
  };

  const handleArchive = (e, isArchived, id) => {
    e.stopPropagation();
    setLoadingButtons({ archive: true, block: false });
    if (isArchived) {
      restoreFunc(userId, id);
    } else {
      deleteFunc(userId, id);
    }
  };

  const hasPartnerCode = useMemo(() => profile?.perms?.partnershipUser, [profile?.perms?.partnershipUser]);

  let partnerCodeColumn;
  if (hasPartnerCode) {
    partnerCodeColumn = [
      {
        key: 'partnerCode',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original: { id, partnerCode } }) => (
          <div className="UserAccounts__PartnerCodeCell">
            <span>{partnerCode || '—'}</span>
            <div className="UserAccounts__ActionsButtonsCell">
              <ActionsButton
                edit
                buttonId="changeUserEmail"
                condition
                onClickFunc={e => {
                  e.stopPropagation();
                  setSelectedTradeAccountId(id);
                  openChangePartnerCodeModal();
                }}>
                <EditIcon />
              </ActionsButton>
              {partnerCode && (
                <ActionsButton
                  deleteType
                  buttonId="removePartnerCode"
                  onClickFunc={e => {
                    e.stopPropagation();
                    setSelectedTradeAccountId(id);
                    openRemovePartnerCodeModalOpen();
                  }}
                  condition>
                  <BasketIcon />
                </ActionsButton>
              )}
            </div>
          </div>
        ),
      },
    ];
  } else {
    partnerCodeColumn = [];
  }

  /* eslint-disable */

  const columns = [
    {
      key: 'accountLogin',
      Cell: ({ original: { login, accountTypeTitle } }) => (
        <LoginCell login={login} title={accountTypeTitle} tooltipId={`UserAccounts-accountLogin-${login}`} />
      ),
    },
    {
      key: 'justBalance',
      Cell: ({ original: { balance, loading, loadingFailed } }) => (
        <MoneyCell inTable value={balance} loading={loading} loadingFailed={loadingFailed} />
      ),
    },
    {
      key: 'justEquity',
      Cell: ({ original: { equity, loading, loadingFailed } }) => (
        <MoneyCell inTable value={equity} loading={loading} loadingFailed={loadingFailed} />
      ),
    },
    {
      key: 'justLeverage',
      Cell: ({ original: { leverage, loading, loadingFailed } }) => (
        <WrappedLeverage leverage={leverage} loading={loading} loadingFailed={loadingFailed} />
      ),
    },
    ...partnerCodeColumn,
  ];

  {
    !isDemo &&
      profile.perms.actionUser &&
      columns.push({
        key: 'justActions',
        Cell: ({
          original: { id, isBlocked, balance, isArchived, actionIsLoading, loading, loadingFailed, platformSlug },
        }) => {
          return !['cfh'].includes(platformSlug) ? (
            <Tooltip id={`boBlockedAccountAction_${id}`} textId={balance ? '' : 'accountBroken'}>
              <div className="UserAccounts__buttons-inner">
                <DefaultButton
                  textId={isBlocked ? 'justUnblock' : 'justBlock'}
                  disabled={actionIsLoading || loading || loadingFailed}
                  loading={actionIsLoading && loadingButtons.block}
                  onClick={e => handleBlock(e, isBlocked, id)}
                />
                <DefaultButton
                  textId={isArchived ? 'justRestore' : 'justArchive'}
                  butColor="error"
                  disabled={actionIsLoading || loading || (isArchived ? !isArchived : loadingFailed)}
                  loading={actionIsLoading && loadingButtons.archive}
                  onClick={e => handleArchive(e, isArchived, id)}
                />
              </div>
            </Tooltip>
          ) : null;
        },
      });
  }
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => history.push(`/backoffice/users/${userId}/accounts/${rowInfo.original.id}`),
  });

  return (
    <div
      className={classNames('UserAccounts', {
        'UserAccounts--noAccounts': !data.length,
        'UserAccounts--partnerCode': hasPartnerCode,
      })}>
      <div className="UserAccounts__head">
        <FormattedMessage id={captionId}>{txt => <h2>{txt}</h2>}</FormattedMessage>
      </div>
      {data.length ? (
        <div className="UserAccounts__body">
          <BaseTable columns={columns} data={data} getTrProps={rowProps} />
        </div>
      ) : (
        <UserNoInfoPanel image={imageNoAccount} textId={noAccountsTextId} />
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>

      <BasicModal
        captionId="editPartnerCode"
        isOpen={isChangePartnerCodeModalOpen}
        onRequestClose={closeChangePartnerCodeModal}>
        {isChangePartnerCodeModalOpen && (
          <EditTradeAccountPartnerCode
            onSubmitSuccess={() => {
              closeChangePartnerCodeModal();
              getUserAccounts({ userId });
            }}
            user={{ id: userId }}
            tradeAccountId={selectedTradeAccountId}
          />
        )}
      </BasicModal>

      <BasicModal
        captionId="confirmText"
        isOpen={isRemovePartnerCodeModalOpen}
        onRequestClose={closeRemovePartnerCodeModalOpen}>
        {isRemovePartnerCodeModalOpen && (
          <RemoveTradeAccountPartnerCodeForm
            onSubmitSuccess={() => {
              closeRemovePartnerCodeModalOpen();
              getUserAccounts({ userId });
            }}
            closeModal={closeRemovePartnerCodeModalOpen}
            user={{ id: userId }}
            tradeAccountId={selectedTradeAccountId}
          />
        )}
      </BasicModal>
    </div>
  );
};

UserAccounts.propTypes = {
  captionId: PropTypes.string.isRequired,
  noAccountsTextId: PropTypes.string.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  imageNoAccount: PropTypes.string,
  userId: PropTypes.string,
  blockFunc: PropTypes.func,
  unBlockFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  restoreFunc: PropTypes.func,
  isDemo: PropTypes.bool,
  profile: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  theme: PropTypes.object,
};

UserAccounts.defaultProps = {
  userId: '',
  blockFunc: null,
  unBlockFunc: null,
  deleteFunc: null,
  restoreFunc: null,
  isDemo: false,
  imageNoAccount: '',
  profile: {},
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(null, { getUserAccounts: data => getUserAccounts.request(data) })
)(UserAccounts);
export { UserAccounts };
