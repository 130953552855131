import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { Tooltip } from 'common/components';

import TooltipIcon from 'welcome-setup/_components/icons/TooltipIcon';

import { staticStyles, getDynamicStyles } from './style';

const TransferDetails = ({ captionSlug, accountInfo, amount, commissionInfo, theme, isWalletEnabled }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="TransferDetails">
      <FormattedMessage id={captionSlug}>
        {txt => <span className="TransferDetails__caption">{txt}</span>}
      </FormattedMessage>
      <ul>
        <li>
          <FormattedMessage id="boAmount" />
          {amount.amount} {amount.currency}
        </li>
        <li>
          <FormattedMessage id="justEmail" />
          {accountInfo.email}
        </li>
        {isWalletEnabled && accountInfo.walletBalance && (
          <li>
            <FormattedMessage id="justWalletBalance" />
            {accountInfo.walletBalance} USD
          </li>
        )}
        {isWalletEnabled && accountInfo.oldWalletBalance && (
          <li>
            <div className="TransferDetails__oldWallet">
              <FormattedMessage id="justOldWalletBalance" />
              <div className="TransferDetails__oldWallet-tooltip">
                <Tooltip textId="oldWalletBalanceTooltip">
                  <TooltipIcon />
                </Tooltip>
              </div>
            </div>
            {accountInfo.oldWalletBalance?.amount} {accountInfo.oldWalletBalance?.currency}
          </li>
        )}
        {accountInfo.balance && (
          <li>
            <FormattedMessage id="boBalance" />
            {accountInfo.balance.amount} {accountInfo.balance.currency}
          </li>
        )}
        {accountInfo.equity && (
          <li>
            <FormattedMessage id="boEquity" />
            {accountInfo.equity.amount} {accountInfo.equity.currency}
          </li>
        )}
        {commissionInfo && commissionInfo.commissionValue && (
          <li>
            <FormattedMessage id="justCommission" />
            {commissionInfo.commissionValue.amount} {commissionInfo.commissionValue.currency}
          </li>
        )}
      </ul>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TransferDetails.propTypes = {
  captionSlug: PropTypes.string.isRequired,
  accountInfo: PropTypes.object.isRequired,
  amount: PropTypes.object.isRequired,
  commissionInfo: PropTypes.object.isRequired,
  isWalletEnabled: PropTypes.bool,
  theme: PropTypes.object,
};

TransferDetails.defaultProps = {
  isWalletEnabled: false,
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    isWalletEnabled: state.interfaceConfig.addons.includes('wallet'),
  }))
)(TransferDetails);
export { TransferDetails };
