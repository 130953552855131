import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageContentPreloader } from 'common/components';
import noInfoImage from 'common/images/noInfo.svg';
import WalletAccounts from '../WalletAccounts';

import { deleteUserAccount, restoreUserAccount, getBackofficeWalletAccounts } from '../../_redux';

const UserWalletAccountsPanel = ({
  id,
  walletAccounts,
  archivedWalletAccounts,
  getWalletAccounts,
  walletAccountsAreLoaded,
  deleteUserAccount,
  restoreUserAccount,
  profile,
}) => {
  useEffect(() => {
    getWalletAccounts({ userId: id });
  }, [getWalletAccounts, id]);

  return (
    <PageContentPreloader ready={walletAccountsAreLoaded}>
      <WalletAccounts
        data={walletAccounts}
        captionId="boUserWalletAccounts"
        imageNoAccount={noInfoImage}
        deleteFunc={deleteUserAccount}
        restoreFunc={restoreUserAccount}
        userId={id}
        profile={profile}
        noAccountsTextId="boNoAccounts"
      />
      <WalletAccounts
        data={archivedWalletAccounts}
        captionId="boUserArchivedWalletAccounts"
        imageNoAccount={noInfoImage}
        deleteFunc={deleteUserAccount}
        restoreFunc={restoreUserAccount}
        userId={id}
        profile={profile}
        noAccountsTextId="boNoArchivedAccounts"
      />
    </PageContentPreloader>
  );
};

UserWalletAccountsPanel.propTypes = {
  id: PropTypes.string.isRequired,
  getWalletAccounts: PropTypes.func.isRequired,
  walletAccounts: PropTypes.array,
  archivedWalletAccounts: PropTypes.array,
  walletAccountsAreLoaded: PropTypes.bool.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  restoreUserAccount: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

UserWalletAccountsPanel.defaultProps = {
  walletAccounts: [],
  archivedWalletAccounts: [],
};

export default connect(
  state => ({
    profile: state.user.profile,
    walletAccounts: state.backoffice.users.walletAccounts.filter(account => !account.isArchived),
    archivedWalletAccounts: state.backoffice.users.walletAccounts.filter(account => account.isArchived),
    walletAccountsAreLoaded: state.backoffice.users.walletAccountsAreLoaded,
  }),
  {
    getWalletAccounts: user => getBackofficeWalletAccounts.request(user),
    deleteUserAccount: (userId, accountId, isMultiWallet) =>
      deleteUserAccount.request(userId, accountId, isMultiWallet),
    restoreUserAccount: (userId, accountId, isMultiWallet) =>
      restoreUserAccount.request(userId, accountId, isMultiWallet),
  }
)(UserWalletAccountsPanel);
