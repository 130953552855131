import React from 'react';
import classNames from 'classnames';
import { withTheme } from 'common/styling/theme';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { getCurrentDayRange } from 'crm/_newComponents/FastFilters/helpers';
import IconTrash from 'crm/_components/BaseIconButton/icons/iconTrash';

import { Calendar } from 'common/components';

import { staticStyles, getDynamicStyles } from './style';

const ModalDropDown = ({ setIsOpen, selectebleOptions, onSelect, withResetButton, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleDateReset = () => {
    onSelect({ label: 'justAll', computedLabel: 'justAll', value: getCurrentDayRange(), isSelected: true });
    setIsOpen(false);
  };

  return (
    <div className={classNames('DateSelector__Options', 'DateSelector__Options--mobile')}>
      <div className="DateSelector__OptionsLeft">
        {selectebleOptions.map(({ value, label, isSelected }) => (
          <button
            key={`DateSelector-options-${label}`}
            className={classNames('DateSelector__Option DateSelector__Option--FastFilter', {
              'DateSelector__Option--FastFilterIsSelected': isSelected,
            })}
            type="button"
            onClick={() => {
              onSelect({ value, label });
              setIsOpen();
            }}>
            <FM id={label}>{txt => txt}</FM>
          </button>
        ))}
      </div>
      <div className="DateSelector__OptionsRight">
        <FM id="justChooseCustomRange">{txt => <span className="DateSelector__OptionsRightTitle">{txt}</span>}</FM>
        <Calendar
          setCorrectLastDay
          daysLimit={1000}
          isUnix={false}
          onChangeFunc={(...value) => {
            onSelect({ value: value.map(val => val.valueOf()), label: 'justCustomRange' });
            setIsOpen();
          }}
        />
        {withResetButton && (
          <button className="DateSelector__reset-btn" type="button" onClick={handleDateReset}>
            <IconTrash />
            <FM id="justReset" />
          </button>
        )}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ModalDropDown.propTypes = {
  selectebleOptions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default withTheme()(ModalDropDown);
