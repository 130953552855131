import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StatusCell, DateCell } from 'common/components';
import { withTheme } from 'common/styling/theme';
import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';

const TransferDetails = ({ transferDetails, intl, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const TRANSFER_TYPE_SLUGS = {
    INNER: 'boInnerTransfer',
    TO_TRADER: 'boAnotherTrader',
    FROM_WALLET: 'fromWallet',
    FROM_WALLET_ACCOUNT: 'fromWalletAccount',
    TO_WALLET_ACCOUNT: 'toWalletAccount',
    FROM_WALLET_ACCOUNT_INVEST: 'justFromWalletAccountInvest',
    TO_WALLET_ACCOUNT_INVEST: 'justToWalletAccountInvest',
    TO_WALLET: 'toWallet',
    TO_TRADER_WALLET: 'toTraderWallet',
    TO_TRADER_LPA: 'justToLpa',
    TO_TRADER_WALLET_LPA: 'justToWalletLpa',
    INVEST_INNER: 'justInvestInner',
    TO_WALLET_INVEST: 'justToWalletInvest',
    FROM_WALLET_INVEST: 'justFromWalletInvest',
  };

  const cells = [
    {
      isAvailable: true,
      captionId: 'boAmount',
      displayValue: `${transferDetails.sendAmount.amount} ${transferDetails.sendAmount.currency}`,
    },
    {
      isAvailable: true,
      captionId: 'justSenderAccount',
      displayValue: <span>{transferDetails.senderAccount ?? intl.formatMessage({ id: 'menuWallet' })}</span>,
    },
    {
      isAvailable: true,
      captionId: 'justRecipientAccount',
      displayValue: <span>{transferDetails.recipientAccount ?? intl.formatMessage({ id: 'menuWallet' })}</span>,
    },
    {
      isAvailable: true,
      captionId: 'justDate',
      displayValue: <DateCell value={transferDetails.created} />,
    },
    {
      isAvailable: transferDetails.commissionInfo && transferDetails.commissionInfo.commissionValue,
      captionId: 'justCommission',
      displayValue: (
        <span>
          {transferDetails.commissionInfo?.commissionValue?.amount ?? ''}{' '}
          {transferDetails.commissionInfo?.commissionValue?.currency ?? ''}
        </span>
      ),
    },
    {
      isAvailable: true,
      captionId: 'boTransferType',
      displayValue: <FormattedMessage id={TRANSFER_TYPE_SLUGS[transferDetails.transferType]} />,
    },
    {
      isAvailable: true,
      captionId: 'justStatus',
      displayValue: <StatusCell statusCode={transferDetails.status} />,
    },
  ];

  return (
    <div className="TransferDetails">
      <FormattedMessage id="transferDetails">
        {txt => <span className="TransferDetails__caption">{txt}</span>}
      </FormattedMessage>
      <div className="TransferDetails__list">
        {cells.map(cell => (
          <div className="TransferDetails__cell" key={cell.captionId}>
            <FormattedMessage id={cell.captionId}>
              {txt => <span className="TransferDetails__cell-caption">{txt}</span>}
            </FormattedMessage>
            <span
              className={classNames('TransferDetails__cell-value', {
                'TransferDetails__cell-value--pos': cell.value > 0,
                'TransferDetails__cell-value--neg': cell.value < 0,
              })}>
              {cell.displayValue}
            </span>
          </div>
        ))}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TransferDetails.propTypes = {
  transferDetails: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

TransferDetails.defaultProps = {
  theme: {},
};

export default withTheme()(injectIntl(TransferDetails));

export { TransferDetails };
