import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCrmUserAccounts } from 'crm/users/_redux';
import noData from 'common/images/noInfo.svg';
import { FormattedMessage as FM } from 'react-intl';
import { PageContentPreloader } from 'common/components';
import { currencyMap } from 'common/constants';

import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const TabAccounts = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const { accounts, accountsAreLoaded } = useSelector(state => state.crm.users);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCrmUserAccounts.request({ id }));
  }, [dispatch, id]);

  return (
    <div className="TabAccounts">
      <PageContentPreloader ready={accountsAreLoaded} type="smallLines">
        <div className="TabAccounts__list">
          {accountsAreLoaded && (
            <>
              {accounts.wallet && (
                <div className="TabAccounts__item">
                  <div
                    className={classNames('TabAccounts__item-icon', {
                      'TabAccounts__item-icon--wallet': true,
                    })}>
                    W
                  </div>
                  <div className="TabAccounts__item-info">
                    <div className="TabAccounts__item-info-subwrap">
                      <div className="TabAccounts__item-info-title">Wallet</div>
                      <div className="TabAccounts__item-info-balance">
                        {accounts.wallet ? (
                          <div>
                            <FM id="crmBalance">
                              {txt => <span className="TabAccounts__item-info-balance--caption">{txt}:</span>}
                            </FM>{' '}
                            {accounts.wallet.amount} {accounts.wallet.currency}
                          </div>
                        ) : (
                          '---'
                        )}
                      </div>
                      <NavLink to={`/crm/contacts/client/${id}/history/1/`} className="TabAccounts__item-info-history">
                        History
                      </NavLink>
                    </div>
                    {/* <div className="TabAccounts__item-info-date">{date}</div> */}
                  </div>
                </div>
              )}
              {accounts.accounts?.map(
                ({ id: idAccount, platformSlug, login, accountTypeTitle, balance, currency }, i) => (
                  <div className="TabAccounts__item" key={i}>
                    <div
                      className={classNames('TabAccounts__item-icon', {
                        'TabAccounts__item-icon--mt5': platformSlug === 'mt5',
                        'TabAccounts__item-icon--ramm': platformSlug === 'ramm',
                        'TabAccounts__item-icon--cfh': platformSlug === 'cfh',
                        'TabAccounts__item-icon--mt4': platformSlug === 'mt4',
                        'TabAccounts__item-icon--multiWallet': platformSlug === 'wallet',
                      })}>
                      {platformSlug === 'mt5' && 'MT'}
                      {platformSlug === 'ramm' && 'R'}
                      {platformSlug === 'cfh' && 'C'}
                      {platformSlug === 'mt4' && 'MT'}
                      {platformSlug === 'wallet' && currencyMap[currency]}
                    </div>
                    <div className="TabAccounts__item-info">
                      <div className="TabAccounts__item-info-subwrap">
                        <div className="TabAccounts__item-info-title">
                          {login} {accountTypeTitle}
                        </div>
                        <div className="TabAccounts__item-info-balance">
                          {balance ? (
                            <div>
                              <FM id="crmBalance">
                                {txt => <span className="TabAccounts__item-info-balance--caption">{txt}:</span>}
                              </FM>{' '}
                              {balance.amount} {balance.currency}
                            </div>
                          ) : (
                            '---'
                          )}
                        </div>
                        <NavLink
                          to={`/crm/contacts/client/${id}/history/${idAccount}`}
                          className="TabAccounts__item-info-history">
                          History
                        </NavLink>
                      </div>
                      {/* <div className="TabAccounts__item-info-date">{date}</div> */}
                    </div>
                  </div>
                )
              )}
              {!accounts.accounts?.length && !accounts?.wallet && (
                <div className="TabAccounts__no-data">
                  <img src={noData} alt="noData" />
                  <FM id="noData" />
                </div>
              )}
            </>
          )}
        </div>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TabAccounts.propTypes = {
  theme: PropTypes.object,
};
TabAccounts.defaultProps = {
  theme: {},
};

export default withTheme()(TabAccounts);
export { TabAccounts };
