import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import { withTheme } from 'common/styling/theme';
import { FileField, LightBoxModal, LightBoxImage } from 'common/components';
import { getWithdrawalProof } from 'backoffice/users/_redux/actions';

import { closeLightBox, openLightBox } from 'interface/user/_redux/actions';
import imageDownload from 'common/images/documentDownload.png';

import classNames from 'classnames';
import { uploadPaymentProof } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const PaymentProofForm = ({
  paymentId,
  status,
  uploadPaymentProof,
  // paymentProofUploaded,
  paymentProofUploadError,
  getWithdrawalProof,
  lightBoxCurrentImage,
  lightBoxImages,
  closeLightBox,
  openLightBox,
  isDeposit,
  withdrawalProof,
  theme,
  paymentProofStatus,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [currentStatus, setCurrentStatus] = useState(status);
  const IE = window.navigator && window.navigator.msSaveOrOpenBlob;

  useEffect(() => {
    if (isDeposit) return;
    getWithdrawalProof(paymentId);
  }, [getWithdrawalProof, isDeposit, paymentId]);

  // const allowedToUploadDocumentsStatuses = ['notUploaded', 'rejected', 'enabled', 'unverificated'];
  const acceptedFormats = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const handleAfterUpload = file => {
    uploadPaymentProof({ id: paymentId, file });
  };

  const imageExtensions = {
    'image/png': '.png',
    'image/jpeg': '.jpeg',
  };

  const documentExtensions = {
    'application/pdf': '.pdf',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  };

  const extensions = { ...imageExtensions, ...documentExtensions };

  if (withdrawalProof) {
    document.url = URL.createObjectURL(withdrawalProof);
    document.name = `proof_withdrawal_${paymentId}${extensions[withdrawalProof.type]}`;
  }

  const downloadDocument = () => {
    window.navigator.msSaveOrOpenBlob(withdrawalProof, document.name);
  };

  const DocumentsDownloadBlock = () => {
    if (IE) {
      return (
        <button type="button" onClick={() => downloadDocument()}>
          <img src={imageDownload} alt="Uptrader" />
        </button>
      );
    }
    return (
      <a href={document.url} download={document.name}>
        <img src={imageDownload} alt="Uptrader" />
      </a>
    );
  };

  return (
    <form
      className={classNames('PaymentProofForm', {
        'PaymentProofForm--no-approve': !withdrawalProof && !isDeposit,
      })}>
      <div className="PaymentProofForm__caption">
        <h2>
          <FormattedMessage id="justPaymentProof">{txt => txt}</FormattedMessage>
        </h2>
        {isDeposit && (
          <p>
            <FormattedMessage id="paymentProofUploadText">{txt => txt}</FormattedMessage>
          </p>
        )}
      </div>

      <div className="PaymentProofForm__input-inner">
        {!paymentProofStatus && isDeposit && (
          <Field
            component={FileField}
            handleAfterUpload={handleAfterUpload}
            name="file"
            formatNameId="documentsFormats"
            fileNameId="justAttachments"
            accept={acceptedFormats.join(',')}
            multiple={false}
          />
        )}

        {paymentProofStatus === 'unverified' && isDeposit && (
          <div className="DocumentsForm__upload">
            <div className="DocumentsForm__upload-text">
              <FormattedMessage id="clientDocumentUpload">
                {txt => (
                  <p>
                    {txt}
                    <i />
                  </p>
                )}
              </FormattedMessage>
              <FormattedMessage id="clientDocumentUploadDescription" />
            </div>
            {currentStatus === 'rejected' && (
              <div className="DocumentsForm__upload-button">
                <FormattedMessage id="clientDocumentUploadAnother">
                  {txt => (
                    <button type="button" onClick={() => setCurrentStatus('unverificated')}>
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              </div>
            )}
          </div>
        )}

        {currentStatus === 'verified' && (
          <FormattedMessage id="status_verified">
            {txt => (
              <p className="Document__status-verified">
                {txt}
                <i />
              </p>
            )}
          </FormattedMessage>
        )}
      </div>

      {paymentProofUploadError && <span className="PaymentProofForm__error">{paymentProofUploadError}</span>}

      {withdrawalProof && !isDeposit && (
        <div className="PaymentProofForm__withdrawal-proof">
          {Object.keys(imageExtensions).includes(withdrawalProof.type) ? (
            <LightBoxImage
              className="UserDocument__lightBoxImage"
              key={1}
              openLightBox={() => openLightBox(1, [document.url])}
              image={document.url}
              titleId={document.name}
            />
          ) : (
            <div className="PaymentProofForm__withdrawal-proof-download">
              <DocumentsDownloadBlock />
              <FormattedMessage id="downloadDocument" />
            </div>
          )}
        </div>
      )}

      <LightBoxModal images={lightBoxImages} currentImage={lightBoxCurrentImage} onRequestClose={closeLightBox} />

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

PaymentProofForm.propTypes = {
  paymentId: PropTypes.number.isRequired,
  status: PropTypes.string,
  // paymentProofUploaded: PropTypes.bool.isRequired,
  uploadPaymentProof: PropTypes.func.isRequired,
  paymentProofUploadError: PropTypes.object,
  isDeposit: PropTypes.bool,
  theme: PropTypes.object,
  withdrawalProof: PropTypes.object,
  getWithdrawalProof: PropTypes.func.isRequired,
  lightBoxCurrentImage: PropTypes.number,
  lightBoxImages: PropTypes.array,
  closeLightBox: PropTypes.func,
  openLightBox: PropTypes.func,
  paymentProofStatus: PropTypes.string,
};

PaymentProofForm.defaultProps = {
  status: null,
  paymentProofUploadError: null,
  isDeposit: false,
  withdrawalProof: {},
  theme: {},
  closeLightBox: null,
  lightBoxCurrentImage: -1,
  lightBoxImages: [],
  openLightBox: null,
  paymentProofStatus: null,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      // paymentProofUploaded: state.client.payments.paymentProofUploaded,
      paymentProofUploadError: state.client.payments.paymentProofUploadError,
      withdrawalProof: state.backoffice.users.withdrawalProof,
      lightBoxCurrentImage: state.user.lightBoxCurrentImage,
      lightBoxImages: state.user.lightBoxImages,
      paymentProofStatus: state.client.payments.paymentsInfo.paymentProofStatus,
    }),
    {
      uploadPaymentProof: data => uploadPaymentProof.request(data),
      getWithdrawalProof: id => getWithdrawalProof.request(id),
      openLightBox: (id, lightBoxImages) => openLightBox(id, lightBoxImages),
      closeLightBox,
    }
  ),
  reduxForm({
    form: 'paymentProof',
  })
)(PaymentProofForm);
