import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  .TwoStepSecurityPanelForm {
    padding: 32px;
    margin-top: 24px;

    &__enable-block {
      margin-bottom: 48px;

      &__field {
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 24px;
        font-size: 16px;
      }

      .SwitchField__error {
        display: none !important;
      }
    }

    &__choose-block {
      margin-bottom: 16px;

      &__options {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__link {
        font-weight: 500;
      }

      &__option {
        font-size: 16px;

        &__caption {
          margin-left: 30px;
          margin-top: 8px;
          font-size: 14px;
        }
      }
    }
  }

  :global(.ReactModal__Content) {
    max-width: 620px !important;
    width: 100%;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TwoStepSecurityPanelForm {
      background: ${colors.white.hex};

      &__choose-block {
        &__link {
          color: ${colors.primary.hex};
        }

        &__option {
          &__caption {
            color: ${rgba([colors.black.rgb, 0.75])};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
