import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .LightBoxPdf__inner {
    display: flex;
    align-items: center;
    .FilePdf {
      height: 2rem;
      width: auto;
      margin-right: 3px !important;
      border-radius: 0;
    }
    span {
      display: block;
      word-break: break-all;
      white-space: normal;
    }

    &:hover {
      cursor: pointer;
      span {
        text-decoration: underline;
      }
    }
  }
`;

export { staticStyles };
