import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'common/styling/theme';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  getLastFunnels,
  getMonthsFunnels,
  getDailyFunnels,
  getUsersHistory,
  getTarget,
  getTotals,
  resetDahboardLoadedFlags,
} from 'common/dashboards/_redux';

import MonthPicker from 'common/components/MonthPicker';
import { PageContentPreloader } from 'common/components';

import { currentDate } from 'common/dashboards/helpers';
import moment from 'moment';
import {
  FunnelChart,
  DashboardHistory,
  FulfillmentOfPlans,
  SmallAreaCharts,
  AreaChart,
  MonthColumnChart,
  BigCharts,
} from '../_components';

import { staticStyles, getDynamicStyles } from './style';

const DashboardsPage = ({
  addons,
  lastFunnels,
  lastFunnelsAreLoaded,
  getLastFunnels,
  getMonthsFunnels,
  getTotals,
  isClientInterface,
  dailyFunnels,
  dailyFunnelsAreLoaded,
  getDailyFunnels,
  usersHistory,
  getUsersHistory,
  target,
  targetIsLoaded,
  getTarget,
  partnerId,
  perms,
  totals,
  history,
  match,
  viewMode,
  resetLoadedFlags,
  slug,
  theme,
}) => {
  console.log({ viewMode });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isBackOfficePage = match.url.indexOf('backoffice') !== -1;
  const hasBrokerAccess = perms && (perms.viewDashboards || perms.managerViewDashboard) && isBackOfficePage;
  const [date, setDate] = useState(currentDate);
  const currentMonth = moment(date).format('YYYY-MM');
  const lastMonth = moment(date).subtract(1, 'months').format('YYYY-MM');
  const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
  const currentMonthName = moment(date).format('MMMM');
  const currentYear = Number(moment().format('YYYY'));
  const currentMonthNum = Number(moment().format('M'));

  const [monthValue, setMonthValue] = useState({
    year: currentYear,
    month: currentMonthNum,
  });

  useEffect(() => {
    getLastFunnels({ hasBrokerAccess, currentMonth, lastMonth, partnerId, viewMode });
    getMonthsFunnels({ hasBrokerAccess, firstMonth: currentMonth, secondMonth: lastMonth, partnerId, viewMode });
    getDailyFunnels({ hasBrokerAccess, startOfMonth, endOfMonth, partnerId, viewMode });
    getUsersHistory({ hasBrokerAccess, partnerId, viewMode });
    getTarget({ hasBrokerAccess, partnerId, viewMode });
    if (!isClientInterface) {
      getTotals({ slug });
    }
  }, [
    getLastFunnels,
    getMonthsFunnels,
    getDailyFunnels,
    getUsersHistory,
    getTarget,
    hasBrokerAccess,
    partnerId,
    viewMode,
    date,
  ]);

  const handleMonthChange = monthValue => {
    setMonthValue(monthValue);

    const month = monthValue.month < 10 ? `0${monthValue.month}` : monthValue.month;

    setDate(moment(`${monthValue.year}-${month}`).format('YYYY-MM'));
  };

  const unlisten = history.listen(() => {
    const queryParams = history.location.pathname.split('/');
    if (queryParams[queryParams.length - 1] === 'dashboards' || history.location.search === '?tab=dashboards') {
      if (lastFunnelsAreLoaded && dailyFunnelsAreLoaded && targetIsLoaded) {
        resetLoadedFlags();
        unlisten();
      }
    }
  });

  return (
    <PageContentPreloader ready={lastFunnelsAreLoaded && dailyFunnelsAreLoaded && targetIsLoaded} type="dashboards">
      {!isClientInterface && (
        <div className="DashboardsPage__totals">
          {addons.includes('wallet') && (
            <div className="DashboardsPage__total-card">
              <FormattedMessage id="justTotalCrmBalance">
                {txt => <div className="DashboardsPage__total-title">{txt}</div>}
              </FormattedMessage>
              <div className="DashboardsPage__total-number">&#36; {`${totals.totalBalance}`}</div>
            </div>
          )}
          <div className="DashboardsPage__total-card">
            <FormattedMessage id="justTotalClients">
              {txt => <div className="DashboardsPage__total-title">{txt}</div>}
            </FormattedMessage>
            <div className="DashboardsPage__total-number">{`${totals?.totalClients}`}</div>
          </div>
        </div>
      )}

      <div className="TopMonthPicker">
        <MonthPicker
          monthValue={monthValue}
          years={{ min: { year: 2010, month: 1 }, max: { year: currentYear, month: currentMonthNum } }}
          handleChange={handleMonthChange}
        />
      </div>
      <div className="DashboardsPage__funnel">
        <div className="DashboardsPage__funnel-top">
          <FormattedMessage id="boFunnelFrom" values={{ month: currentMonthName }}>
            {txt => <h3>{txt}</h3>}
          </FormattedMessage>
        </div>
        <FunnelChart lastFunnels={lastFunnels} currentMonth={currentMonth} />
        {Object.keys(usersHistory).length > 0 && <DashboardHistory usersHistory={usersHistory} />}
      </div>
      <FulfillmentOfPlans
        lastFunnels={lastFunnels}
        target={target}
        partnerId={partnerId}
        viewMode={viewMode}
        currentMonth={currentMonth}
        lastMonth={lastMonth}
        currentMonthName={currentMonthName}
      />
      <div className="DashboardsPage__month">
        <FormattedMessage id="boDashboardThisMonth">
          {txt => <h3 className="DashboardsPage__month-title">{txt}</h3>}
        </FormattedMessage>
        <div className="DashboardsPage__month-topCharts">
          <MonthColumnChart
            partnerId={partnerId}
            hasBrokerAccess={hasBrokerAccess}
            currentYear={Number(moment(date).format('YYYY'))}
            currentMonth={Number(moment(date).format('M'))}
            lastMonthYear={Number(moment(date).subtract(1, 'months').format('YYYY'))}
            lastMonth={Number(moment(date).subtract(1, 'months').format('M'))}
          />
          <AreaChart
            lastFunnels={lastFunnels}
            dailyFunnels={dailyFunnels}
            currentMonth={currentMonth}
            lastMonth={lastMonth}
            tomorrow={moment(date).add(1, 'days').format('YYYY-MM-DD')}
          />
        </div>
        <SmallAreaCharts
          lastFunnels={lastFunnels}
          dailyFunnels={dailyFunnels}
          currentMonth={currentMonth}
          lastMonth={lastMonth}
          tomorrow={moment(date).add(1, 'days').format('YYYY-MM-DD')}
        />
      </div>
      <BigCharts
        lastFunnels={lastFunnels}
        dailyFunnels={dailyFunnels}
        displayToday={moment(date).format('DD.MM.YYYY')}
        startOfWeek={moment(date).startOf('week').format('YYYY-MM-DD')}
        endOfWeek={moment(date).endOf('week').format('YYYY-MM-DD')}
        currentMonth={currentMonth}
      />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageContentPreloader>
  );
};

DashboardsPage.propTypes = {
  addons: PropTypes.object.isRequired,
  lastFunnelsAreLoaded: PropTypes.bool.isRequired,
  getLastFunnels: PropTypes.func.isRequired,
  getMonthsFunnels: PropTypes.func.isRequired,
  dailyFunnels: PropTypes.object,
  dailyFunnelsAreLoaded: PropTypes.bool.isRequired,
  getDailyFunnels: PropTypes.func.isRequired,
  usersHistory: PropTypes.object,
  getUsersHistory: PropTypes.func.isRequired,
  target: PropTypes.object,
  targetIsLoaded: PropTypes.bool.isRequired,
  getTarget: PropTypes.func.isRequired,
  getTotals: PropTypes.func.isRequired,
  partnerId: PropTypes.number,
  perms: PropTypes.object.isRequired,
  resetLoadedFlags: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  viewMode: PropTypes.string,
  theme: PropTypes.object,
  lastFunnels: PropTypes.object,
};

DashboardsPage.defaultProps = {
  lastFunnels: {},
  dailyFunnels: {},
  target: {},
  partnerId: 0,
  usersHistory: {},
  viewMode: null,
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      addons: state.interfaceConfig.addons,
      lastFunnels: state.dashboards.lastFunnels,
      lastFunnelsAreLoaded: state.dashboards.lastFunnelsAreLoaded,
      dailyFunnels: state.dashboards.dailyFunnels,
      dailyFunnelsAreLoaded: state.dashboards.dailyFunnelsAreLoaded,
      usersHistory: state.dashboards.usersHistory,
      usersHistoryIsLoaded: state.dashboards.usersHistoryIsLoaded,
      totals: state.dashboards.totals,
      totalsIsLoaded: state.dashboards.totalsIsLoaded,
      target: state.dashboards.target,
      targetIsLoaded: state.dashboards.targetIsLoaded,
      perms: state.user.profile.perms,
    }),
    {
      getLastFunnels: data => getLastFunnels.request(data),
      getMonthsFunnels: data => getMonthsFunnels.request(data),
      getDailyFunnels: data => getDailyFunnels.request(data),
      getUsersHistory: data => getUsersHistory.request(data),
      getTarget: data => getTarget.request(data),
      getTotals: data => getTotals.request(data),
      resetLoadedFlags: () => resetDahboardLoadedFlags(),
    }
  )
)(DashboardsPage);
export { DashboardsPage };
