import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { withTheme } from 'common/styling/theme';
import { LightBoxGallery } from '../../light-box';

import { staticStyles, getDynamicStyles } from './style';
import fileIcon from '../../../images/pdf.svg';

const LightBoxModal = ({ currentImage, onRequestClose, images, src, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const hasVScroll = document.body.scrollHeight > document.body.clientHeight;
  const isDeviceTouch = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (!hasVScroll) {
    document.body.classList.add('Body__no-scroll');
  } else {
    document.body.classList.remove('Body__no-scroll');
  }

  if (isDeviceTouch()) {
    document.body.classList.add('Body__touch-screen');
  } else {
    document.body.classList.remove('Body__touch-screen');
  }

  return (
    <Modal isOpen={currentImage !== -1} onRequestClose={onRequestClose}>
      <button className="LightBoxModal__close" type="button" onClick={onRequestClose}>
        <span />
      </button>

      {currentImage !== -1 && (
        <LightBoxGallery initialSlide={currentImage}>
          {images.map(image => {
            const url = src ? image[src] : image;
            return (
              <div
                role="presentation"
                className="SliderItem"
                onClick={onRequestClose}
                onKeyPress={onRequestClose}
                key={url}>
                <button type="button" onClick={e => e.stopPropagation()}>
                  {url && url.endsWith('.pdf') ? (
                    <button
                      className="LightBoxGallery__pdf"
                      onClick={() => {
                        window.open(url, '_blank');
                      }}>
                      <img src={fileIcon} alt={image.title} />
                      <span>{image.title}</span>
                    </button>
                  ) : (
                    <img src={url} alt={image.title} />
                  )}
                </button>
              </div>
            );
          })}
        </LightBoxGallery>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </Modal>
  );
};

LightBoxModal.propTypes = {
  currentImage: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  src: PropTypes.string,
  theme: PropTypes.object,
};

LightBoxModal.defaultProps = {
  currentImage: -1,
  src: '',
  theme: {},
};

export default withTheme()(LightBoxModal);
export { LightBoxModal };
