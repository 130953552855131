import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { LightBoxModal } from 'common/components';
import { closeLightBox } from 'interface/user/_redux/actions';
import { withTheme } from 'common/styling/theme';

import { getWithdrawalProof } from 'backoffice/users/_redux/actions';
import UserDocument from '../UserDocument';
import { getPaymentProof } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const UserDepositProofForm = ({
  status,
  paymentProof,
  withdrawalProof,
  getPaymentProof,
  getWithdrawalProof,
  user,
  isDeposit,
  lightBoxCurrentImage,
  lightBoxImages,
  closeLightBox,
  error,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const match = useRouteMatch();

  useEffect(() => {
    if (isDeposit) {
      getPaymentProof(match.params.id, isDeposit);
    } else {
      getWithdrawalProof(match.params.id);
    }
  }, [getPaymentProof, getWithdrawalProof, match.params.id, isDeposit]);

  return (
    <form className="UserDepositProofForm">
      <FormattedMessage id="justPaymentProof">{txt => <h2>{txt}</h2>}</FormattedMessage>
      <div className="UserDepositProofForm__block-text">
        <UserDocument
          doc="proof"
          user={user}
          statusId={`${status}`}
          file={isDeposit ? paymentProof : withdrawalProof}
          name="paymentProof"
          fileNameId="justPaymentProof"
          uploadError={null}
          depositId={match.params.id}
          isDeposit={isDeposit}
          isProof
        />
      </div>

      {error && <strong className="formError">{error}</strong>}

      <LightBoxModal images={lightBoxImages} currentImage={lightBoxCurrentImage} onRequestClose={closeLightBox} />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </form>
  );
};

UserDepositProofForm.propTypes = {
  status: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  paymentProof: PropTypes.object,
  withdrawalProof: PropTypes.object,
  getPaymentProof: PropTypes.func.isRequired,
  getWithdrawalProof: PropTypes.func.isRequired,
  isDeposit: PropTypes.bool,
  error: PropTypes.string,
  lightBoxCurrentImage: PropTypes.number,
  lightBoxImages: PropTypes.array,
  closeLightBox: PropTypes.func,
  theme: PropTypes.object,
};

UserDepositProofForm.defaultProps = {
  error: '',
  closeLightBox: null,
  lightBoxCurrentImage: -1,
  lightBoxImages: [],
  isDeposit: false,
  theme: {},
  paymentProof: {},
  withdrawalProof: {},
};

export default compose(
  connect(
    state => ({
      paymentProof: state.backoffice.payments.paymentProof,
      withdrawalProof: state.backoffice.users.withdrawalProof,
      lightBoxCurrentImage: state.user.lightBoxCurrentImage,
      lightBoxImages: state.user.lightBoxImages,
    }),
    {
      getPaymentProof: (id, isDeposit) => getPaymentProof.request(id, isDeposit),
      getWithdrawalProof: id => getWithdrawalProof.request(id),
      closeLightBox,
    }
  ),
  reduxForm({ form: 'boUserDepositProof' }),
  withTheme()
)(UserDepositProofForm);
export { UserDepositProofForm };
