import css from 'styled-jsx/css';

const staticStyles = css.global`
  .TwoStepGoogleForm {
    background: #fafafa;
    border-radius: 12px;

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__header {
      padding: 32px 24px;
      text-align: center;
      border-bottom: 1px solid #f0f0f0;

      &__link {
        font-weight: 500;
      }
    }

    &__qr-code {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;

      &__image {
        width: 160px;
        height: 160px;
      }

      &__title {
        font-weight: 500;
        font-size: 16px;
      }
    }

    &__secret-key {
      text-align: center;
    }

    &__field {
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__code {
        display: flex;
        gap: 16px;
      }

      .ReduxTextField {
        width: 100%;
      }

      .BaseTextField__inp {
        background: #fff;
      }

      .BaseTextField__label {
        z-index: 1111;
      }
    }

    &__submit-button {
      height: 53px;
    }

    &__cancel-button {
      margin-top: 24px;
      align-self: flex-end;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TwoStepGoogleForm {
      &__header {
        &__link {
          color: ${colors.primary.hex};
        }
      }

      &__cancel-button {
        color: ${colors.black.hex} !important;
        border-color: #f0f0f0 !important;

        &:hover {
          background: #f0f0f0 !important;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
