import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';

import noUsersImage from 'common/images/noUsersImage.svg';

import {
  PageLayout,
  NoItemsPanel,
  PageContentPreloader,
  SearchPanel,
  BasicModal,
  DefaultButton,
  BaseSelectField,
  RadioButton,
  DateRangeField,
} from 'common/components';
import UsersIcon from 'interface/user/_components/LeftNavigationMenuIcons/Icons/UsersIcon';
import ImportCsvForm from 'backoffice/users/_components/ImportCsvForm';
import ImportServerForm from 'backoffice/users/_components/ImportServerForm';
import { Checkbox } from 'backoffice/invest/_components';
import { getCountries } from 'client/KYC/_redux';
import { withTheme } from 'common/styling/theme';

import { createBackofficeUserExports, clearCreateError } from 'common/_redux';
import { ModalHOC } from 'common';
import { BOUsersTable, ManagerClientInfo, UserCreateForm } from '../../_components';
import { getUsers, getManagerClient, getFreeClientsCount, getUserStatuses } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const yearsOptions = [...Array(10).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const UsersPage = ({
  users,
  profile,
  getUsers,
  getManagerClient,
  usersAreLoaded,
  getUserStatuses,
  userStatuses,
  countries,
  getCountries,
  intl,
  exportUser,
  managerClient,
  managerClientIsLoading,
  freeClientsCount,
  getFreeClientsCount,
  createBackofficeUserExports,
  userCreateExportDataError,
  clearCreateError,
  isExportRedirect,
  addons,
  features,
  kycSettings,
  showModal,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importModal, setImportModal] = useState('server');
  const [currentModal, setCurrentModal] = useState(null);
  const importModalTitle = importModal === 'server' ? 'justImportServer' : 'justImportCrm';
  const withStatuses = addons.includes('statusesSystem');

  const getClientModalIsOpen = isModalOpen && !managerClientIsLoading;
  const getClientModalCaptionId = managerClient ? 'getClientSuccessfully' : 'justOoops';

  const toggleModal = modalName => {
    setCurrentModal(modalName);
    setIsModalOpen(!isModalOpen);
  };

  const toggleImportModal = () => {
    setIsImportModalOpen(!isImportModalOpen);
  };

  useEffect(() => {
    getUsers({ search: query });
  }, [getUsers, query]);

  useEffect(() => {
    if (withStatuses) {
      getUserStatuses();
    }
  }, [getUserStatuses]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (isExportRedirect) history.push('/backoffice/users/exports');
  }, [isExportRedirect, history]);

  const isSearchPanelShown = users.count || !!Object.keys(query).length;
  const handleGetUsers = useCallback(() => getUsers({ search: query }), [getUsers, query]);
  const withManagers = addons.includes('managerSystem');
  const withWallet = addons.includes('wallet');
  const hasPaymentOperationManagers = features.includes('paymentOperationManager');

  useEffect(() => {
    if (withManagers && profile.isManager && profile.perms.getClientPerm) getFreeClientsCount();
  }, [getFreeClientsCount, withManagers, profile]);

  /* eslint-disable */
  useEffect(() => {
    handleGetUsers();
  }, []);
  /* eslint-enable */

  const handleRowClick = (_rowId, rowInfo) => history.push(`/backoffice/users/${rowInfo.original?.id ?? rowInfo.id}`);

  const totalPaymentsOptions = [
    { value: '', label: intl.formatMessage({ id: 'justAll' }) },
    {
      value: ['0', '300'],
      label: intl.formatMessage({ id: 'justLessThanAmount' }, { amount: 300 }),
    },
    {
      value: ['301', '500'],
      label: '301 - 500',
    },
    {
      value: ['501', '1000'],
      label: '501 - 1000',
    },
    {
      value: ['1001', '99999999'],
      label: intl.formatMessage({ id: 'justAboveThanAmount' }, { amount: 1001 }),
    },
  ];
  const countriesOptions = countries.map(option => ({
    value: option.gid,
    label: option.name,
  }));

  const filters = [
    {
      title: 'justVerificationStatus',
      accessor: 'verification_status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'incomplete', label: intl.formatMessage({ id: 'status_incomplete' }) },
        { value: 'verified', label: intl.formatMessage({ id: 'status_verified' }) },
        { value: 'verified_new_documents', label: intl.formatMessage({ id: 'status_verified_new_documents' }) },
        { value: 'need_income_sources', label: intl.formatMessage({ id: 'status_need_income_sources' }) },
        { value: 'unverified', label: intl.formatMessage({ id: 'status_unverified' }) },
      ].filter(item => (!kycSettings.enabled ? item.value !== 'verified' : item)),
    },
    {
      title: 'justDocumentStatus',
      accessor: 'document_status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'rejected', label: intl.formatMessage({ id: 'status_rejected' }) },
        { value: 'verified', label: intl.formatMessage({ id: 'status_verified' }) },
        { value: 'deprecated', label: intl.formatMessage({ id: 'status_deprecated' }) },
        { value: 'unverified', label: intl.formatMessage({ id: 'status_unverified' }) },
        { value: 'disabled', label: intl.formatMessage({ id: 'status_disable' }) },
      ].filter(item => (!kycSettings.enabled ? item.value !== 'verified' : item)),
    },
    {
      title: 'justFilterByLastDate',
      accessor: 'ordering',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justNoOrdering' }) },
        { value: 'last_document_uploaded', label: intl.formatMessage({ id: 'justOldestFirst' }) },
        { value: '-last_document_uploaded', label: intl.formatMessage({ id: 'justNewestFirst' }) },
      ],
    },
    {
      title: 'justProfileValidity',
      accessor: 'is_active',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'True', label: intl.formatMessage({ id: 'status_valid' }) },
        { value: 'False', label: intl.formatMessage({ id: 'status_blocked' }) },
      ],
    },
    ...(withStatuses
      ? [
          {
            title: 'justStatus',
            accessor: 'addon_status',
            options: [{ value: '', label: intl.formatMessage({ id: 'justAll' }) }, ...userStatuses],
          },
        ]
      : []),
    {
      title: 'justTotalDeposits',
      accessor: ['total_deposits_amount_from', 'total_deposits_amount_to'],
      options: totalPaymentsOptions,
    },
    {
      title: 'justTotalWithdrawals',
      accessor: ['total_withdrawals_amount_from', 'total_withdrawals_amount_to'],
      options: totalPaymentsOptions,
    },
    {
      title: 'justCountry',
      accessor: 'country',
      options: [{ value: '', label: intl.formatMessage({ id: 'justAll' }) }, ...countriesOptions],
      searchable: true,
    },
  ];

  const inputs = [
    {
      title: 'justPartner',
      accessor: 'partner_account',
    },
    {
      title: 'justIbAccount',
      accessor: 'ib_account',
    },
    ...(withManagers
      ? [
          {
            title: 'justManager',
            accessor: 'manager',
          },
        ]
      : []),
  ];

  const handleGetManagerClient = () => {
    getManagerClient({ search: query });
    toggleModal('getClientModal');
  };

  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState();

  const [isCreateFullExport, setIsCreateFullExport] = useState(true);
  const [isBalanceToEndDate, setIsBalanceToEndDate] = useState(withWallet ? false : undefined);

  const handleBalanceCheckboxToggle = () => {
    setIsBalanceToEndDate(checked => !checked);
  };

  const dateRangeField = {
    title: 'justUserLastActivity',
    queryParam: 'last_seen',
    onlyParam: true,
  };

  useEffect(() => {
    if (isExportMenuModalOpen) {
      setSelectedTimePeriod(
        `${moment(moment().add(-7, 'day').startOf('day')).format('YYYY-MM-DD')}_${moment(
          moment().startOf('day')
        ).format('YYYY-MM-DD')}`
      );
    }
  }, [isExportMenuModalOpen]);

  return (
    <PageLayout captionSlug="boUsers" showReload onReloadClick={handleGetUsers} reloading={!usersAreLoaded}>
      <PageContentPreloader ready={usersAreLoaded} firstLaunchOnly>
        {isSearchPanelShown && (
          <SearchPanel
            location={location}
            loading={!usersAreLoaded}
            history={history}
            getList={getUsers}
            filters={filters}
            inputs={inputs}
            params={{ search: query }}
            dateRangeField={dateRangeField}
          />
        )}
        <div className="UsersPage__actions">
          <div className="UsersPage__create-button">
            <DefaultButton
              textId="justCreateUser"
              filled
              onClick={() => toggleModal('userCreateModal')}
              loading={!usersAreLoaded}
              plus={usersAreLoaded}
            />
          </div>
          {withManagers && profile.isManager && profile.perms.getClientPerm && (
            <div
              className={classNames('UsersPage__clients', {
                'UsersPage__clients--withoutSearchPanel': !isSearchPanelShown,
              })}>
              <DefaultButton
                textId="justGetClient"
                filled
                onClick={handleGetManagerClient}
                loading={managerClientIsLoading}
              />
              <div className="UsersPage__free-clients">
                <div className="UsersPage__client-icon">
                  <UsersIcon />
                  <span className="UsersPage__clients-count">{freeClientsCount}</span>
                </div>
                <FormattedMessage id="justFreeClients">
                  {txt => <span className="UsersPage__free-clients-text">{txt}</span>}
                </FormattedMessage>
              </div>
            </div>
          )}
          <div className="UsersPage__import-export-selects">
            {exportUser && (
              <div className="UsersPage__import-export-select">
                <BaseSelectField
                  name="import"
                  textId="justUserExport"
                  options={[
                    { value: 'showExports', label: 'Show exports' },
                    { value: 'xlsx', label: 'Export in xlsx' },
                  ]}
                  onChange={option => {
                    if (option.value === 'showExports') {
                      history.push('/backoffice/users/exports');
                    } else {
                      // setExportExt(option.value);
                      setIsExportMenuModalOpen(true);
                    }
                  }}
                  maxMenuHeight={170}
                />
              </div>
            )}

            <div className="UsersPage__import-export-select">
              <BaseSelectField
                name="import"
                textId="justUserImport"
                options={[
                  { value: 'showImports', label: 'Show imports' },
                  { value: 'csv', label: 'From CSV' },
                  { value: 'server', label: 'From server' },
                ]}
                onChange={option => {
                  if (option.value === 'showImports') {
                    history.push('/backoffice/imports');
                  } else {
                    setImportModal(option.value);
                    setIsImportModalOpen(true);
                  }
                }}
                maxMenuHeight={170}
              />
            </div>
          </div>
        </div>

        {users.count ? (
          <BOUsersTable
            data={users}
            loading={!usersAreLoaded}
            location={location}
            history={history}
            getList={getUsers}
            onRowClick={handleRowClick}
            params={{ search: query }}
            hasPaymentOperationManagers={hasPaymentOperationManagers}
            kycSettings={kycSettings}
            showModal={showModal}
            addons={addons}
            profilePerms={profile.perms}
            withManagers={withManagers}
            withStatuses={withStatuses}
          />
        ) : (
          <NoItemsPanel
            icon={noUsersImage}
            captionSlug={Object.keys(query).length ? 'noUsersMatchingTheParameters' : 'noUsersYet'}
          />
        )}
      </PageContentPreloader>

      <BasicModal
        isOpen={currentModal === 'getClientModal' ? getClientModalIsOpen : isModalOpen}
        captionId={currentModal === 'getClientModal' ? getClientModalCaptionId : 'justCreateUser'}
        onRequestClose={toggleModal}>
        {currentModal === 'getClientModal' && (
          <ManagerClientInfo managerClient={managerClient} toggleModal={toggleModal} />
        )}
        {currentModal === 'userCreateModal' && (
          <UserCreateForm toggleModal={toggleModal} handleGetUsers={handleGetUsers} showModal={showModal} />
        )}
      </BasicModal>

      <BasicModal isOpen={isImportModalOpen} captionId={importModalTitle} onRequestClose={toggleImportModal}>
        {importModal === 'server' ? (
          <ImportServerForm toggleModal={toggleImportModal} history={history} />
        ) : (
          <ImportCsvForm toggleModal={toggleImportModal} history={history} />
        )}
      </BasicModal>

      <BasicModal isOpen={userCreateExportDataError} captionId="justError" onRequestClose={clearCreateError}>
        <FormattedMessage id="errorOnExportUsers" />
      </BasicModal>

      <BasicModal
        captionId="selectTimePeriodOfExport"
        isOpen={isExportMenuModalOpen}
        onRequestClose={() => setIsExportMenuModalOpen(false)}>
        <div className="UserPageExports">
          <RadioButton
            name="investFrom"
            textId="createExportForAllTime"
            value="all"
            onChange={() => setIsCreateFullExport(true)}
            checked={isCreateFullExport}
          />
          <RadioButton
            name="investFrom"
            textId="createExportForTimePeriod"
            value="all"
            onChange={() => setIsCreateFullExport(false)}
            checked={!isCreateFullExport}
          />
          <div style={{ width: 'max-content', display: !isCreateFullExport ? 'flex' : 'none' }}>
            <DateRangeField
              yearsOptions={yearsOptions}
              input={{ value: selectedTimePeriod, onChange: setSelectedTimePeriod }}
              meta={{ touched: true, error: false }}
              noRedux
              relativeInner
            />
          </div>
          {!isCreateFullExport && withWallet && (
            <div className="UsersPage__export-checkbox">
              <Checkbox checked={isBalanceToEndDate} onClick={handleBalanceCheckboxToggle} />
              <FormattedMessage id="justBalanceAtEndDate">
                {txt => (
                  <button className="UsersPage__export-checkbox-label" onClick={handleBalanceCheckboxToggle}>
                    {txt}
                  </button>
                )}
              </FormattedMessage>
            </div>
          )}
          <DefaultButton
            textId="justExport"
            type="button"
            filled
            disabled={!selectedTimePeriod && !isCreateFullExport}
            onClick={() => {
              setIsExportMenuModalOpen(false);
              createBackofficeUserExports({
                timePeriod: isCreateFullExport ? undefined : selectedTimePeriod,
                balanceToDateEnd: isCreateFullExport ? undefined : isBalanceToEndDate,
              });
            }}
          />
        </div>
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

UsersPage.propTypes = {
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  addons: PropTypes.array.isRequired,
  userStatuses: PropTypes.array.isRequired,
  features: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUserStatuses: PropTypes.func.isRequired,
  getManagerClient: PropTypes.func.isRequired,
  createBackofficeUserExports: PropTypes.func.isRequired,
  getFreeClientsCount: PropTypes.func.isRequired,
  clearCreateError: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  usersAreLoaded: PropTypes.bool.isRequired,
  managerClient: PropTypes.object,
  managerClientIsLoading: PropTypes.bool.isRequired,
  userCreateExportDataError: PropTypes.bool.isRequired,
  isExportRedirect: PropTypes.bool.isRequired,
  freeClientsCount: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  kycSettings: PropTypes.object.isRequired,
  exportUser: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

UsersPage.defaultProps = {
  managerClient: null,
  exportUser: false,
  theme: {},
};

export default connect(
  state => ({
    users: state.backoffice.users.users,
    usersAreLoaded: state.backoffice.users.usersAreLoaded,
    userStatuses: state.backoffice.users.userStatuses,
    managerClient: state.backoffice.users.managerClient,
    managerClientIsLoading: state.backoffice.users.managerClientIsLoading,
    freeClientsCount: state.backoffice.users.freeClientsCount,
    countries: state.client.kyc.countries,
    userCreateExportDataIsLoading: state.common.userCreateExportDataIsLoading,
    userCreateExportDataError: state.common.userCreateExportDataError,
    isExportRedirect: state.common.isExportRedirect,
    exportUser: state.user.profile.perms.exportUser || state.user.profile.perms.managerExportUser,
    kycSettings: state.interfaceConfig.kycSettings,
    addons: state.interfaceConfig.addons,
    features: state.interfaceConfig.features,
    profile: state.user.profile,
    error: state.backoffice.users.error,
  }),
  {
    getUsers: search => getUsers.request(search),
    getManagerClient: data => getManagerClient.request(data),
    getFreeClientsCount: () => getFreeClientsCount.request(),
    createBackofficeUserExports: ({ timePeriod, balanceToDateEnd }) =>
      createBackofficeUserExports.request({ timePeriod, balanceToDateEnd }),
    clearCreateError: () => clearCreateError(),
    getUserStatuses: () => getUserStatuses.request(),
    getCountries: () => getCountries.request(),
  }
)(injectIntl(ModalHOC(withTheme()(UsersPage))));
export { UsersPage };
