import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import { BaseTextField, BaseSelectField, RangeField, BaseDateRangeField } from '../form-fields';

import { staticStyles } from './style';

const SearchPanel = ({
  history,
  location,
  filters,
  inputs,
  rangeFields,
  dateRangeField,
  withoutSearch,
  getList,
  params,
  loading,
  searchTextId,
  searchQueryId,
}) => {
  const query = useMemo(() => queryString.parse(location.search), [location.search]);

  const [search, setSearch] = useState(query.search || '');
  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    if (!Array.isArray(inputs)) {
      return;
    }
    const newFieldValues = {};
    inputs.forEach(({ accessor }) => {
      newFieldValues[accessor] = query[accessor];
    });
    setFieldValues(fieldValues => ({ ...fieldValues, ...newFieldValues }));
  }, [inputs, query]);

  useEffect(() => {
    if (!Array.isArray(filters)) {
      return;
    }
    const findOptionByValue = (value, options) => {
      const option = options.find(option =>
        // option.value is array when accessor is an array
        Array.isArray(option.value) ? option.value.includes(value) : option.value?.toString() === value?.toString()
      );
      return { value, label: option?.label || '' };
    };
    const newFieldValues = {};
    // for each filter update current value in the field
    filters.forEach(({ accessor, isMulti, options }) => {
      if (isMulti) {
        // if there is an error in query parameters, take an empty array so that nothing is displayed in the table
        const value = query[accessor] ?? [];
        // in multiselect there can be selected single value or array
        const queryValues = Array.isArray(value) ? value : [value];
        newFieldValues[accessor] = queryValues.map(value => findOptionByValue(value, options));
      } else {
        // in filters, accessor can be an array or a single value
        const accessors = Array.isArray(accessor) ? accessor : [accessor];
        accessors.forEach(singleAccessor => {
          const queryValue = query[singleAccessor] || '';
          newFieldValues[singleAccessor] = findOptionByValue(queryValue, options);
        });
      }
    });
    setFieldValues(fieldValues => ({ ...fieldValues, ...newFieldValues }));
  }, [filters, query]);

  const handleChange = (queryName, search) => {
    let query = { page: 1, page_size: 10, ...params.search };

    if (Array.isArray(queryName)) {
      const rangeAccessors = queryName.reduce((acc, key, index) => ({ ...acc, [key]: search.value[index] }), {});
      query = { ...query, ...rangeAccessors };
    } else {
      query = { ...query, [queryName]: search || undefined };
    }

    history.replace({
      ...location,
      search: queryString.stringify(query),
    });

    if (searchQueryId) {
      getList({ search: query, id: searchQueryId });

      return;
    }

    getList({ search: query });
  };

  const handleSearchChange = e => {
    const search = e.target.value;
    setSearch(search);
  };

  const handleSearchKeyPress = e => {
    const query = { page: 1, page_size: 10, ...params.search, search: search || undefined };
    const { key } = e;

    if (key === 'Enter') {
      history.replace({
        ...location,
        search: queryString.stringify(query),
      });

      if (searchQueryId) {
        getList({ search: query, id: searchQueryId });

        return;
      }

      getList({ search: query });
    }
  };

  const handleInputsChange = (accessor, e) => {
    setFieldValues({ ...fieldValues, [accessor]: e.target.value });
    handleChange(accessor, e.target.value, true);
  };

  const handleFilterChange = (accessor, options, isMulti) => {
    if (Array.isArray(accessor)) {
      const rangeAccessors = accessor.reduce(
        (acc, key, index) => ({ ...acc, [key]: { value: options.value[index], label: options.label } }),
        {}
      );

      setFieldValues({ ...fieldValues, ...rangeAccessors });
      handleChange(accessor, options);

      return;
    }

    setFieldValues({ ...fieldValues, [accessor]: options });
    let value;
    if (options) {
      value = isMulti ? options.map(option => option.value) : options.value;
    }
    handleChange(accessor, value);
  };

  const handleMobileFilterChange = (accessor, e) => {
    const value = e.target.value;
    const label = e.target.options[e.target.selectedIndex].text;
    setFieldValues({ ...fieldValues, [accessor]: { value, label } });
    handleChange(accessor, value);
  };

  const handleRangeFieldChange = ([minSize, maxSize]) => {
    const query = {
      page: 1,
      page_size: 10,
      ...params.search,
      [rangeFields.queryMin]: minSize,
      [rangeFields.queryMax]: maxSize,
    };
    history.replace({
      ...location,
      search: queryString.stringify(query),
    });

    if (searchQueryId) {
      getList({ search: query, id: searchQueryId });

      return;
    }

    getList({ search: query });
  };

  const getDateRangeFieldValue = () => {
    // если param from/to объединен в один
    if (dateRangeField.onlyParam) {
      const dateParam = query[dateRangeField.queryParam];

      if (!dateParam) return '';

      return dateParam;
    }

    const startDate = query[dateRangeField.queryStartDate];
    const endDate = query[dateRangeField.queryEndDate];

    if (!startDate || !endDate) return '';

    return `${startDate}_${endDate}`;
  };

  const handleDateRangeFieldChange = date => {
    const [startDate, endDate] = date.split('_');

    const query = { page: 1, page_size: 10, ...params.search };

    if (dateRangeField.onlyParam) {
      query[dateRangeField.queryParam] = date;
    } else {
      query[dateRangeField.queryStartDate] = startDate;
      query[dateRangeField.queryEndDate] = endDate;
    }

    history.replace({
      ...location,
      search: queryString.stringify(query),
    });
    getList({ search: query });
  };

  return (
    <div className="SearchPanel">
      <div className="SearchPanel__inputs-wrapper">
        {!withoutSearch && (
          <BaseTextField
            type="search"
            name="search"
            textId={searchTextId || 'justSearch'}
            value={search}
            loading={loading}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
          />
        )}
        {!!inputs.length &&
          inputs.map((field, id) => (
            <BaseTextField
              type="search"
              name={field.accessor}
              textId={field.title}
              onChange={value => handleInputsChange(field.accessor, value)}
              value={fieldValues[field.accessor] ?? ''}
              key={`input_${id}`}
            />
          ))}
        {!!filters.length &&
          filters.map((filter, id) => (
            <BaseSelectField
              value={Array.isArray(filter.accessor) ? fieldValues[filter.accessor[0]] : fieldValues[filter.accessor]}
              name={filter.accessor}
              textId={filter.title}
              onChange={value => handleFilterChange(filter.accessor, value, filter.isMulti)}
              onMobileChange={e => handleMobileFilterChange(filter.accessor, e)}
              options={filter.options}
              isMulti={filter.isMulti}
              placeholder={filter.placeholder}
              searchable={!!filter.searchable}
              key={`filter_${id}`}
            />
          ))}
        {!!rangeFields && (
          <RangeField
            textId="justSize"
            formatLabelText="Mb"
            minValue={rangeFields.minValue}
            maxValue={rangeFields.maxValue}
            onFinalChange={handleRangeFieldChange}
          />
        )}
        {!!dateRangeField && (
          <BaseDateRangeField
            placeholderId={dateRangeField.title}
            value={getDateRangeFieldValue()}
            onChange={handleDateRangeFieldChange}
          />
        )}
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

SearchPanel.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  filters: PropTypes.array,
  inputs: PropTypes.array,
  params: PropTypes.object.isRequired,
  rangeFields: PropTypes.object,
  dateRangeField: PropTypes.object,
  withoutSearch: PropTypes.bool,
  loading: PropTypes.bool,
  searchTextId: PropTypes.string,
  searchQueryId: PropTypes.string,
};

SearchPanel.defaultProps = {
  filters: [],
  inputs: [],
  dateRangeField: null,
  rangeFields: null,
  withoutSearch: false,
  loading: false,
  searchTextId: null,
  searchQueryId: null,
};

export default injectIntl(SearchPanel);
export { SearchPanel };
