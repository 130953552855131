import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DefaultButton, ReduxTextField } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { setAuthorized } from 'auth/login/_redux';
import { setInitialEmailVerifyStep } from 'auth/twoStepAuth/_redux';

import { staticStyles, getDynamicStyles } from './style';

const TwoStepEmailForm = ({
  isRegister,
  isVerify,
  isReset,
  withdrawalId,
  rememberUser,
  onClose,
  onSubmit,
  onGetCode,
  onResend,
  theme,
  handleSubmit,
  dispatch,
  submitting,
  error,
  canMoveToVerifyStep,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [step, setStep] = useState(isVerify ? 'verify' : 'getCode');
  const [isGetCodeLoading, setIsGetCodeLoading] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const timerRef = useRef(null);

  const handleTimerReset = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const handleTimerStart = () => {
    if (timerRef.current) return;

    setResendTime(30);

    timerRef.current = setInterval(() => {
      setResendTime(prevTime => {
        if (prevTime === 0) {
          handleTimerReset();

          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);
  };

  const submit = handleSubmit(values => {
    if (isVerify) {
      if (rememberUser) {
        return onSubmit({ ...values, rememberMe: rememberUser }, dispatch);
      }

      if (withdrawalId) {
        return onSubmit({ id: withdrawalId, ...values }, dispatch);
      }

      return onSubmit(values, dispatch);
    }

    if (isReset) {
      return onSubmit(values, dispatch);
    }

    return onSubmit({ ...values, otpType: 'email' }, dispatch);
  });

  const handleResend = () => {
    handleTimerStart();
    dispatch(onResend());
  };

  const handleGetCode = () => {
    dispatch(onGetCode());
    setIsGetCodeLoading(true);
    handleTimerStart();
  };

  const handleClose = () => {
    onClose();

    if (isRegister) {
      dispatch(setAuthorized());
    }
  };

  useEffect(() => {
    if (canMoveToVerifyStep) {
      setStep('verify');
      setIsGetCodeLoading(false);
    }
  }, [canMoveToVerifyStep]);

  useEffect(() => {
    if (isVerify) {
      handleTimerStart();
    }

    return () => {
      handleTimerReset();
      dispatch(setInitialEmailVerifyStep());
    };
  }, []);

  return (
    <div className="TwoStepEmailForm__wrapper">
      <form className="TwoStepEmailForm" onSubmit={submit}>
        {step === 'getCode' && (
          <div className="TwoStepEmailForm__get-code-block">
            <FormattedMessage id="authEmailWillBeSent">
              {txt => <span className="TwoStepEmailForm__get-code-block__caption">{txt}.</span>}
            </FormattedMessage>
            <DefaultButton
              loading={isGetCodeLoading}
              disabled={isGetCodeLoading}
              textId="authGetCode"
              filled
              onClick={handleGetCode}
            />
          </div>
        )}
        {step === 'verify' && (
          <div className="TwoStepEmailForm__message-sent-block">
            <div className="TwoStepEmailForm__message-sent-block__captions">
              <FormattedMessage id="authMessageWasSent">
                {txt => <p className="TwoStepEmailForm__message-was-sent">{txt}.</p>}
              </FormattedMessage>
              <FormattedMessage id="authPleaseEnterVerificationCode">
                {txt => <p className="TwoStepEmailForm__message-enter-code">{txt}:</p>}
              </FormattedMessage>
            </div>
            <div className="TwoStepEmailForm__code-field">
              <Field
                component={ReduxTextField}
                name="otp"
                textId="authCode"
                disabled={submitting}
                validate={[required()]}
              />
              <DefaultButton
                type="submit"
                textId="justVerify"
                filled
                className="TwoStepEmailForm__submit-button"
                disabled={submitting}
              />
            </div>
            <div className="TwoStepEmailForm__resendCode">
              <FormattedMessage
                id="authResentCodeIn"
                values={{ time: resendTime < 10 ? `0${resendTime}` : resendTime }}
              />
              <FormattedMessage id="authResendCode">
                {txt => (
                  <button
                    className={classNames('TwoStepEmailForm__resendCode-btn', {
                      'TwoStepEmailForm__resendCode-btn-disabled': resendTime !== 0,
                    })}
                    disabled={resendTime !== 0}
                    onClick={handleResend}>
                    {txt}
                  </button>
                )}
              </FormattedMessage>
            </div>
            {error && <strong className="formError">{error}</strong>}
          </div>
        )}
      </form>
      <DefaultButton textId="justCancel" className="TwoStepEmailForm__cancel-button" onClick={handleClose} />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TwoStepEmailForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onGetCode: PropTypes.func,
  canMoveToVerifyStep: PropTypes.bool,
  isRegister: PropTypes.bool,
  isVerify: PropTypes.bool,
  isReset: PropTypes.bool,
  withdrawalId: PropTypes.number,
  error: PropTypes.string,
  rememberUser: PropTypes.bool,
  theme: PropTypes.object,
};

TwoStepEmailForm.defaultProps = {
  onGetCode: () => {},
  canMoveToVerifyStep: false,
  isRegister: false,
  isVerify: false,
  isReset: false,
  rememberUser: false,
  withdrawalId: null,
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    canMoveToVerifyStep: state.auth.tfa.canMoveToVerifyStep,
  })),
  reduxForm({
    form: 'TwoStepEmailForm',
    onSubmitSuccess: (result, dispatch, { onClose, onSuccess, isChangingFirstStep }) => {
      if (isChangingFirstStep) {
        onSuccess();

        return;
      }

      onClose();
      onSuccess?.('authCodesWillBeSentEmail');
    },
  })
)(TwoStepEmailForm);
