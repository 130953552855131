import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  PaginationTable,
  MoneyCell,
  DefaultButton,
  ActionsButton,
  DateCell,
  TableLoader,
  RowLink,
} from 'common/components';
import { DepositIcon, WithdrawalIcon, CrossIcon, EditIcon, DownloadIcon } from 'common/icons';

import { staticStyles } from './style';

const InvestmentsTable = ({
  data,
  activeTab,
  prevTab,
  onRowClick,
  loading,
  getList,
  location,
  tempNewInvestDisableCanselRequestButton,
  history,
  params,
  onTableAction,
  theme,
  isNewInevest,
  ...props
}) => {
  const investmentIdSlug = document.location.hostname === 'portal.motforex.com' ? 'investmentIdMot' : 'investmentId';

  const getRowLink = id => {
    if (isNewInevest) {
      return `/investNew/investments/${id}/open`;
    }

    return `/invest/investments/${id}/open`;
  };

  /* eslint-disable */
  const activeColumns = [
    {
      accessor: 'title',
      Header:
        document.location.hostname === 'my.tixee.com' ||
        document.location.hostname === 'portal.disona.com' ||
        document.location.hostname === 'portal.rock-west.com'
          ? 'rammInvestmentTixee'
          : 'rammInvestment',
      Cell: ({
        row: {
          original: { id, strategy },
        },
      }) => (
        <RowLink isPreviewCell noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <div className="InvestmentsTable__title">
            <span className="title">{strategy.title}</span>
            <span className="masterAccount">
              <FormattedMessage id={investmentIdSlug}>{txt => txt}</FormattedMessage>
              {` #${id}`}
            </span>
          </div>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'equity',
      Header:
        document.location.hostname === 'my.tixee.com' ||
        document.location.hostname === 'portal.disona.com' ||
        document.location.hostname === 'portal.rock-west.com'
          ? 'rammInvestmentEquityTixee'
          : 'rammInvestmentEquity',
      Cell: ({
        row: {
          original: { equity, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <MoneyCell inTable value={equity} />
        </RowLink>
      ),
    },
    ...(activeTab === 'active' && isNewInevest
      ? [
          {
            accessor: 'balance',
            Header: 'justBalance',
            Cell: ({
              row: {
                original: { balance, id },
              },
            }) => (
              <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
                <MoneyCell inTable value={balance} />
              </RowLink>
            ),
          },
        ]
      : []),
    {
      accessor: 'pnl',
      Header: 'rammCurrentIncome',
      Cell: ({
        row: {
          original: { pnl, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} isPreviewCell link={getRowLink(id)}>
          <MoneyCell inTable value={pnl} />
        </RowLink>
      ),

      isPreviewCell: true,
    },
    ...(!isNewInevest
      ? [
          {
            accessor: 'takeProfit',
            Header: 'justTakeProfit',
            Cell: ({
              row: {
                original: { takeProfit, id },
              },
            }) =>
              takeProfit ? (
                <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
                  <MoneyCell inTable value={takeProfit} />
                </RowLink>
              ) : (
                '一'
              ),
          },
          {
            accessor: 'stopLoss',
            Header: 'justStopLoss',
            Cell: ({
              row: {
                original: { stopLoss, id },
              },
            }) =>
              stopLoss ? (
                <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
                  <MoneyCell inTable value={stopLoss} />
                </RowLink>
              ) : (
                '一'
              ),
          },
        ]
      : []),
    ,
    {
      accessor: 'buttons',
      Header: () => {},
      Cell: ({
        row: {
          original: { id, strategy, status, enableOperations, currency },
        },
      }) => (
        <>
          <div className="InvestmentsTable__btns">
            {!isNewInevest && (!strategy.rollover || strategy.takeprofitStoplossInRollovers) && (
              <ActionsButton
                tooltipId={`justEditTpSl_${id}`}
                tooltipTextId="justEditTpSl"
                watch
                onClickFunc={e => onTableAction({ e, id, type: 'edit', strategyCurrency: strategy.currency })}>
                <EditIcon />
              </ActionsButton>
            )}
            {enableOperations && (
              <>
                <ActionsButton
                  tooltipId={`justDeposit_${id}`}
                  tooltipTextId={
                    document.location.hostname === 'portal.motforex.com'
                      ? 'justDepositIntoInvestmentMot'
                      : 'justDepositIntoInvestment'
                  }
                  watch
                  onClickFunc={e => onTableAction({ e, id, type: 'deposit', strategy })}>
                  <DepositIcon />
                </ActionsButton>
                <ActionsButton
                  tooltipId={`justWithdrawal_${id}`}
                  tooltipTextId="justWithdrawal"
                  watch
                  onClickFunc={e => onTableAction({ e, id, type: 'withdraw', status, strategy })}>
                  <WithdrawalIcon />
                </ActionsButton>
              </>
            )}
            <ActionsButton
              tooltipId={`justEditDowload_${id}`}
              tooltipTextId="justCreateExport"
              noFill
              watch
              onClickFunc={e => onTableAction({ e, id, type: 'createExport' })}>
              <DownloadIcon fillTransparent />
            </ActionsButton>

            <ActionsButton
              tooltipId={`rammActionDeactivate_${id}`}
              tooltipTextId="rammActionDeactivate"
              watch
              onClickFunc={e => onTableAction({ e, id, type: 'close', strategy })}>
              <CrossIcon />
            </ActionsButton>
          </div>
        </>
      ),
    },
  ];

  const closedColumns = [
    {
      accessor: 'strategy.id',
      Header: 'justStrategy',
      Cell: ({
        row: {
          original: { strategy, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)} isPreviewCell>
          <span>{strategy.id}</span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'strategy.title',
      Header: 'justInvestment',
      Cell: ({
        row: {
          original: { strategy, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)} isPreviewCell>
          <span className="title">{strategy.title}</span>
        </RowLink>
      ),

      isPreviewCell: true,
    },
    {
      accessor: 'created',
      Header: 'justOpen',
      Cell: ({
        row: {
          original: { created, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <DateCell value={created} />
        </RowLink>
      ),
    },
    {
      accessor: 'closedAt',
      Header: 'justClosed',
      Cell: ({
        row: {
          original: { closedAt, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <DateCell value={closedAt} />
        </RowLink>
      ),
    },
    {
      accessor: 'age',
      Header: 'ageAndWeeks',
      Cell: ({
        row: {
          original: { age, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <span>{age}</span>
        </RowLink>
      ),
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <span>{amount ?? '一'}</span>
        </RowLink>
      ),
    },
    {
      Header: () => null,
      id: 'rammShowStatement',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <DefaultButton
          textId="rammShowStatement"
          type="button"
          onClick={() => history.push(`/invest/investments/${id}`)}
          noBorder
        />
      ),
    },
  ];

  const requestsColumns = [
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} isPreviewCell link={getRowLink(id)}>
          <span>{id}</span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'strategyName',
      Header: 'justStrategy',
      Cell: ({
        row: {
          original: { strategyName, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} isPreviewCell link={getRowLink(id)}>
          <span className="title">{strategyName}</span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'operation',
      Header: 'justType',
      Cell: ({
        row: {
          original: { operation, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <span>{operation}</span>
        </RowLink>
      ),
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <span>{amount}</span>
        </RowLink>
      ),
    },
    {
      accessor: 'currentAmount',
      Header: 'rammCurrentIncome',
      Cell: ({
        row: {
          original: { currentAmount, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <span>{currentAmount}</span>
        </RowLink>
      ),
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      Cell: ({
        row: {
          original: { status, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} isPreviewCell link={getRowLink(id)}>
          <span>{status}</span>
        </RowLink>
      ),

      isPreviewCell: true,
    },
    {
      accessor: 'created',
      Header: 'justDate',
      Cell: ({
        row: {
          original: { created, id },
        },
      }) => (
        <RowLink noRedirect={activeTab === 'requests'} link={getRowLink(id)}>
          <DateCell value={created} />
        </RowLink>
      ),
    },
    {
      Header: () => null,
      id: 'rammShowStatement',
      Cell: ({
        row: {
          original: { id, status },
        },
      }) =>
        status === 'Await' && !tempNewInvestDisableCanselRequestButton ? (
          <DefaultButton
            textId="justCancel"
            type="button"
            onClick={e => onTableAction({ e, id, type: 'cancel_request' })}
            noBorder
          />
        ) : null,
    },
  ];
  /* eslint-enable */

  const columns = activeTab => {
    switch (activeTab) {
      case 'closed':
        return closedColumns;
      case 'requests':
        return requestsColumns;
      default:
        return activeColumns;
    }
  };

  return (
    <div
      className={classNames('InvestmentsTable', {
        'InvestmentsTable-active': activeTab === 'active' && !isNewInevest,
        'InvestmentsTable-active--NEW': activeTab === 'active' && isNewInevest,
        'InvestmentsTable-closed': activeTab === 'closed',
        'InvestmentsTable-requests': activeTab === 'requests',
      })}>
      <PaginationTable
        {...props}
        data={data}
        columns={columns(activeTab)}
        onRowClick={(state, rowInfo) => {
          if (activeTab !== 'requests') {
            onRowClick(rowInfo.original.id);
          }
        }}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      {activeTab !== prevTab && loading && <TableLoader />}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestmentsTable.propTypes = {
  data: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  prevTab: PropTypes.string.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  onTableAction: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestmentsTable.defaultProps = {
  theme: {},
};

export default InvestmentsTable;
