import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnershipUsersTable {
    min-width: 300px;
    @include respond-to(sm) {
      overflow-x: scroll;
    }

    .PartnershipUsersTable__user {
      display: flex;
      align-items: center;

      &__link {
        justify-content: flex-start;
      }

      img {
        border-radius: 50%;
        margin-right: 10px;
      }

      span {
        max-width: 100px;
      }
    }
  }

  .UserTagsPanelClient__tag {
    font-size: rem(16px);
    display: inline-block;
    position: relative;
    padding: rem(8px 30px 10px 10px);
    margin-right: rem(10px);
    line-height: 1;
    button {
      position: absolute;
      cursor: pointer;
      width: rem(18px);
      height: rem(18px);
      right: rem(5px);
      top: 50%;
      transform: translateY(-50%);
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: rem(8px);
        left: 0;
        width: rem(17px);
        height: 1px;
        transition: all 0.4s ease;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .PartnershipUsersTable__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .PartnershipUsersTable {
      :global(.ReactDataTable) {
        tr {
          .ReactDataTable__tbody-cell {
            .time {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
    .UserTagsPanelClient__tag {
      background: ${colors.primary.hex};
      color: ${colors.white.hex};
      border-radius: ${border.radius};
      button {
        &:after,
        &:before {
          background: ${colors.white.hex};
        }
        &:hover {
          &:after,
          &:before {
            background: ${colors.error.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
