import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnerAccountWithdrawalForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 240px;

    :global(.DefaultButton) {
      width: 140px;
    }
    :gloval(.ReduxSelectField) {
      margin-bottom: 25px;
    }
    :global(.CheckboxField) {
      margin-top: 25px;
    }

    &__radio {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 24px;
    }
  }
`;
export { staticStyles };
