import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { MoneyCell, BaseTable, DefaultButton, Tooltip } from 'common/components';
import { currencyMap } from 'common/constants';
import { withTheme } from 'common/styling/theme';

import UserNoInfoPanel from '../../../_components/UserNoInfoPanel';

import { staticStyles, getDynamicStyles } from './style';

const WalletAccounts = ({
  data,
  captionId,
  imageNoAccount,
  userId,
  deleteFunc,
  restoreFunc,
  profile,
  noAccountsTextId,
  history,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [loadingButtons, setLoadingButtons] = useState({
    archive: false,
  });

  const handleArchive = (e, isArchived, id) => {
    e.stopPropagation();
    setLoadingButtons({ archive: true });
    if (isArchived) {
      restoreFunc(userId, id, true);
    } else {
      deleteFunc(userId, id, true);
    }
  };

  /* eslint-disable */

  const columns = [
    {
      key: 'justName',
      Cell: ({ original: { accountTypeTitle, currency } }) => (
        <div className="WalletAccounts__account">
          <div className="WalletAccounts__account__currency">{currencyMap[currency]}</div>
          <div className="WalletAccounts__account__title">{accountTypeTitle}</div>
          <FormattedMessage id="justMulticurrencyWallet">
            {txt => <span className="WalletAccounts__account__caption">{txt}</span>}
          </FormattedMessage>
        </div>
      ),
    },
    {
      key: 'justBalance',
      Cell: ({ original: { balance, loading, loadingFailed } }) => (
        <MoneyCell inTable value={balance} loading={loading} loadingFailed={loadingFailed} />
      ),
    },
  ];

  {
    profile.perms.actionUser &&
      columns.push({
        key: 'justActions',
        Cell: ({ original: { id, balance, isArchived, actionIsLoading, loading, loadingFailed, platformSlug } }) => {
          return !['cfh'].includes(platformSlug) ? (
            <Tooltip id={`boBlockedAccountAction_${id}`} textId={balance ? '' : 'accountBroken'}>
              <div className="WalletAccounts__buttons-inner">
                <DefaultButton
                  textId={isArchived ? 'justRestore' : 'justArchive'}
                  butColor="error"
                  disabled={actionIsLoading || loading || (isArchived ? !isArchived : loadingFailed)}
                  loading={actionIsLoading && loadingButtons.archive}
                  onClick={e => handleArchive(e, isArchived, id)}
                />
              </div>
            </Tooltip>
          ) : null;
        },
      });
  }
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => history.push(`/backoffice/users/${userId}/accounts/${rowInfo.original.id}`),
  });

  return (
    <div
      className={classNames('WalletAccounts', {
        'WalletAccounts--noAccounts': !data.length,
      })}>
      <div className="WalletAccounts__head">
        <FormattedMessage id={captionId}>{txt => <h2>{txt}</h2>}</FormattedMessage>
      </div>
      {data.length ? (
        <div className="WalletAccounts__body">
          <BaseTable columns={columns} data={data} getTrProps={rowProps} />
        </div>
      ) : (
        <UserNoInfoPanel image={imageNoAccount} textId={noAccountsTextId} />
      )}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

WalletAccounts.propTypes = {
  noAccountsTextId: PropTypes.string.isRequired,
  captionId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  imageNoAccount: PropTypes.string,
  userId: PropTypes.string,
  deleteFunc: PropTypes.func,
  restoreFunc: PropTypes.func,
  profile: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  theme: PropTypes.object,
};

WalletAccounts.defaultProps = {
  userId: '',
  deleteFunc: null,
  restoreFunc: null,
  imageNoAccount: '',
  profile: {},
  theme: {},
};

export default compose(withRouter, withTheme())(WalletAccounts);
export { WalletAccounts };
