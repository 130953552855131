import css from 'styled-jsx/css';
import { isRTL, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: rem(30px 0 10px);

    @include respond-to(sm) {
      align-items: flex-start;
    }

    &__select-wrap {
      position: relative;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(10px);
        top: 40%;
        right: ${isRTL ? 'auto' : '15%'};
        left: ${isRTL ? '15%' : 'auto'};
        transform: rotate(-45deg);
      }
    }

    &__select {
      width: 60px;
      padding: rem(10px);
      cursor: pointer;
    }

    &__pagination-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &__nav-info {
      display: flex;
      align-items: center;

      :global(.ActionsButton) {
        width: rem(10px);
        height: rem(10px);
        transform: rotate(45deg);

        &:first-child {
          margin-right: rem(5px);
        }

        &:nth-child(2) {
          margin-right: rem(30px);
        }
      }
      @include respond-to(sm) {
        margin-bottom: rem(20px);
      }
    }

    &__nav-input {
      text-align: center;
      padding: rem(10px 0);
      margin: rem(0 10px);
    }

    :global(.DefaultButton) {
      margin-left: rem(${isRTL ? '0' : '15px'});
      margin-right: rem(${isRTL ? '15px' : '0'});
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Pagination {
      &__select-wrap {
        border: 1px solid ${colors.lightGray.hex};

        &:after {
          border: solid ${colors.gray.hex};
          border-width: 0 0 1px 1px;
        }
      }

      &__select {
        background-color: transparent;
        border: none;
      }

      &__pagination-nav {
        :global(.ActionsButton) {
          border: solid ${rgba([colors.primary.rgb, 0.7])};
          &:first-child {
            &:disabled {
              border: solid ${colors.gray.hex};
              border-width: 0 0 1px 1px;
            }
            border-width: 0 0 1px 1px;
          }

          &:nth-child(2) {
            &:disabled {
              border: solid ${colors.gray.hex};
              border-width: 1px 1px 0 0;
            }
            border-width: 1px 1px 0 0;
          }
        }
      }
    }
  `;
};
export { staticStyles, getDynamicStyles };
