import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MoneyCell } from 'common/components';

import { InfoBlock } from '../_components';

import { staticStyles } from './style';

const AccountsInfoBlock = () => {
  const accounts = useSelector(state => state.crm.users.accounts?.accounts || []);
  const wallet = useSelector(state => state.crm.users.accounts?.wallet);
  const balance = useSelector(state => state.crm.users.userData?.balance);
  const items = useMemo(() => {
    const formattedAccounts = accounts?.map(({ accountTypeTitle, platformSlug, login, balance }) => ({
      titleId: ` ${login} ${accountTypeTitle} ${platformSlug}`,
      cell: balance ? <MoneyCell value={balance} heightMaxContent crmStyle /> : '----',
    }));
    return [
      ...(wallet
        ? [
            {
              titleId: 'boWallet',
              cell: <MoneyCell value={wallet} heightMaxContent crmStyle />,
            },
          ]
        : []),
      ...formattedAccounts,
    ];
  }, [accounts, wallet]);

  if (!balance) {
    return false;
  }

  return (
    <>
      <InfoBlock
        title="crmAccountsSum"
        titleValues={{ sum: balance.value, currency: balance.currency }}
        items={items}
      />
      <style jsx global>
        {staticStyles}
      </style>
    </>
  );
};

export default AccountsInfoBlock;
