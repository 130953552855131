import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from '../../../styling/theme';
import { staticStyles } from './style';
import fileIcon from '../../../images/pdf.svg';

const LightBoxPdf = ({ className, openLightBox, titleId }) => (
  <div
    role="presentation"
    className={classNames('LightBoxPdf', className)}
    onClick={openLightBox}
    onKeyPress={openLightBox}>
    <div className="LightBoxPdf__inner">
      <img className="FilePdf" src={fileIcon} alt={titleId} />
      <span>{titleId}</span>
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

LightBoxPdf.propTypes = {
  className: PropTypes.string.isRequired,
  openLightBox: PropTypes.func.isRequired,
  titleId: PropTypes.string.isRequired,
};

export default withTheme()(LightBoxPdf);
export { LightBoxPdf };
