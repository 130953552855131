import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { ChallengeItem } from 'client/accounts/_components';
import { getChallenges, getUserChallenges } from 'client/challenges/_redux';

import { DefaultButton, BasicModal } from 'common/components';
import {
  StartChallengeForm,
  StartChallengeFormHERO10X,
} from 'client/challenges/_components/SelectChallengeBlock/_components';
import { ModalHOC } from 'common';
import NoChallengesPanel from '../NoChallengesPanel';

import { staticStyles } from './style';
// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengesBlock = ({
  challenges,
  getChallenges,
  userChallenges,
  userChallengesAreLoaded,
  showModal,
  getUserChallenges,
  isAccountsPage,
  history,
}) => {
  const [isStartNewChallengeFormOpen, setIsStartNewChallengeOpen] = useState(false);

  const toogleModalFunc = () => {
    setIsStartNewChallengeOpen(prev => !prev);
  };
  useEffect(() => {
    if (isAccountsPage) {
      getChallenges();
      getUserChallenges();
    }
  }, [getChallenges, getUserChallenges]);
  return (
    <div className="ChallengesBlock">
      <div className="ChallengesBlock__head">
        <FormattedMessage id={isHero ? 'justActiveChallengesHERO10X' : 'justActiveChallenges'}>
          {txt => <h3 className="challenge-text">{txt}</h3>}
        </FormattedMessage>

        <div className="ChallengesBlock__head-right-buttons">
          {isAccountsPage && (
            <DefaultButton
              className="challenge-button"
              textId={isHero ? 'justAboutChallengesHERO10X' : 'justAboutChallenges'}
              onClick={() => {
                showModal({
                  modalClassName: 'challenge-modal',
                  caption: { id: isHero ? 'justAboutChallengesHERO10X' : 'justAboutChallenges' },

                  content: {
                    id: isHero ? 'justAboutChallengesTogetherHERO10X' : 'justAboutChallengesTogether',
                  },
                  actionButton: !isHero
                    ? {
                        className: 'challenge-button',
                        textId: 'justGoToChallengePage',
                        handler: () => {
                          history.push('/challenges/');
                        },
                      }
                    : null,
                  cancelButton: {
                    textId: 'justClose',
                  },
                });
              }}
            />
          )}
          {isAccountsPage && (
            <DefaultButton
              textId={isHero ? 'justChallengeHERO10X' : 'justChallenge'}
              plus
              filled
              onClick={toogleModalFunc}
            />
          )}
        </div>
      </div>
      <div className="ChallengesBlock__body">
        {!userChallenges.length && (
          <NoChallengesPanel textId={isHero ? 'justNoActiveChallengesPangelHERO10X' : 'justNoActiveChallengesPangel'} />
        )}
      </div>
      {userChallengesAreLoaded &&
        userChallenges
          .filter(({ status }) => status === 'active')
          .map(activeChallenge => (
            <ChallengeItem key={`${activeChallenge.challenge.name} block`} activeChallenge={activeChallenge} />
          ))}
      <BasicModal
        captionId="justNewChallenge"
        isOpen={isStartNewChallengeFormOpen && !isHero}
        onRequestClose={() => toogleModalFunc()}>
        <StartChallengeForm
          challenges={challenges}
          toggleModal={() => toogleModalFunc()}
          initialValues={{ type: null, budget: null }}
        />
      </BasicModal>

      <BasicModal
        captionId="justNewChallengeHERO10X"
        isOpen={isStartNewChallengeFormOpen && isHero}
        onRequestClose={() => toogleModalFunc()}>
        <StartChallengeFormHERO10X
          challenges={challenges}
          toggleModal={() => toogleModalFunc()}
          initialValues={{ type: null, budget: null }}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

ChallengesBlock.propTypes = {
  challenges: PropTypes.array.isRequired,
  userChallenges: PropTypes.array.isRequired,
  userChallengesAreLoaded: PropTypes.bool.isRequired,
  getChallenges: PropTypes.func.isRequired,
  getUserChallenges: PropTypes.func.isRequired,
};

export default compose(
  ModalHOC,
  withRouter,
  withTheme(),
  connect(
    state => ({
      challenges: state.client.challenges.challenges,
      challengesAreLoaded: state.client.challenges.challengesAreLoaded,
      userChallenges: state.client.challenges.userChallenges,
      userChallengesAreLoaded: state.client.challenges.userChallengesAreLoaded,
    }),
    {
      getChallenges: () => getChallenges.request(),
      getUserChallenges: () => getUserChallenges.request(),
    }
  )
)(ChallengesBlock);
