import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required, email } from 'redux-form-validators';
import classNames from 'classnames';
import { isValidNumber } from 'libphonenumber-js';
import { FormattedMessage } from 'react-intl';

import { BasicModal, DefaultButton, ReduxTextField, PhoneField, DateField, ReduxSelectField } from 'common/components';
import { getCountries } from 'client/KYC/_redux';

import { editUserInfo } from '../../_redux';

import { staticStyles } from './style';

const validatePhoneNumber = phoneNumber => {
  if (!isValidNumber(phoneNumber)) {
    return <FormattedMessage id="validationErrorWrongPhone" />;
  }
};

const UserEditInfoModal = ({
  userId,
  captionId,
  isOpen,
  onRequestClose,
  fieldName,
  fieldTitle,
  fieldType,
  getCountries,
  countriesAreLoaded,
  countries,
  allowNoValue,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const isCountry = fieldName === 'tax_residence' || fieldName === 'nationality';

  useEffect(() => {
    if (isCountry) {
      getCountries();
    }
  }, [getCountries, isCountry]);

  let countriesOptions;
  if (countriesAreLoaded) {
    countriesOptions = countries.map(option => ({
      value: option.gid,
      label: option.name,
    }));
  }

  const submit = handleSubmit(values => editUserInfo({ ...values, userId }, dispatch));

  const textFieldsType = fieldType === 'email' || fieldType === 'text';
  const emailValidation = fieldType === 'email' && [required(), email()];

  return (
    <div className="UserEditInfo">
      <BasicModal captionId={captionId} isOpen={isOpen} onRequestClose={onRequestClose}>
        <form
          className={classNames('UserEditInfoForm', {
            UserEditInfoForm__medium__tel: fieldType === 'tel',
            UserEditInfoForm__medium: isCountry,
            UserEditInfoForm__big: fieldType === 'date',
          })}
          onSubmit={submit}>
          <div className="UserEditInfoForm__inputs-inner">
            {textFieldsType && (
              <Field
                component={ReduxTextField}
                name={fieldName}
                textId={fieldTitle}
                autoComplete="off"
                type={fieldType}
                validate={allowNoValue ? [] : emailValidation || [required()]}
                disabled={submitting}
              />
            )}

            {fieldType === 'tel' && (
              <Field
                component={PhoneField}
                name="phone"
                textId="justPhone"
                validate={[required(), validatePhoneNumber]}
                disabled={submitting}
              />
            )}

            {fieldType === 'number' && (
              <Field
                component={ReduxTextField}
                name={fieldName}
                textId={fieldTitle}
                autoComplete="off"
                disabled={submitting}
                type="number"
              />
            )}

            {fieldType === 'date' && (
              <Field
                component={DateField}
                type={fieldType}
                name={fieldName}
                textId={fieldTitle}
                placeholder="dd.mm.yyyy"
                disabled={submitting}
                validate={[required()]}
                autoFocus
              />
            )}

            {isCountry && (
              <Field
                component={ReduxSelectField}
                name={fieldName}
                textId={fieldTitle}
                options={countriesOptions}
                disabled={submitting}
                validate={[required()]}
                maxMenuHeight={170}
                searchable
              />
            )}
          </div>
          <DefaultButton
            textId="justChange"
            type="submit"
            loading={submitting}
            disabled={invalid || pristine || submitting}
            filled
          />
          {error && <strong className="formError">{error}</strong>}
        </form>
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserEditInfoModal.propTypes = {
  userId: PropTypes.number.isRequired,
  captionId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  fieldType: PropTypes.string,
  getCountries: PropTypes.func.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UserEditInfoModal.defaultProps = {
  isOpen: false,
  fieldType: '',
  error: '',
};

export default compose(
  connect(
    (state, props) => ({
      countries: state.client.kyc.countries,
      countriesAreLoaded: state.client.kyc.countriesAreLoaded,
      form: props.form,
    }),
    { getCountries: () => getCountries.request() }
  ),
  reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    asyncBlurFields: [],
    onSubmitSuccess: (result, dispatch, props) => props.onRequestClose(),
  })
)(UserEditInfoModal);
export { UserEditInfoModal };
