import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .Auth__message {
    padding: 15px 20px;
    margin: 25px 0 0;
  }

  .RegisterPage {
    &__panel-tabs {
      margin-top: 16px;

      :global(.PanelTabs) {
        ul {
          display: grid;
          gap: 4px;
          grid-template-columns: repeat(2, 1fr);

          li {
            margin: 0;

            button {
              margin: 0;
              width: 100%;

              a {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Auth__message {
      background: ${rgba([colors.warning.rgb, 0.35])};
    }
  `;
};

export { staticStyles, getDynamicStyles };
