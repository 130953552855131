import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { closeLightBox, closeModal, openLightBox } from 'interface/user/_redux/actions';
import { EditIcon } from 'common/icons';
import { DefaultButton, StatusCell, FileField, LightBoxImage, BasicModal, ActionsButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noContentImage from 'common/images/noDocument.svg';
import imageDownload from 'common/images/documentDownload.png';

import { verifyDepositProof } from 'backoffice/payments/_redux';
import { UserNoInfoPanel } from 'backoffice/_components';
import { RejectDocumentForm } from 'backoffice/users/_components';

import { verifyUserDocument, rejectUserDocument, uploadUserDocument } from 'backoffice/users/_redux';

import { staticStyles, getDynamicStyles } from './style';

const UserDocument = ({
  doc,
  user,
  statusId,
  file,
  verifyDepositProof,
  verifyUserDocument,
  rejectUserDocument,
  name,
  depositId,
  fileNameId,
  uploadError,
  uploadUserDocument,
  documentVerifyIsLoading,
  documentRejectIsLoading,
  openLightBox,
  profile,
  modals,
  closeModal,
  isProof,
  isDeposit,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const IE = window.navigator && window.navigator.msSaveOrOpenBlob;

  const imageExtensions = {
    'image/png': '.png',
    'image/jpeg': '.jpeg',
  };

  const documentExtensions = {
    'application/pdf': '.pdf',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  };

  const extensions = { ...imageExtensions, ...documentExtensions };

  const document = {
    url: '',
    name: '',
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleRejectDocumentVerification = () => {
    toggleModal();
  };

  if (file) {
    document.url = URL.createObjectURL(file);
    document.name = `proof_deposit_${depositId}${extensions[file.type]}`;
  }

  const downloadDocument = () => {
    window.navigator.msSaveOrOpenBlob(file, document.name);
  };

  const DocumentsDownloadBlock = () => {
    if (IE) {
      return (
        <button type="button" onClick={() => downloadDocument()}>
          <img src={imageDownload} alt="Uptrader" />
        </button>
      );
    }
    return (
      <a href={document.url} download={document.name}>
        <img src={imageDownload} alt="Uptrader" />
      </a>
    );
  };

  const handleVerifyDocument = id => {
    if (isProof) {
      verifyDepositProof(depositId);
    } else {
      verifyUserDocument(id);
    }
  };

  return (
    <div className="UserDocument__inner">
      <div className="UserDocument__photo-inner">
        {file && statusId !== 'rejected' ? (
          statusId !== 'notUploaded' && (
            <div className="UserDocument__download-inner">
              {Object.keys(imageExtensions).includes(file.type) ? (
                <LightBoxImage
                  className="UserDocument__lightBoxImage"
                  key={1}
                  openLightBox={() => openLightBox(1, [document.url])}
                  image={document.url}
                  titleId={document.name}
                />
              ) : (
                <>
                  <DocumentsDownloadBlock />
                  <FormattedMessage id="downloadDocument" />
                </>
              )}
            </div>
          )
        ) : (
          <div className="UserDocument__upload-inner">
            {profile.perms.actionUser ? (
              <Field
                component={FileField}
                sendHandler={(_, file) => uploadUserDocument({ id: depositId, file, isDeposit })}
                name={name}
                formatNameId="documentsFormats"
                fileNameId={fileNameId}
                accept={Object.keys(extensions).join(',')}
                multiple={false}
              />
            ) : (
              <UserNoInfoPanel image={noContentImage} textId="boNoDocument" />
            )}
          </div>
        )}
      </div>

      {statusId === 'unverified' && file && profile.perms.actionUser && isDeposit && (
        <div className="UserDocument__buttons-inner">
          <DefaultButton
            onClick={() => handleVerifyDocument(user.id)}
            textId="boVerify"
            butColor="success"
            loading={documentVerifyIsLoading}
            disabled={documentVerifyIsLoading || documentRejectIsLoading}
          />
          <DefaultButton
            onClick={() => handleRejectDocumentVerification(user.id)}
            textId="boReject"
            butColor="error"
            loading={documentRejectIsLoading}
            disabled={documentVerifyIsLoading || documentRejectIsLoading}
          />
        </div>
      )}

      {statusId === 'verified' && (
        <div className="UserDocument__verified-inner">
          <div className="UserDocument__status">
            <StatusCell statusCode={statusId} />
          </div>
          <ActionsButton edit onClickFunc={() => handleRejectDocumentVerification(user.id)}>
            <EditIcon />
          </ActionsButton>
        </div>
      )}

      {uploadError && <span className="UserDocument__error">{uploadError}</span>}

      <BasicModal
        captionId="boRejectDocument"
        descriptionId="boRejectDocumentDescription"
        isOpen={modals[doc]}
        onRequestClose={() => closeModal(doc)}>
        {modals[doc] && (
          <DefaultButton
            onClick={() => rejectUserDocument(user.id)}
            textId="boReject"
            butColor="error"
            loading={documentRejectIsLoading}
            disabled={documentVerifyIsLoading || documentRejectIsLoading}
          />
        )}
      </BasicModal>

      <BasicModal isOpen={isOpen} captionId="createRejectNotification" onRequestClose={toggleModal}>
        <RejectDocumentForm paymentId={depositId} id={user.id} isProof={isProof} toggleModal={toggleModal} />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

UserDocument.propTypes = {
  doc: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  statusId: PropTypes.string.isRequired,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  verifyUserDocument: PropTypes.func.isRequired,
  rejectUserDocument: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fileNameId: PropTypes.string.isRequired,
  uploadError: PropTypes.string,
  uploadUserDocument: PropTypes.func.isRequired,
  documentVerifyIsLoading: PropTypes.bool.isRequired,
  documentRejectIsLoading: PropTypes.bool.isRequired,
  openLightBox: PropTypes.func,
  profile: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  verifyDepositProof: PropTypes.func,
  isProof: PropTypes.bool,
  isDeposit: PropTypes.bool,
  depositId: PropTypes.string,
  theme: PropTypes.object,
};

UserDocument.defaultProps = {
  file: null,
  uploadError: '',
  openLightBox: null,
  isProof: false,
  isDeposit: false,
  depositId: null,
  verifyDepositProof: null,
  theme: {},
};

const ThemedUserDocument = withTheme()(
  connect(
    state => ({
      profile: state.user.profile,
      documentVerifyIsLoading: state.backoffice.payments.documentVerifyIsLoading,
      documentRejectIsLoading: state.backoffice.payments.documentRejectIsLoading,
      lightBoxCurrentImage: state.user.lightBoxCurrentImage,
      lightBoxImages: state.user.lightBoxImages,
      modals: state.user.modals,
    }),
    {
      verifyUserDocument: id => verifyUserDocument.request(id),
      rejectUserDocument: id => rejectUserDocument.request(id),
      uploadUserDocument: data => uploadUserDocument.request(data),
      verifyDepositProof: id => verifyDepositProof.request(id),
      openLightBox: (id, lightBoxImages) => openLightBox(id, lightBoxImages),
      closeLightBox,
      closeModal: name => closeModal(name),
    }
  )(UserDocument)
);

export default ThemedUserDocument;
export { UserDocument };
