import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  challenges: [],
  challengesAreLoaded: false,

  userChallenges: [],
  userChallengesAreLoaded: false,

  challenge: {},
  challengeIsLoaded: false,

  challengesLeaderboardAreLoaded: false,
  challengesLeaderboard: {},

  manualWithdrawError: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MANUAL_WITHDRAW_ERROR: {
      return { ...state, manualWithdrawError: action.error };
    }

    case ActionTypes.MANUAL_WITHDRAW_ERROR_CLEAR: {
      return { ...state, manualWithdrawError: null };
    }

    case ActionTypes.GET_CHALLENGES[REQUEST]:
      return { ...state, challengesAreLoaded: false };
    case ActionTypes.GET_CHALLENGES[SUCCESS]:
      return {
        ...state,
        challengesAreLoaded: true,
        challenges: action.data,
      };
    case ActionTypes.GET_CHALLENGES[FAILURE]:
      return { ...state, challengesAreLoaded: false };

    case ActionTypes.GET_USER_CHALLENGES[REQUEST]:
      return { ...state, userChallengesAreLoaded: false };
    case ActionTypes.GET_USER_CHALLENGES[SUCCESS]:
      return {
        ...state,
        userChallengesAreLoaded: true,
        userChallenges: action.data,
      };
    case ActionTypes.GET_USER_CHALLENGES[FAILURE]:
      return { ...state, userChallengesAreLoaded: false };

    case ActionTypes.GET_CHALLENGE[REQUEST]:
      return { ...state, challengeIsLoaded: false };
    case ActionTypes.GET_CHALLENGE[SUCCESS]:
      return {
        ...state,
        challengeIsLoaded: true,
        challenge: action.data,
      };
    case ActionTypes.GET_CHALLENGE[FAILURE]:
      return { ...state, challengeIsLoaded: false };

    case ActionTypes.GET_CHALLENGES_LEADERBOARD[REQUEST]:
      return { ...state, challengesLeaderboardAreLoaded: false };
    case ActionTypes.GET_CHALLENGES_LEADERBOARD[SUCCESS]:
      return {
        ...state,
        challengesLeaderboardAreLoaded: true,
        challengesLeaderboard: action.data,
      };
    case ActionTypes.GET_CHALLENGES_LEADERBOARD[FAILURE]:
      return { ...state, challengesLeaderboardAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
