import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

import { DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { payCommissions } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const PayCommissionAllForm = ({
  filter,
  onlyFilter,
  handleSubmit,
  togglePayCommissionAllModal,
  submitting,
  invalid,
  error,
  theme,
  dispatch,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(values => payCommissions({ ...values, selectAll: true, filter, onlyFilter }, dispatch));

  return (
    <form className="PayCommissionAllForm" onSubmit={submit}>
      <div className="PayCommissionAllForm__line">
        <DefaultButton
          type="submit"
          textId="justPayRewards"
          loading={submitting}
          disabled={invalid || submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={togglePayCommissionAllModal} />
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

PayCommissionAllForm.propTypes = {
  filter: PropTypes.object.isRequired,
  togglePayCommissionAllModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

PayCommissionAllForm.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  reduxForm({
    form: 'PayCommissionForm',
    onSubmitSuccess(result, dispatch, props) {
      props.togglePayCommissionAllModal();
    },
  })
)(PayCommissionAllForm);
export { PayCommissionAllForm };
