import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CommissionsPageExports {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .CommissionsPageExports > .RadioButton {
    width: 700px;
  }

  .CommissionsPage__buttons {
    margin-top: 32px;

    :global(.DefaultButton) {
      margin-right: rem(32px);
      margin-bottom: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .CommissionsPage__export-select {
    display: flex;
    margin-bottom: 10px;

    :global(.BaseSelectField) {
      width: 220px;
      margin-bottom: 0;
    }

    :global(.BaseSelectField__label) {
      font-size: rem(15px);
      line-height: rem(22px);
      font-weight: 500;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }

    :global(.Select__control) {
      min-height: 100% !important;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .CommissionsPage__export-select {
      :global(.BaseSelectField) {
        :global(.Select__control) {
          border-color: ${colors.primary.hex} !important;
          &::after {
            border-color: ${colors.primary.hex};
          }
        }
      }

      :global(.BaseSelectField__label) {
        color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
