import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
