import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { DefaultButton, BasicModal } from 'common/components';

import { getDeposit, actionDeposit, getPaymentDepositStatuses } from '../../_redux';
import PaymentPage from '../PaymentPage';
import { RejectDepositForm, ChangePaymentStatusForm } from '../../_components';

const DepositPage = ({
  profile,
  deposit,
  depositStatuses,
  getPaymentDepositStatuses,
  actionDeposit,
  depositIsLoaded,
  depositActionsLoading,
  getDeposit,
  redirect,
  history,
  match,
}) => {
  const [loading, setLoading] = useState({
    fail: false,
    done: false,
    execute: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isChangeStatusOpen, setChangeStatusOpen] = useState(false);

  useEffect(() => {
    getDeposit(match.params.id);
  }, [getDeposit, match.params.id]);

  useEffect(() => {
    if (profile.isAdmin || profile.perms.superUserPerm) {
      getPaymentDepositStatuses();
    }
  }, [getPaymentDepositStatuses]);

  useEffect(() => {
    if (redirect) {
      window.location = '/backoffice/deposits';
    }
  }, [redirect]);

  const handleDepositButtonClick = type => {
    actionDeposit(match.params.id, type);
    setLoading({ ...loading, [type]: true });
  };

  const toggleChangeStatusForm = () => {
    setChangeStatusOpen(open => !open);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleMarkedFailed = () => {
    toggleModal();
  };

  const receivableStatuses = ['money_waiting', 'in_process', 'id_verification', 'canceled', 'payment_proof'];

  const Buttons = () =>
    profile.perms.executeDeposit ? (
      <>
        {receivableStatuses.includes(deposit.status) && (
          <DefaultButton
            onClick={() => handleDepositButtonClick('execute')}
            textId={deposit.status === 'id_verification' ? 'justSkipIdVerification' : 'boExecute'}
            butColor="success"
            disabled={depositActionsLoading}
            loading={loading.execute}
          />
        )}
        {receivableStatuses.includes(deposit.status) && (
          <DefaultButton
            onClick={() => handleMarkedFailed()}
            textId={deposit.status === 'id_verification' ? 'justRefund' : 'boMarkFail'}
            butColor="error"
            disabled={depositActionsLoading}
            loading={loading.fail}
          />
        )}
        {(profile.isAdmin || profile.perms.superUserPerm) && (
          <DefaultButton textId="boChangeStatus" onClick={toggleChangeStatusForm} />
        )}
      </>
    ) : null;

  return (
    <>
      <PaymentPage
        type="deposits"
        captionSlug="boDeposit"
        itemId={match.params.id}
        item={deposit}
        getItem={getDeposit}
        itemIsLoaded={depositIsLoaded}
        Buttons={Buttons}
        history={history}
        hasEditPerm={profile.perms.executeDeposit || profile.perms.editDeposit}
        hasEditTagsPerm={profile.perms.tagDeposit}
        idBackTo="boBackToDeposits"
      />
      <BasicModal isOpen={isOpen} captionId="createRejectNotification" onRequestClose={toggleModal}>
        <RejectDepositForm id={deposit.id} toggleModal={toggleModal} />
      </BasicModal>
      <BasicModal isOpen={isChangeStatusOpen} captionId="boChangeStatus" onRequestClose={toggleChangeStatusForm}>
        <ChangePaymentStatusForm
          statuses={depositStatuses}
          requestId={match.params.id}
          type="deposit"
          closeModal={toggleChangeStatusForm}
        />
      </BasicModal>
    </>
  );
};

DepositPage.propTypes = {
  profile: PropTypes.object.isRequired,
  getDeposit: PropTypes.func.isRequired,
  getPaymentDepositStatuses: PropTypes.func.isRequired,
  deposit: PropTypes.object.isRequired,
  depositIsLoaded: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  actionDeposit: PropTypes.func.isRequired,
  depositActionsLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  redirect: PropTypes.bool.isRequired,
  depositStatuses: PropTypes.array,
};

DepositPage.defaultProps = {
  depositStatuses: [],
};

export default compose(
  withRouter,
  connect(
    state => ({
      profile: state.user.profile,
      deposit: state.backoffice.payments.deposit,
      depositIsLoaded: state.backoffice.payments.depositIsLoaded,
      depositActionsLoading: state.backoffice.payments.depositActionsLoading,
      redirect: state.backoffice.common.redirect,
      depositStatuses: state.backoffice.payments.depositStatuses,
    }),
    {
      getDeposit: id => getDeposit.request(id),
      actionDeposit: (id, actionType) => actionDeposit.request(id, actionType),
      getPaymentDepositStatuses: () => getPaymentDepositStatuses.request(),
    }
  )
)(DepositPage);
export { DepositPage };
