import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import queryString from 'query-string';

import { DefaultButton, PanelTabs, Collapsible, BasicModal } from 'common/components';

import { getChallenges, getUserChallenges } from 'client/challenges/_redux';

import { staticStyles } from './style';

import { BudgetSelector, SelectChallengeBlockTable, StartChallengeForm } from './_components';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const SelectChallengeBlock = ({ challenges, location, history }) => {
  const [isStartNewChallengeFormOpen, setIsStartNewChallengeOpen] = useState(false);

  const toogleModalFunc = () => {
    setIsStartNewChallengeOpen(prev => !prev);
  };
  const firstElemRef = useRef(null);
  const activeTabSlugQuery = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : undefined),
    [location.search]
  );
  const [selectedChallengeId, setSelectedChallengeId] = useState(null);

  const [selectedGroupId, setSelectedGroupId] = useState(null);

  // FIXME: Возможен был белый экран, тк firstElemRef был почему-то null

  useEffect(() => {
    if (challenges[0]?.challenges?.length) {
      setSelectedGroupId(challenges[0].id);
      setSelectedChallengeId(challenges[0].challenges[0].id);
      if (typeof activeTabSlugQuery === 'undefined' && firstElemRef?.current) {
        firstElemRef.current.click();
      }
    }
  }, []);

  useEffect(() => {
    setSelectedGroupId(+activeTabSlugQuery);

    const selectedGroup = challenges.find(({ id }) => id === +activeTabSlugQuery);

    if (typeof selectedGroup?.challenges?.[0]?.id === 'number') {
      setSelectedChallengeId(selectedGroup?.challenges?.[0]?.id);
    }
  }, [activeTabSlugQuery]);

  const selectedChallengeData = useMemo(() => {
    if (typeof selectedChallengeId === 'number') {
      const selectedGroup = challenges.find(({ id }) => id === selectedGroupId);

      if (!selectedGroup) return null;

      return selectedGroup.challenges.find(({ id }) => id === selectedChallengeId);
    }
    return null;
  }, [selectedChallengeId, selectedGroupId, challenges]);

  const challengesBudgetSelectorValues = useMemo(() => {
    const selectedGroup = challenges.find(({ id }) => id === selectedGroupId);

    if (!selectedGroup || !selectedGroup.challenges.length) {
      return [];
    }

    return selectedGroup.challenges.map(challenge => ({
      initialAccountBalance: challenge.initialAccountBalance,
      id: challenge.id,
    }));
  }, [selectedChallengeId, selectedGroupId, challenges]);

  const onChallengeSelect = id => {
    setSelectedChallengeId(id);
  };
  return (
    <div className="SelectChallengeBlock">
      <div className="SelectChallengeBlock__head">
        <PanelTabs
          firstElemRef={firstElemRef}
          activeTabSlug={selectedGroupId}
          location={location}
          history={history}
          noTranslate>
          {challenges.map((group, ind) => (
            <div
              key={`${group.title}${ind}`}
              className="SelectChallengeBlock__Content"
              textId={group.title}
              slug={group.id}
              id={group.id}>
              {challengesBudgetSelectorValues && (
                <BudgetSelector
                  data={challengesBudgetSelectorValues}
                  onChange={onChallengeSelect}
                  selectedChallengeId={selectedChallengeId}
                />
              )}
              {selectedChallengeData && <SelectChallengeBlockTable data={selectedChallengeData} />}
            </div>
          ))}
        </PanelTabs>
      </div>

      <div className="StartNewChallengeButton">
        <DefaultButton className="challenge-button" plus filled textId="justChallege" onClick={toogleModalFunc} />
      </div>

      <Collapsible captionId="justAboutChallenges">
        <div className="CollapsibleAboutChallenges">
          {isHero ? (
            <FM id="justAboutChallengesTogetherHERO10X">{txt => <span className="challenge-text">{txt}</span>}</FM>
          ) : (
            <>
              <FM id="justAboutChallengesOne">{txt => <span className="challenge-text">{txt}</span>}</FM>
              <FM id="justAboutChallengesTwo">{txt => <span className="challenge-text">{txt}</span>}</FM>
            </>
          )}
        </div>
      </Collapsible>

      <BasicModal
        captionId={isHero ? 'justNewChallengeHERO10X' : 'justNewChallenge'}
        isOpen={isStartNewChallengeFormOpen}
        onRequestClose={() => toogleModalFunc()}>
        <StartChallengeForm
          challenges={challenges}
          toggleModal={() => toogleModalFunc()}
          initialValues={{ type: selectedGroupId, budget: selectedChallengeId }}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      challenges: state.client.challenges.challenges,
      userChallenges: state.client.challenges.userChallenges,
      userChallengesAreLoaded: state.client.challenges.userChallengesAreLoaded,
    }),
    {
      getChallenges: () => getChallenges.request(),
      getUserChallenges: () => getUserChallenges.request(),
    }
  )
)(SelectChallengeBlock);
export { SelectChallengeBlock };
