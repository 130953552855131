import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  BasicModal,
  NoItemsPanel,
  PageLayout,
  PageContentPreloader,
  DefaultButton,
  BaseSelectField,
  DateRangeField,
  RadioButton,
} from 'common/components';
import { withTheme } from 'common/styling/theme';
import noPartnersImage from 'common/images/noPartners.svg';
import { getCommissionsList, getRewardFrequencies, createPartnerCommissionsExport } from '../../_redux';
import {
  CommissionsFilterForm,
  CommissionsTable,
  EditCommissionForm,
  PayCommissionForm,
  RejectRewardsForm,
  PayCommissionAllForm,
  RejectRewardsAllForm,
} from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const yearsOptions = [...Array(10).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const CommissionsPage = ({
  filter,
  commissionsList,
  commissionsListIsLoaded,
  rewardFrequencies,
  rewardFrequenciesAreLoaded,
  getRewardFrequencies,
  getCommissionsList,
  createPartnerCommissionsExport,
  isPartnerCommissionsExportRedirect,
  theme,
  history,
  location,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const query = { page: 1, page_size: 10, ...queryString.parse(location.search) };
  const [isEditCommissionModalOpen, setEditCommissionModalOpen] = useState(false);
  const [isPayCommissionModalOpen, setPayCommissionModalOpen] = useState(false);
  const [isRejectRewardsModalOpen, setRejectRewardsModalOpen] = useState(false);
  const [rewardId, setRewardId] = useState(0);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [isSelectAllRecords, setIsSelectAllRecords] = useState(false);

  const [isPayCommissionAllModalOpen, setPayCommissionAllModalOpen] = useState(false);
  const [isRejectRewardsAllModalOpen, setRejectRewardsAllModalOpen] = useState(false);

  const handleClickOnHeaderCheckbox = newValue => {
    if (newValue) {
      setIsSelectAllRecords(true);
      setSelectedRecords(
        commissionsList?.results
          ?.filter(({ status }) => status === 'READY_FOR_PAY')
          .map(commission => ({
            id: commission.id,
            availableAmount: commission.availableAmount,
          }))
      );
    } else {
      setIsSelectAllRecords(false);
      setSelectedRecords([]);
    }
  };

  const handleClickOnCheckbox = (newValue, item) => {
    if (newValue) {
      const countOfSelectedReadyForPayOnThisPage = commissionsList.results.filter(
        ({ status }) => status === 'READY_FOR_PAY'
      ).length;
      const willBeAllSelected = selectedRecords.length + 1 === countOfSelectedReadyForPayOnThisPage;
      if (willBeAllSelected) {
        handleClickOnHeaderCheckbox(true);
      } else {
        setIsSelectAllRecords(false);
        setSelectedRecords(prev => [...prev, item]);
      }
    } else {
      setIsSelectAllRecords(false);
      setSelectedRecords(prev => prev.filter(({ id }) => id !== item.id));
    }
  };

  useEffect(() => {
    getRewardFrequencies();
  }, [getRewardFrequencies]);

  useEffect(() => {
    if (isPartnerCommissionsExportRedirect) history.push('/backoffice/partners/commissions/exports');
  }, [isPartnerCommissionsExportRedirect, history]);

  const toggleEditCommissionModal = () => {
    setEditCommissionModalOpen(!isEditCommissionModalOpen);
  };

  const togglePayCommissionModal = () => {
    setPayCommissionModalOpen(!isPayCommissionModalOpen);
  };

  const toggleRejectRewardsModal = () => {
    setRejectRewardsModalOpen(!isRejectRewardsModalOpen);
  };

  const togglePayCommissionAllModal = () => {
    setPayCommissionAllModalOpen(!isPayCommissionAllModalOpen);
  };

  const toggleRejectRewardsAllModal = () => {
    setRejectRewardsAllModalOpen(!isRejectRewardsAllModalOpen);
  };

  const onEditRecord = id => {
    setRewardId(id);
    toggleEditCommissionModal();
  };

  const [exportExt, setExportExt] = useState(null);
  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState();

  const [isCreateFullExport, setIsCreateFullExport] = useState(true);

  useEffect(() => {
    if (isExportMenuModalOpen) {
      setSelectedTimePeriod(
        `${moment(moment().add(-7, 'day').startOf('day')).format('YYYY-MM-DD')}_${moment(
          moment().startOf('day')
        ).format('YYYY-MM-DD')}`
      );
    }
  }, [isExportMenuModalOpen]);

  return (
    <PageLayout captionSlug="boCommissions">
      <PageContentPreloader ready={rewardFrequenciesAreLoaded} type="bigLine">
        <CommissionsFilterForm search={query} rewardFrequencies={rewardFrequencies} />
        <div className="CommissionsPage__export-select">
          <BaseSelectField
            name="import"
            textId="boCommissionsExport"
            options={[
              { value: 'showExports', label: 'Show exports' },
              { value: 'xlsx', label: 'Export in xlsx' },
              { value: 'csv', label: 'Export in csv' },
            ]}
            onChange={option => {
              if (option.value === 'showExports') {
                history.push('/backoffice/partners/commissions/exports');
              } else {
                setExportExt(option.value);
                setIsExportMenuModalOpen(true);
              }
            }}
            maxMenuHeight={170}
          />
        </div>
        {!!commissionsList.count && (
          <>
            <CommissionsTable
              data={commissionsList}
              loading={!commissionsListIsLoaded}
              location={location}
              history={history}
              getList={query => getCommissionsList({ ...filter, ...query.search })}
              params={{ search: query }}
              onRowClick={() => {}}
              onEditRecord={onEditRecord}
              selectedRecords={selectedRecords}
              isSelectAllRecords={isSelectAllRecords}
              handleClickOnHeaderCheckbox={handleClickOnHeaderCheckbox}
              handleClickOnCheckbox={handleClickOnCheckbox}
            />
            <div className="CommissionsPage__buttons">
              <DefaultButton
                onClick={togglePayCommissionModal}
                textId="justReleaseRewards"
                disabled={!selectedRecords.length}
                filled
              />
              <DefaultButton
                onClick={toggleRejectRewardsModal}
                textId="justRejectRewards"
                disabled={!selectedRecords.length}
              />
              <DefaultButton
                onClick={togglePayCommissionAllModal}
                textId="justReleaseAllRewards"
                disabled={!commissionsList?.results?.length}
                filled
              />
              <DefaultButton
                onClick={toggleRejectRewardsAllModal}
                textId="justRejectAllRewards"
                disabled={!commissionsList?.results?.length}
              />
            </div>
          </>
        )}
        {Object.entries(filter).length !== 0 && commissionsList.count === 0 && (
          <NoItemsPanel
            icon={noPartnersImage}
            captionSlug={Object.keys(query).length ? 'noRewardsMatchingTheParameters' : 'noRewardsYet'}
          />
        )}
      </PageContentPreloader>
      <BasicModal
        isOpen={isEditCommissionModalOpen}
        captionId="justCommissionInfo"
        onRequestClose={toggleEditCommissionModal}>
        <EditCommissionForm
          commission={commissionsList.results && commissionsList.results.find(item => item.id === rewardId)}
          toggleEditCommissionModal={toggleEditCommissionModal}
          getList={() => getCommissionsList({ ...filter, ...query })}
        />
      </BasicModal>
      <BasicModal
        isOpen={isPayCommissionModalOpen}
        captionId="justPayRewards"
        onRequestClose={togglePayCommissionModal}>
        <PayCommissionForm
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          filter={{ ...filter, ...query }}
          togglePayCommissionModal={togglePayCommissionModal}
        />
      </BasicModal>
      <BasicModal
        isOpen={isRejectRewardsModalOpen}
        captionId="justRejectRewards"
        onRequestClose={toggleRejectRewardsModal}>
        <RejectRewardsForm
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          filter={{ ...filter, ...query }}
          toggleRejectRewardsModal={toggleRejectRewardsModal}
        />
      </BasicModal>

      <BasicModal
        isOpen={isPayCommissionAllModalOpen}
        captionId="justPayAllRewards"
        onRequestClose={togglePayCommissionAllModal}>
        <PayCommissionAllForm
          filter={{ ...filter, ...query }}
          onlyFilter={filter}
          togglePayCommissionAllModal={togglePayCommissionAllModal}
        />
      </BasicModal>
      <BasicModal
        isOpen={isRejectRewardsAllModalOpen}
        captionId="justRejecAllRewards"
        onRequestClose={toggleRejectRewardsAllModal}>
        <RejectRewardsAllForm
          filter={{ ...filter, ...query }}
          onlyFilter={filter}
          toggleRejectRewardsAllModal={toggleRejectRewardsAllModal}
        />
      </BasicModal>

      <BasicModal
        captionId="selectTimePeriodOfExport"
        isOpen={isExportMenuModalOpen}
        onRequestClose={() => setIsExportMenuModalOpen(false)}>
        <div className="CommissionsPageExports">
          <RadioButton
            name="investFrom"
            textId="createExportForAllTime"
            value="all"
            onChange={() => setIsCreateFullExport(true)}
            checked={isCreateFullExport}
          />
          <RadioButton
            name="investFrom"
            textId="createExportForTimePeriod"
            value="all"
            onChange={() => setIsCreateFullExport(false)}
            checked={!isCreateFullExport}
          />
          <div style={{ width: 'max-content', display: !isCreateFullExport ? 'flex' : 'none' }}>
            <DateRangeField
              yearsOptions={yearsOptions}
              input={{ value: selectedTimePeriod, onChange: setSelectedTimePeriod }}
              meta={{ touched: true, error: false }}
              noRedux
              relativeInner
              daysLimit={30}
            />
          </div>
          <DefaultButton
            textId="justExport"
            type="button"
            filled
            disabled={!selectedTimePeriod}
            onClick={() => {
              setIsExportMenuModalOpen(false);
              createPartnerCommissionsExport({
                ext: exportExt,
                timePeriod: isCreateFullExport ? undefined : selectedTimePeriod,
              });
            }}
          />
        </div>
      </BasicModal>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

CommissionsPage.propTypes = {
  commissionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  commissionsListIsLoaded: PropTypes.bool.isRequired,
  isPartnerCommissionsExportRedirect: PropTypes.bool.isRequired,
  rewardFrequencies: PropTypes.array.isRequired,
  rewardFrequenciesAreLoaded: PropTypes.bool.isRequired,
  getCommissionsList: PropTypes.func.isRequired,
  getRewardFrequencies: PropTypes.func.isRequired,
  createPartnerCommissionsExport: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  filter: PropTypes.object,
  theme: PropTypes.object,
};

CommissionsPage.defaultProps = {
  filter: {},
  theme: {},
};

export default compose(
  withRouter,
  injectIntl,
  withTheme(),
  connect(
    state => ({
      filter: state.form.CommissionsFilterForm && state.form.CommissionsFilterForm.values,
      commissionsList: state.backoffice.partnership.commissionsList,
      commissionsListIsLoaded: state.backoffice.partnership.commissionsListIsLoaded,
      rewardFrequencies: state.backoffice.partnership.rewardFrequencies,
      rewardFrequenciesAreLoaded: state.backoffice.partnership.rewardFrequenciesAreLoaded,
      isPartnerCommissionsExportRedirect: state.backoffice.partnership.isPartnerCommissionsExportRedirect,
    }),
    {
      getCommissionsList: data => getCommissionsList.request(data),
      getRewardFrequencies: () => getRewardFrequencies.request(),
      createPartnerCommissionsExport: ({ ext, timePeriod }) =>
        createPartnerCommissionsExport.request({ ext, timePeriod }),
    }
  )
)(CommissionsPage);
export { CommissionsPage };
