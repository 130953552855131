import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import Chart from 'react-apexcharts';

import { PageLayout, PageContentPreloader, DefaultButton, NoItemsPanel } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noPartnersImage from 'common/images/noPartners.svg';
import noLinks from 'common/images/noInfo.svg';

import { getPartnershipInfo } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

class PartnershipInfoLinksPage extends React.Component {
  clicksByMonths = [];

  constructor(props) {
    super(props);
    props.getPartnershipInfo();

    this.state = {
      copied: false,
      chartShow: { partnerCode: -1, linkIndex: -1 },
    };
  }

  handleCopyClick = () => {
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  handlePartnerLinkClicks = (partnerCode, partnerIndex, linkIndex) => {
    this.setState({ chartShow: { partnerCode, linkIndex } });
    this.clicksByMonths = [];

    const monthsNames = moment.months();
    monthsNames.forEach(month => this.clicksByMonths.push({ title: month, count: 0 }));

    const yearAgo = moment().subtract(1, 'year');
    const filteredClicks = this.props.partnershipLinks[partnerIndex].links[linkIndex].clicks.filter(
      click => moment(click.created) > yearAgo
    );

    filteredClicks.forEach(click => {
      const monthId = moment(click.created).month();
      this.clicksByMonths[monthId].count += 1;
    });

    if (JSON.stringify(this.state.chartShow) === JSON.stringify({ partnerCode, linkIndex })) {
      this.setState({ chartShow: { partnerCode: -1, linkIndex: -1 } });
    }
  };

  render() {
    const { partnershipLinks, partnershipInfoIsLoaded, intl, theme, partnershipConfig } = this.props;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    const options = {
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
        },
      },
      stroke: {
        curve: 'straight',
      },
      colors: ['#0AA1E2'],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.clicksByMonths.map(m => m.title),
      },
      legend: {
        horizontalAlign: 'left',
      },
    };

    const series = [
      {
        name: intl.formatMessage({ id: 'partnershipClicks' }),
        data: this.clicksByMonths.map(m => m.count),
      },
    ];

    const { defaultPartnerCodeParamName, partnerCodeParamNames } = partnershipConfig;

    const additionalParamNamesLinks = partnerCodeParamNames.filter(param => param !== defaultPartnerCodeParamName);

    return (
      <PageLayout captionSlug="partnershipLinks">
        <PageContentPreloader ready={partnershipInfoIsLoaded} type="line">
          <>
            {partnershipLinks.length ? (
              <div className="PartnershipInfoLinksPage">
                {partnershipLinks.map((partner, index) => (
                  <div key={index} className="PartnershipInfoLinks__item">
                    <FormattedMessage id="referralAccountLink">
                      {txt => (
                        <p className="PartnershipInfoLinks__partner-code">
                          {txt}: <span>{partner.partnerCode}</span>
                        </p>
                      )}
                    </FormattedMessage>

                    {partner.links.length ? (
                      <>
                        <div className="PartnershipInfoLinks__default-parameter">
                          <FormattedMessage id="settingPartnershipDefaultPartnerCodeParamNameTitle">
                            {txt => <h3 className="PartnershipInfoLinks__title">{txt}</h3>}
                          </FormattedMessage>
                          {partner.links.map((link, i) => (
                            <div className="PartnershipInfoLinks__item-links" key={i}>
                              <div className="Link__inner">
                                <p className="Link__text">{`${link.link}?${defaultPartnerCodeParamName}=${partner.partnerCode}`}</p>
                                <FormattedMessage id="justCopy">
                                  {txt => (
                                    <CopyToClipboard
                                      text={`${link.link}?${defaultPartnerCodeParamName}=${partner.partnerCode}`}
                                      onCopy={this.handleCopyClick}>
                                      <button type="button" className="Copy-button__url">
                                        {txt}
                                      </button>
                                    </CopyToClipboard>
                                  )}
                                </FormattedMessage>
                              </div>

                              <DefaultButton
                                textId={
                                  JSON.stringify(this.state.chartShow) ===
                                  JSON.stringify({ partnerCode: partner.partnerCode, linkIndex: i })
                                    ? 'justHideStatistic'
                                    : 'justShowStatistic'
                                }
                                onClick={() => this.handlePartnerLinkClicks(partner.partnerCode, index, i)}
                                filled
                              />

                              <div className="PartnershipInfoLinks__chart-inner">
                                {JSON.stringify(this.state.chartShow) ===
                                  JSON.stringify({ partnerCode: partner.partnerCode, linkIndex: i }) && (
                                  <div className="PartnershipInfoLinks__chart">
                                    <FormattedMessage id="partnershipClicks">{txt => <h2>{txt}</h2>}</FormattedMessage>
                                    <Chart options={options} series={series} type="area" height="400" />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>

                        {additionalParamNamesLinks.length > 0 && (
                          <div>
                            <FormattedMessage id="settingPartnershipAdditionalPartnerCodeParamNamesTitle">
                              {txt => <h3 className="PartnershipInfoLinks__title">{txt}</h3>}
                            </FormattedMessage>
                            {partner.links.map((link, i) => (
                              <div key={i} className="PartnershipInfoLinks__item-additional-link-block">
                                {additionalParamNamesLinks.map((param, i) => (
                                  <div className="PartnershipInfoLinks__item-links" key={i}>
                                    <div className={classNames('Link__inner', 'Link__inner-additional')}>
                                      <p className="Link__text">{`${link.link}?${param}=${partner.partnerCode}`}</p>
                                      <FormattedMessage id="justCopy">
                                        {txt => (
                                          <CopyToClipboard
                                            text={`${link.link}?${param}=${partner.partnerCode}`}
                                            onCopy={this.handleCopyClick}>
                                            <button type="button" className="Copy-button__url">
                                              {txt}
                                            </button>
                                          </CopyToClipboard>
                                        )}
                                      </FormattedMessage>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="PartnershipNoInfoLinks">
                        <div className="PartnershipNoInfoLinks__image-inner">
                          <img src={noLinks} alt="No partnership links yet" />
                        </div>
                        <FormattedMessage id="noReferalLinkDescription">{txt => <p>{txt}</p>}</FormattedMessage>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <NoItemsPanel
                icon={noPartnersImage}
                captionSlug="noReferalLinkCaption"
                descriptionSlug="noReferalLinkDescription"
              />
            )}
          </>
        </PageContentPreloader>

        <div
          className={classNames('Copied__inner', {
            'Copied__inner-active': this.state.copied,
          })}>
          <FormattedMessage id="justCopied">{txt => <p>{txt}</p>}</FormattedMessage>
        </div>

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </PageLayout>
    );
  }
}

PartnershipInfoLinksPage.propTypes = {
  getPartnershipInfo: PropTypes.func.isRequired,
  partnershipLinks: PropTypes.array.isRequired,
  partnershipInfoIsLoaded: PropTypes.bool.isRequired,
  partnershipConfig: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PartnershipInfoLinksPage.defaultProps = {
  theme: {},
};

const ThemedPartnershipInfoLinksPage = withTheme()(injectIntl(PartnershipInfoLinksPage));

export default connect(
  state => ({
    partnershipInfoIsLoaded: state.client.partnership.partnershipInfoIsLoaded,
    partnershipLinks: state.client.partnership.partnershipLinks,
    partnershipConfig: state.interfaceConfig.partnership,
  }),
  {
    getPartnershipInfo: () => getPartnershipInfo.request(),
  }
)(ThemedPartnershipInfoLinksPage);
export { PartnershipInfoLinksPage };
