import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { SwitchField, RadioButton, DefaultButton, BasicModal } from 'common/components';

import { setTfaType, verifyTfaType, requestChangeTfaType, resetTfaType } from 'auth/twoStepAuth/_redux';
import { TwoStepEmailForm, TwoStepGoogleForm, TwoStepSuccess } from 'auth/twoStepAuth/_components';
import { resendOTPToken } from 'auth/login/_redux';

import ChangingAuthMethod from '../ChangingAuthMethod';

import { staticStyles, getDynamicStyles } from './style';

const TwoStepSecurityPanel = ({ theme, tfAuthSettings, isEnabled, isTfaEnabled, selectedType }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const dispatch = useDispatch();

  const [selectedMethod, setSelectedMethod] = useState(selectedType); // email | totp
  const [captionTextId, setCaptionTextId] = useState('');
  const [successTextId, setSuccessTextId] = useState('');
  const [modal, setModal] = useState({ type: '', opened: false });

  const handleModalToggle = type => {
    setModal(prev => ({ type, opened: !prev.opened }));
  };

  const handleSuccess = message => {
    setCaptionTextId(message);
    handleModalToggle('success');
  };

  const handleModalClose = () => {
    setModal(prev => ({ ...prev, opened: !prev.opened }));
  };

  const handleModalTypeToggle = () => {
    if (isTfaEnabled) {
      handleModalToggle('change');

      return;
    }

    if (selectedMethod === 'totp') {
      dispatch(setTfaType.request({ otpType: 'totp' }));
    }

    handleModalToggle(selectedMethod);
  };

  const handleTypeChange = method => {
    setSelectedMethod(method);
  };

  const handleTfaToggle = (_e, value) => {
    if (!value && !tfAuthSettings?.mandatory) {
      handleModalToggle(`reset_${selectedType}`);
    }
  };

  useEffect(() => {
    if (isTfaEnabled) {
      setSuccessTextId('authTwoStepChanged');

      return;
    }

    setSuccessTextId('authTwoStepEnabled');
  }, []);

  return (
    <div className="TwoStepSecurityPanelForm__wrapper">
      <form className="TwoStepSecurityPanelForm">
        <div className="TwoStepSecurityPanelForm__enable-block">
          <FormattedMessage id="authTwoStepAuthentication">
            {txt => <h2 className="TwoStepSecurityPanelForm__title">{txt}</h2>}
          </FormattedMessage>
          <div className="TwoStepSecurityPanelForm__enable-block__field">
            <Field
              disabled={isTfaEnabled && tfAuthSettings?.mandatory}
              component={SwitchField}
              onChange={handleTfaToggle}
              name="isEnabled"
              type="checkbox"
            />
            <FormattedMessage id="authEnableTwoFactorAuth" />
          </div>
        </div>
        <div className="TwoStepSecurityPanelForm__choose-block">
          <FormattedMessage id="authChooseAuthMethod">
            {txt => <h2 className="TwoStepSecurityPanelForm__title">{txt}</h2>}
          </FormattedMessage>
          <div className="TwoStepSecurityPanelForm__choose-block__options">
            {tfAuthSettings.modes?.includes('totp') && (
              <div className="TwoStepSecurityPanelForm__choose-block__option">
                <RadioButton
                  textId="authAuthenticatorApp"
                  checked={selectedMethod === 'totp'}
                  onChange={() => handleTypeChange('totp')}
                />
                <div className="TwoStepSecurityPanelForm__choose-block__option__caption">
                  <FormattedMessage
                    id="authGetCodeFromGoogle"
                    values={{
                      link: (
                        <a
                          className="TwoStepSecurityPanelForm__choose-block__link "
                          rel="noreferrer"
                          href="https://support.google.com/accounts/answer/1066447"
                          target="_blank">
                          <FormattedMessage id="authGoogleAuthenticator" />
                        </a>
                      ),
                    }}
                  />
                </div>
              </div>
            )}
            {tfAuthSettings.modes?.includes('email') && (
              <div className="TwoStepSecurityPanelForm__choose-block__option">
                <RadioButton
                  textId="authTwoStepEmail"
                  checked={selectedMethod === 'email'}
                  onChange={() => handleTypeChange('email')}
                />
                <div className="TwoStepSecurityPanelForm__choose-block__option__caption">
                  <FormattedMessage id="authRecieveEmailUniqueCode" />
                </div>
              </div>
            )}
          </div>
        </div>
        <DefaultButton
          textId={isTfaEnabled ? 'justChange' : 'justSubmit'}
          filled
          disabled={!isEnabled || !selectedMethod || selectedMethod === selectedType}
          onClick={handleModalTypeToggle}
        />
      </form>
      <BasicModal
        captionId="authChangingAuthMethod"
        isOpen={modal.type === 'change' && modal.opened}
        onRequestClose={handleModalClose}>
        <ChangingAuthMethod onSuccess={handleSuccess} selectedMethod={selectedMethod} onClose={handleModalClose} />
      </BasicModal>
      <BasicModal
        captionId="authTwoStepAuthentication"
        isOpen={modal.type === 'totp' && modal.opened}
        onRequestClose={handleModalClose}>
        <TwoStepGoogleForm isNewSetting onClose={handleModalClose} onSubmit={verifyTfaType} onSuccess={handleSuccess} />
      </BasicModal>
      <BasicModal
        key="email"
        captionId="authTwoStepAuthentication"
        isOpen={modal.type === 'email' && modal.opened}
        onRequestClose={handleModalClose}>
        <TwoStepEmailForm
          onResend={() => resendOTPToken.request()}
          onClose={handleModalClose}
          onGetCode={() => setTfaType.request({ otpType: 'email' })}
          onSubmit={verifyTfaType}
          onSuccess={handleSuccess}
        />
      </BasicModal>
      <BasicModal
        captionId="authTwoStepAuthentication"
        isOpen={modal.type === 'reset_totp' && modal.opened}
        onRequestClose={handleModalClose}>
        <TwoStepGoogleForm isReset onClose={handleModalClose} onSubmit={resetTfaType} />
      </BasicModal>
      <BasicModal
        key="email"
        captionId="authTwoStepAuthentication"
        isOpen={modal.type === 'reset_email' && modal.opened}
        onRequestClose={handleModalClose}>
        <TwoStepEmailForm
          isReset
          onResend={() => requestChangeTfaType.request()}
          onGetCode={() => requestChangeTfaType.request()}
          onClose={handleModalClose}
          onSubmit={resetTfaType}
        />
      </BasicModal>
      <BasicModal
        isOpen={modal.type === 'success' && modal.opened}
        captionId="authTwoStepAuthentication"
        onRequestClose={handleModalClose}>
        <TwoStepSuccess onClose={handleModalClose} successTextId={successTextId} captionTextId={captionTextId} />
      </BasicModal>
      <style jsx global>
        {dynamicStyles}
      </style>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

TwoStepSecurityPanel.propTypes = {
  isTfaEnabled: PropTypes.bool.isRequired,
  tfAuthSettings: PropTypes.object.isRequired,
  selectedType: PropTypes.string,
  theme: PropTypes.object,
  isEnabled: PropTypes.bool,
};

TwoStepSecurityPanel.defaultProps = {
  isEnabled: false,
  selectedType: null,
  theme: {},
};

const selector = formValueSelector('TwoStepSecurityPanelForm');

export default compose(
  withTheme(),
  connect(
    state => ({
      isEnabled: selector(state, 'isEnabled'),
      tfAuthSettings: state.interfaceConfig.tfAuthSettings,
    }),
    {}
  ),
  reduxForm({
    form: 'TwoStepSecurityPanelForm',
    // onSubmitSuccess: (result, dispatch, props) => {},
  })
)(TwoStepSecurityPanel);
