import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withTheme } from '../../../common/styling/theme';
import { DefaultButton } from '../../../common/components';

const replaceTemplates = (url, templates) =>
  url.replace(/{(.*?)}/g, (match, key) => (templates[key.trim()] !== undefined ? templates[key.trim()] : match));

const OpenTradeLinkPanel = ({
  templates,
  onTradingStarted,
  accountTradeLink,
  accountTradeLinkLoading,
  accountTradeLinkLoaded,
}) => (
  <div className="OpenTradeLinkPanel__wrapper">
    {accountTradeLinkLoading && (
      <FormattedMessage id="loadingTradeLinkCaption">
        {txt => <p className="OpenTradeLinkPanel__caption">{txt}</p>}
      </FormattedMessage>
    )}
    {accountTradeLinkLoaded && (
      <FormattedMessage id="loadedTradeLinkCaption">
        {txt => <p className="OpenTradeLinkPanel__caption">{txt}</p>}
      </FormattedMessage>
    )}
    <DefaultButton
      textId="clientStartTrade"
      loading={accountTradeLinkLoading}
      filled
      onClick={() => {
        onTradingStarted();
        window.open(replaceTemplates(accountTradeLink, templates), '_blank');
      }}
      disabled={accountTradeLinkLoading || !accountTradeLinkLoaded}
    />
  </div>
);

OpenTradeLinkPanel.propTypes = {
  templates: PropTypes.object,
  onTradingStarted: PropTypes.func.isRequired,
  accountTradeLink: PropTypes.string.isRequired,
  accountTradeLinkLoading: PropTypes.bool.isRequired,
  accountTradeLinkLoaded: PropTypes.bool.isRequired,
};

OpenTradeLinkPanel.defaultProps = {
  templates: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(state => ({
    accountTradeLink: state.client.accounts.accountTradeLink,
    accountTradeLinkLoading: state.client.accounts.accountTradeLinkLoading,
    accountTradeLinkLoaded: state.client.accounts.accountTradeLinkLoaded,
  }))
)(OpenTradeLinkPanel);
export { OpenTradeLinkPanel };
