import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  feed: [],
  feedIsLoaded: false,

  userExportData: {},
  userExportDataIsLoaded: false,

  contacts: {},
  contactsAreLoaded: false,

  userData: {},
  userDataIsLoaded: false,

  accounts: {},
  accountsAreLoaded: false,

  accountData: {},
  accountDataIsLoading: false,

  accountTrades: { trades: {}, summary: {} },
  accountTradesAreLoaded: false,

  favorites: [],
  favoritesAreLoaded: false,

  logs: {},
  logsAreLoaded: false,

  payments: {},
  paymentsAreLoaded: false,

  internalTransfers: {},
  internalTransfersAreLoaded: false,

  salesStatuses: [],
  salesStatusesAreLoaded: false,

  notes: [],
  notesDocuments: {},
  notesDocumentsAreLoaded: false,
  notesAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_GEONAME_CONTACT_BY_GID[SUCCESS]:
      return { ...state, userData: { ...state.userData, address: { ...state.userData.address, ...action.data } } };
    case ActionTypes.GET_GEONAME_CONTACT_BY_GID[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.GET_CRM_USER_DATA[REQUEST]:
      return { ...state, userDataIsLoaded: false };
    case ActionTypes.GET_CRM_USER_DATA[SUCCESS]:
      return { ...state, userData: action.data, userDataIsLoaded: true };
    case ActionTypes.GET_CRM_USER_DATA[FAILURE]:
      return { ...state, error: action.error, userDataIsLoaded: false };

    case ActionTypes.GET_CRM_USER_ACCOUNTS[REQUEST]:
      return { ...state, accountsAreLoaded: false };
    case ActionTypes.GET_CRM_USER_ACCOUNTS[SUCCESS]:
      action.data.accounts.forEach(acc => {
        acc.loading = true;
      });
      return { ...state, accounts: action.data, accountsAreLoaded: true };
    case ActionTypes.GET_CRM_USER_ACCOUNTS[FAILURE]:
      return { ...state, accountsAreLoaded: false, error: action.error };

    case ActionTypes.GET_CRM_USER_ACCOUNTS_PREFETCH[SUCCESS]:
      action.data.accounts.forEach(acc => {
        acc.loading = false;
      });
      return {
        ...state,
        accounts: action.data,
        accountsAreLoaded: true,
      };

    case ActionTypes.GET_CRM_USER_ACCOUNT_DATA[REQUEST]:
      return { ...state, accountDataIsLoading: false };
    case ActionTypes.GET_CRM_USER_ACCOUNT_DATA[SUCCESS]:
      return { ...state, accountData: action.data, accountDataIsLoading: true };
    case ActionTypes.GET_CRM_USER_ACCOUNT_DATA[FAILURE]:
      return { ...state, accountDataIsLoading: false, error: action.error };

    case ActionTypes.GET_CRM_ACCOUNT_TRADES[REQUEST]:
      return { ...state, accountTradesAreLoaded: false };
    case ActionTypes.GET_CRM_ACCOUNT_TRADES[SUCCESS]:
      return { ...state, accountTrades: action.data, accountTradesAreLoaded: true };
    case ActionTypes.GET_CRM_ACCOUNT_TRADES[FAILURE]:
      return { ...state, accountTradesAreLoaded: false, error: action.error };

    case ActionTypes.GET_CRM_USER_LOGS[REQUEST]:
      return { ...state, logsAreLoaded: false };
    case ActionTypes.GET_CRM_USER_LOGS[SUCCESS]:
      return { ...state, logs: action.data, logsAreLoaded: true };
    case ActionTypes.GET_CRM_USER_LOGS[FAILURE]:
      return { ...state, logsAreLoaded: false, error: action.error };

    case ActionTypes.GET_CRM_USER_FEED[REQUEST]:
      return { ...state, feedIsLoaded: false };
    case ActionTypes.GET_CRM_USER_FEED[SUCCESS]:
      return { ...state, feed: action.data, feedIsLoaded: true };
    case ActionTypes.GET_CRM_USER_FEED[FAILURE]:
      return { ...state, feedIsLoaded: false, error: action.error };

    case ActionTypes.GET_CRM_USER_PAYMENTS[REQUEST]:
      return { ...state, paymentsAreLoaded: false };
    case ActionTypes.GET_CRM_USER_PAYMENTS[SUCCESS]:
      return { ...state, payments: action.data, paymentsAreLoaded: true };
    case ActionTypes.GET_CRM_USER_PAYMENTS[FAILURE]:
      return { ...state, paymentsAreLoaded: false, error: action.error };

    case ActionTypes.GET_CRM_USER_INTERNAL_TRANSFERS[REQUEST]:
      return { ...state, internalTransfersAreLoaded: false };
    case ActionTypes.GET_CRM_USER_INTERNAL_TRANSFERS[SUCCESS]:
      return { ...state, internalTransfers: action.data, internalTransfersAreLoaded: true };
    case ActionTypes.GET_CRM_USER_INTERNAL_TRANSFERS[FAILURE]:
      return { ...state, internalTransfersAreLoaded: false, error: action.error };

    case ActionTypes.GET_SALES_STATUSES[REQUEST]:
      return { ...state, salesStatusesAreLoaded: false };
    case ActionTypes.GET_SALES_STATUSES[SUCCESS]:
      return { ...state, salesStatuses: action.data, salesStatusesAreLoaded: true };
    case ActionTypes.GET_SALES_STATUSES[FAILURE]:
      return { ...state, salesStatusesAreLoaded: false, error: action.error };

    // case ActionTypes.SET_CONTACT_FAVORITE_CONTACT_PAGE[SUCCESS]: {
    //   return { ...state, favorites: action.data };
    // }

    case ActionTypes.GET_CONTACT_NOTES[REQUEST]:
      return { ...state, notesAreLoaded: false };
    case ActionTypes.GET_CONTACT_NOTES[SUCCESS]:
      return { ...state, notes: action.data, notesAreLoaded: true };
    case ActionTypes.GET_CONTACT_NOTES[FAILURE]:
      return { ...state, error: action.error, notesAreLoaded: false };

    case ActionTypes.createContactDocument[REQUEST]:
      return {
        ...state,
        notesDocumentsAreLoaded: false,
      };
    case ActionTypes.createContactDocument[SUCCESS]:
      return { ...state, notesDocuments: action.payload, notesDocumentsAreLoaded: true };
    case ActionTypes.createContactDocument[FAILURE]:
      return { ...state, error: action.error, notesDocumentsAreLoaded: false };

    case ActionTypes.createContactNote[SUCCESS]:
      return { ...state, notesDocuments: {}, notesDocumentsAreLoaded: false };

    case ActionTypes.editContactNote[REQUEST]:
      return {
        ...state,
        notesDocumentsAreLoaded: false,
      };

    case ActionTypes.editContactNote[SUCCESS]:
      return { ...state, notesDocuments: undefined, notesDocumentsAreLoaded: true };
    case ActionTypes.editContactNote[FAILURE]:
      return { ...state, error: action.error, notesDocumentsAreLoaded: false };

    case ActionTypes.ADD_CONTACT_TAG[SUCCESS]:
      return { ...state, userData: action.data, userDataIsLoaded: true };

    case ActionTypes.DELETE_CONTACT_TAG[SUCCESS]:
      return { ...state, userData: action.data, userDataIsLoaded: true };

    default:
      return state;
  }
};

export default reducer;
