import css from 'styled-jsx/css';

const staticStyles = css.global`
  .DateSelector__reset-btn {
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 8px;
    padding: 6px 8px;
    border-radius: 4px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .DateSelector__reset-btn {
      border: 1px solid ${colors.primary.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
