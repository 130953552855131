import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserTagsPanelClient {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px);
    margin-top: 20px;
    margin-bottom: rem(25px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
  }

  .UserTagsPanelClient__head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(15px);
  }

  .UserTagsPanelClient__tag {
    font-size: rem(16px);
    display: inline-block;
    position: relative;
    padding: rem(8px 30px 10px 10px);
    margin-right: rem(10px);
    line-height: 1;
    button {
      position: absolute;
      cursor: pointer;
      width: rem(18px);
      height: rem(18px);
      right: rem(5px);
      top: 50%;
      transform: translateY(-50%);
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: rem(8px);
        left: 0;
        width: rem(17px);
        height: 1px;
        transition: all 0.4s ease;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .UserTagsPanelClient {
    position: relative;
    margin: 0 0 20px 0;
    padding: 25px 25px 25px 25px;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 15px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__head {
      &--hide {
        margin: 0 0 0 0 !important;
      }
    }

    &__add-tag-button {
      position: absolute;
      right: 28px;
      top: 24px;

      @include respond-to(sm) {
        top: 16px;
      }

      &--hide {
        display: none !important;
      }

      :global(button) {
        margin: 0 28px 0 0;
        transform: translateY(-3px);
      }
    }

    &__content {
      &--hide {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .UserTagsPanelClient {
      background: ${colors.white.hex};
    }

    .UserTagsPanelClient__tag {
      background: ${colors.primary.hex};
      color: ${colors.white.hex};
      border-radius: ${border.radius};
      button {
        &:after,
        &:before {
          background: ${colors.white.hex};
        }
        &:hover {
          &:after,
          &:before {
            background: ${colors.error.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
