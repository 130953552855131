import { DefaultButton, RadioButton, ReduxSelectField } from 'common/components';
import { FormMessageHOC } from 'common/HOCs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';

import { getWalletAccountsList } from 'client/multiWallet/_redux';
import { getTradingAccountList } from '../../../../../_redux/actions';

import { activateChallenge } from '../../../../_redux/actions';

import { staticStyles } from './style';

const StartChallengeForm = ({
  isWalletAccountAddonEnabled,
  isMultiWalletAddonEnabled,
  getWalletAccounts,
  walletAccounts,
  accounts,
  accountsIsLoaded,
  baseValue,
  typeValue,
  userId,
  budgetValue,
  getTradingAccountList,
  challenges,
  handleSubmit,
  account,
  withdrawAccount,
  submitting,
  initialize,
  invalid,
  dispatch,
  error,
  intl,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(
    isWalletAccountAddonEnabled || isMultiWalletAddonEnabled ? 'wallet' : 'tradingAccount'
  );
  const [refundMethod, setRefundMethod] = useState(
    isWalletAccountAddonEnabled || isMultiWalletAddonEnabled ? 'wallet' : 'tradingAccount'
  );
  useEffect(() => {
    if (!accountsIsLoaded) {
      getTradingAccountList();
    }
  }, [getTradingAccountList]);

  useEffect(() => {
    if (isMultiWalletAddonEnabled) {
      getWalletAccounts();
    }
  }, []);

  const typeOptions = useMemo(
    () => challenges.map(challengeType => ({ label: challengeType.title, value: challengeType.id })),
    [challenges]
  );

  const budgetOptions = useMemo(() => {
    const selectedChallengeType = challenges.find(({ id }) => id === typeValue);
    if (!selectedChallengeType || !selectedChallengeType.challenges) {
      return [];
    }

    return [...selectedChallengeType.challenges]
      .sort((a, b) => parseFloat(a.initialAccountBalance.amount) - parseFloat(b.initialAccountBalance.amount))
      .map(challenge => ({
        label: `${intl.formatNumber(challenge.initialAccountBalance.amount)} ${
          challenge.initialAccountBalance.currency
        }`,
        value: challenge.id,
      }));
  }, [challenges, typeValue, intl]);

  const submit = handleSubmit(({ walletAccount }) => {
    activateChallenge(
      {
        id: budgetValue,
        account: paymentMethod !== 'wallet' ? account : walletAccount || undefined,
        withdrawAccount: refundMethod !== 'wallet' ? withdrawAccount : walletAccount || undefined,
        userId,
      },
      dispatch
    );
  });

  const selectedChallengeData = useMemo(() => {
    if (typeof budgetValue === 'number') {
      const selectedGroup = challenges.find(({ id }) => id === typeValue);

      if (!selectedGroup) return null;

      return selectedGroup.challenges.find(({ id }) => id === budgetValue);
    }
    return null;
  }, [budgetValue, typeValue, challenges]);

  const [selectedLevel, setSelectedLevel] = useState(0);

  useEffect(() => {
    setSelectedLevel(0);
  }, []);

  const selectedLevelData = useMemo(() => {
    if (!selectedChallengeData) {
      return null;
    }

    return selectedChallengeData.levels[selectedLevel];
  }, [budgetValue, selectedLevel, typeValue, selectedChallengeData]);

  const accountsFiltered = useMemo(
    () => accounts.filter(acc => !acc.isDemo && !acc.isBlocked && !acc.loading),
    [accounts]
  );

  const accountsOptions = accountsFiltered.map(item => {
    const balance = baseValue === 'balance' ? item.balance : item.marginFree;
    return {
      label: `${item.accountTypeTitle} : ${item.login} ${balance ? `( ${balance.amount} ${balance.currency} )` : ''}`,
      value: item.id,
    };
  });

  const walletAccountsOptions = walletAccounts.map(item => ({
    label: `${item.accountTypeTitle} : ${item.login} ${
      item.balance ? `( ${item.balance.amount} ${item.balance.currency} )` : ''
    }`,
    value: item.id,
  }));

  const isSubmitButtonDisabled = useMemo(() => {
    if (!account && paymentMethod !== 'wallet') {
      return true;
    }
    if (!withdrawAccount && refundMethod !== 'wallet') {
      return true;
    }

    return invalid || submitting;
  }, [account, withdrawAccount, invalid, submitting, paymentMethod, refundMethod]);

  console.log({ isSubmitButtonDisabled });

  return (
    <form className="StartChallengeForm" onSubmit={submit}>
      <FM id="justStartChallengeFormTopText">
        {txt => <span className="StartChallengeForm__TopText challenge-text">{txt}</span>}
      </FM>
      <Field
        className="challenge-field"
        component={ReduxSelectField}
        name="type"
        maxMenuHeight={140}
        textId="justChallengeType"
        options={typeOptions}
        validate={[required()]}
        onChange={() => {
          initialize({ type: typeValue, budget: null });
        }}
        disabled={submitting}
        searchable
      />
      <Field
        component={ReduxSelectField}
        name="budget"
        maxMenuHeight={140}
        className="challenge-field"
        textId="justChallengeBalance"
        options={budgetOptions}
        validate={[required()]}
        disabled={submitting}
        searchable
      />
      <div
        className="StartChallengeForm__PaymentMethod"
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '25px' }}>
        <FM id="justPaymentMethodForChallenge">{txt => <span className="challenge-text">{txt}:</span>}</FM>
        {isWalletAccountAddonEnabled && (
          <RadioButton
            name="paymentMethod"
            textId="justWithWallet"
            value="wallet"
            onChange={() => setPaymentMethod('wallet')}
            checked={paymentMethod === 'wallet'}
          />
        )}

        {isMultiWalletAddonEnabled && (
          <>
            <RadioButton
              name="paymentMethod"
              textId="justWalletTitle"
              value="wallet"
              onChange={() => setPaymentMethod('wallet')}
              checked={paymentMethod === 'wallet'}
            />
            {paymentMethod === 'wallet' && (
              <Field
                component={ReduxSelectField}
                name="walletAccount"
                textId="justWalletTitle"
                options={walletAccountsOptions}
              />
            )}
          </>
        )}

        {!isWalletAccountAddonEnabled && !isMultiWalletAddonEnabled && (
          <>
            <RadioButton
              name="paymentMethod"
              textId="justWithTradingAccount"
              value="tradingAccount"
              onChange={() => setPaymentMethod('tradingAccount')}
              checked={paymentMethod === 'tradingAccount'}
            />
            {paymentMethod === 'tradingAccount' && (
              <Field component={ReduxSelectField} name="account" textId="justAccount" options={accountsOptions} />
            )}
          </>
        )}
      </div>

      <div
        className="StartChallengeForm__RefundMethod"
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '25px' }}>
        <FM id="justRefundMethodForChallenge">{txt => <span className="challenge-text">{txt}:</span>}</FM>
        {isWalletAccountAddonEnabled && (
          <RadioButton
            name="refundMethod"
            textId="justToMyWallet"
            value="wallet"
            onChange={() => setRefundMethod('wallet')}
            checked={refundMethod === 'wallet'}
          />
        )}

        {isMultiWalletAddonEnabled && (
          <>
            <RadioButton
              name="refundMethod"
              textId="justToMyWallet"
              value="wallet"
              onChange={() => setRefundMethod('wallet')}
              checked={refundMethod === 'wallet'}
            />
            {refundMethod === 'wallet' && (
              <Field
                component={ReduxSelectField}
                name="walletAccunt"
                textId="justWalletTitle"
                options={walletAccountsOptions}
              />
            )}
          </>
        )}
        <RadioButton
          name="refundMethod"
          textId="justToMyTradingAccount"
          value="tradingAccount"
          onChange={() => setRefundMethod('tradingAccount')}
          checked={refundMethod === 'tradingAccount'}
        />
        {refundMethod === 'tradingAccount' && (
          <Field component={ReduxSelectField} name="withdrawAccount" textId="justAccount" options={accountsOptions} />
        )}
      </div>
      {selectedChallengeData && (
        <div className="StartChallengeFormTable">
          <div className="StartChallengeFormTable__Header">
            {selectedChallengeData.levels.map((level, ind) => (
              <button
                key={`${level.name + ind}`}
                onClick={() => {
                  setSelectedLevel(ind);
                }}
                type="button"
                className={
                  selectedLevel === ind
                    ? 'StartChallengeFormTable__name StartChallengeFormTable__name--fullOpacity'
                    : 'StartChallengeFormTable__name'
                }>
                <div className="Round">{ind + 1}</div>
                <div className="Name">{level.name}</div>
              </button>
            ))}
          </div>
          <table width="100%">
            <tr>
              <td>
                <FM id="justMinimalTradingDays" />
              </td>
              <td>
                <FM id="justMaximumDailyLoss" />
              </td>
              <td>
                <FM id="justMaximumLoss" />
              </td>

              <td className="displayNoneHero">
                <FM id="justProfitTarget" />
              </td>
            </tr>
            <tr>
              <td>{selectedLevelData.minimum_trading_days}</td>
              <td style={{ color: '#EE3235' }}>
                {`${selectedLevelData.maximum_daily_loss.amount} ${selectedLevelData.maximum_daily_loss.currency}`}
              </td>
              <td style={{ color: '#EE3235' }}>
                {`${selectedLevelData.maximum_total_loss.amount} ${selectedLevelData.maximum_total_loss.currency}`}
              </td>
              <td style={{ color: '#39B841' }} className="displayNoneHero">
                {`${selectedLevelData.target.amount} ${selectedLevelData.target.currency}`}
              </td>
            </tr>
          </table>
        </div>
      )}

      <div className="StartChallengeForm__bottom">
        <div className="StartChallengeForm__RefundableFee">
          <FM id="justRefundableFee">{txt => <span>{txt}:</span>}</FM>
          <div>{selectedChallengeData?.cost ?? '---'}</div>
        </div>

        <div className="StartChallengeForm__LeftText">
          <FM id="justStartChallengeFormLeftText">{txt => <span className="challenge-text">{txt}</span>}</FM>
        </div>
        <div className="DivWithDefButton">
          <DefaultButton
            className="challenge-button"
            textId="justStartChallenge"
            type="submit"
            disabled={isSubmitButtonDisabled}
            loading={submitting}
            filled
          />
        </div>
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

const selector = formValueSelector('startChallengeForm');
export default compose(
  FormMessageHOC,
  injectIntl,
  connect(
    state => {
      const {
        type: typeValue,
        budget: budgetValue,
        account,
        walletAccount,
        withdrawAccount,
      } = selector(state, 'type', 'budget', 'account', 'withdrawAccount');
      return {
        userId: state.user?.profile?.id,
        typeValue,
        budgetValue,
        account,
        walletAccount,
        walletAccounts: state.client.multiWallet.walletAccounts.filter(
          account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
        ),
        withdrawAccount,
        accounts: state.client.common.accounts,
        accountsIsLoaded: state.client.common.accountsIsLoaded,
        baseValue: state.interfaceConfig.baseValue,
        isWalletAccountAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'wallet'),
        isMultiWalletAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'walletAccounts'),
      };
    },
    {
      getTradingAccountList: () => getTradingAccountList.request(),
      getWalletAccounts: () => getWalletAccountsList.request(),
    }
  ),
  reduxForm({
    form: 'startChallengeForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
    enableReinitialize: true,
  })
)(StartChallengeForm);
