import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { DefaultButton, ReduxSelectField, ReduxTextField, DateRangeField } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { getCommissionsList } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const CommissionsFilterForm = ({
  rewardFrequencies,
  search,
  handleSubmit,
  submitting,
  invalid,
  error,
  theme,
  intl,
  dispatch,
}) => {
  const submit = handleSubmit(values => getCommissionsList({ ...values, ...search }, dispatch));
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const ibSystems = [
    { value: '', label: intl.formatMessage({ id: 'justAll' }) },
    { value: 'pnl', label: intl.formatMessage({ id: 'ibPnL' }) },
    { value: 'traded_volume', label: intl.formatMessage({ id: 'ibTradedVolume' }) },
  ];

  const commissionStatuses = [
    { value: '', label: intl.formatMessage({ id: 'justAll' }) },
    { value: 'IN_PROGRESS', label: intl.formatMessage({ id: 'status_in_progress' }) },
    { value: 'READY_FOR_PAY', label: intl.formatMessage({ id: 'status_ready_for_pay' }) },
    { value: 'READY_FOR_WITHDRAW', label: intl.formatMessage({ id: 'status_ready_for_withdraw' }) },
    { value: 'WITHDRAW', label: intl.formatMessage({ id: 'status_withdraw' }) },
    { value: 'REJECTED', label: intl.formatMessage({ id: 'status_rejected' }) },
  ];

  const REWARD_FREQUENCIES = {
    dailyInstant: intl.formatMessage({ id: 'justDailyInstant' }),
    monthlyInstant: intl.formatMessage({ id: 'justMonthlyInstant' }),
    monthlyDelayed: intl.formatMessage({ id: 'justMonthlyDelayed' }),
  };

  return (
    <form className="CommissionsFilterForm" onSubmit={submit}>
      <FormattedMessage id="justSearchingParameters">
        {txt => <span className="CommissionsFilterForm__title">{txt}</span>}
      </FormattedMessage>
      <div className="CommissionsFilterForm__fields">
        <div className="CommissionsFilterForm__block">
          <Field
            component={ReduxTextField}
            type="text"
            name="partnerName"
            textId="justPartnerName"
            disabled={submitting}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="partnerCode"
            textId="boPartnerAccountNumber"
            disabled={submitting}
          />
          <Field component={ReduxTextField} type="text" name="commissionId" textId="boRewardId" disabled={submitting} />
          <Field
            component={ReduxSelectField}
            name="status"
            textId="boRewardStatus"
            options={commissionStatuses}
            disabled={submitting}
          />
          <Field
            component={ReduxSelectField}
            name="frequencyOfPayments"
            maxMenuHeight={100}
            textId="justFrequencyOfReward"
            options={rewardFrequencies.map(freq => ({
              value: freq,
              label: REWARD_FREQUENCIES[freq] ?? intl.formatMessage({ id: 'All' }),
            }))}
            disabled={submitting}
          />
        </div>
        <div className="CommissionsFilterForm__block">
          <Field
            component={ReduxSelectField}
            name="ibSystem"
            textId="boPartnerIbProgramm"
            options={ibSystems}
            disabled={submitting}
          />
          <Field component={DateRangeField} name="dateRange" disabled={submitting} />
          <Field
            component={ReduxTextField}
            type="text"
            name="leftAmount"
            textId="boRewardIsEqualOrLessThan"
            disabled={submitting}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="rightAmount"
            textId="boRewardIsEqualOrMoreThan"
            disabled={submitting}
          />
        </div>
      </div>
      <DefaultButton textId="justApply" type="submit" loading={submitting} disabled={invalid || submitting} filled />
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

CommissionsFilterForm.propTypes = {
  rewardFrequencies: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.string,
  search: PropTypes.object,
  theme: PropTypes.object,
};

CommissionsFilterForm.defaultProps = {
  error: '',
  search: {},
  theme: {},
};

export default compose(
  injectIntl,
  withTheme(),
  reduxForm({
    form: 'CommissionsFilterForm',
  })
)(CommissionsFilterForm);
export { CommissionsFilterForm };
