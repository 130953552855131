import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WalletAccounts {
    margin-bottom: 35px;
    h2 {
      margin-bottom: 15px;
    }
  }

  :global(.WalletAccounts__account__currency) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    font-weight: 500;
    border: 2px solid;
    font-size: 18px;
    border-radius: 50%;
  }

  :global(.WalletAccounts__account) {
    display: flex;
    align-items: center;
    gap: 16px;

    @include respond-to(xs) {
      gap: 8px;
    }
  }

  :global(.WalletAccounts__account__title) {
    font-size: 18px;
  }

  :global(.WalletAccounts__account__caption) {
    font-size: 13px !important;

    @include respond-to(sm) {
      display: none !important;
    }
  }

  :global(.WalletAccounts__ActionsButtonsCell) {
    display: flex;
    width: 45px;
  }

  .WalletAccounts--noAccounts {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px 25px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .WalletAccounts__body {
    :global(.ReactTable) {
      :global(.rt-thead),
      :global(.rt-tr-group) {
        min-width: 1050px !important;

        @include respond-to(lg) {
          min-width: 888px !important;
        }

        @include respond-to(md) {
          min-width: 763px !important;
        }
      }

      /* head */
      :global(.rt-thead.-header) {
        :global(.rt-tr) {
          margin: 0 15px;
          :global(.rt-th) {
            &:first-child {
              flex: 1 0 auto !important;
              min-width: 270px !important;

              @include respond-to(lg) {
                min-width: 120px !important;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              flex: 1 0 auto !important;
              min-width: 120px !important;
            }
            &:nth-child(4) {
              flex: 1 0 auto !important;
              min-width: 100px !important;

              @include respond-to(md) {
                display: none;
              }
            }
            &:nth-child(5) {
              flex: 100 0 auto !important;
              min-width: 160px !important;
            }
          }
        }
      }

      /* body */
      :global(.rt-tbody) {
        overflow: visible;

        :global(.rt-tr-group) {
          margin: 0 0 2px 0;

          :global(.rt-tr) {
            padding: 10px 18px;
            :global(.rt-td) {
              &:first-child {
                flex: 1 0 auto !important;
                min-width: 270px !important;

                @include respond-to(lg) {
                  min-width: 120px !important;
                }
              }
              &:nth-child(2),
              &:nth-child(3) {
                flex: 1 0 auto !important;
                min-width: 120px !important;
              }
              &:nth-child(4) {
                flex: 1 0 auto !important;
                min-width: 100px !important;

                @include respond-to(md) {
                  display: none;
                }
              }
              &:nth-child(5) {
                flex: 100 0 auto !important;
                min-width: 160px !important;
              }
            }
          }
        }
      }
    }
  }

  :global(.WalletAccounts__buttons-inner) {
    :global(button) {
      margin-right: 10px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .WalletAccounts--noAccounts {
      background-color: ${colors.white.hex};
    }

    :global(.WalletAccounts__account__currency) {
      border-color: ${colors.primary.hex};
    }

    :global(.WalletAccounts__account__title) {
      color: #074a6c;
    }

    :global(.WalletAccounts__account__caption) {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
