import css from 'styled-jsx/css';

import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/screen-size';

  :global(.RowLink) {
    @include respond-to(md) {
      justify-content: flex-start;
    }
  }

  .PaymentsTable {
    width: 100%;
    .ReactTable {
      .rt-thead {
        min-width: 1360px !important;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          min-width: 1480px !important;
        }
        .rt-tr {
          .rt-th {
            &:nth-child(1) {
              width: 40px !important;
            }
            &:nth-child(2) {
              width: 120px !important;
              flex: 120 0 auto !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 120px !important;
              }
            }
            &:nth-child(3) {
              width: 120px !important;
              flex: 120 0 auto !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 120px !important;
              }
            }
            &:nth-child(4) {
              width: 130px !important;
              flex: 130 0 auto !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 130px !important;
              }
            }
            &:nth-child(5),
            &:nth-last-child(3) {
              width: 100px !important;
              flex: 100 0 auto !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 110px !important;
              }
            }
            &:nth-child(6),
            &:nth-last-child(3) {
              width: 150px !important;
              flex: 150 0 auto !important;
              padding-left: 0;
            }
            &:nth-last-child(2) {
              width: 150px !important;
              flex: 150 0 auto !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 180px !important;
              }
            }
            &:last-child {
              width: 320px !important;
              flex: 320 0 auto !important;
            }
          }
        }
      }
      .rt-tbody {
        min-width: 1360px !important;
        display: block;
        overflow: visible;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          min-width: 1480px !important;
        }
        .rt-tr-group {
          .rt-tr {
            .rt-td {
              &:nth-child(1) {
                width: 40px !important;
              }
              &:nth-child(2) {
                width: 110px !important;
                flex: 110 0 auto !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 80px !important;
                }
                :global(.MoneyCell__inner) {
                  height: auto !important;
                  flex-wrap: wrap;
                }
                .MoneyCell__withdraw,
                .MoneyCell__wallet_withdraw,
                .MoneyCell__deposit,
                .MoneyCell__wallet_deposit,
                .MoneyCell__compensation,
                .MoneyCell__wallet_freeze,
                .MoneyCell__wallet_refund {
                  position: relative;
                  padding: rem(${isRTL ? '5px 30px 5px 0' : '5px 0 5px 30px'});
                  :global(svg) {
                    width: rem(20px);
                    height: rem(19px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: ${isRTL ? 'auto' : '0'};
                    right: ${isRTL ? '0' : 'auto'};
                  }
                }
                span {
                  &:first-child {
                    border: none;
                    width: auto;
                    height: auto;
                    font-size: rem(15px);
                    font-weight: 400;
                  }
                }
              }
              &:nth-child(3) {
                width: 130px !important;
                flex: 130 0 auto !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 130px !important;
                }
              }
              &:nth-child(4) {
                width: 130px !important;
                flex: 130 0 auto !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 130px !important;
                }
              }
              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7) {
                width: 130px !important;
                flex: 130 0 auto !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 130px !important;
                }
              }
              &:nth-last-child(3) {
                width: 150px !important;
                flex: 150 0 auto !important;
                span {
                  font-size: rem(15px);
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    max-width: 130px;
                    overflow: hidden;
                  }
                }
              }
              &:nth-last-child(2) {
                width: 150px !important;
                flex: 150 0 auto !important;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 3px;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 180px !important;
                }
              }
              &:last-child {
                overflow: visible;
                width: 320px !important;
                flex: 320 0 auto !important;

                span {
                  font-size: 13px;
                }
              }
              .StatusCell {
                overflow: hidden;
                text-overflow: ellipsis;
                top: 2px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    &__tags {
      width: 320px;
      display: flex;
      align-items: center;
      gap: 8px;

      &__caption {
        text-wrap: nowrap;
      }
    }

    .ResponsiveTable__thead {
      min-width: 340px !important;

      span {
        &:first-child {
          width: 40px !important;
        }
        flex: 60;
        text-align: center;
        padding: 5px 0;

        &:nth-last-child(2) {
          min-width: 30px;
          padding-right: 0;
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 340px !important;

      .ResponsiveTable__preview-main {
        height: 40px;
        .ResponsiveTable__row-item {
          flex: 60;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(2) {
            width: 40px;
            :global(.MoneyCell__inner) {
              height: auto !important;
              flex-wrap: wrap;
            }
            .MoneyCell__withdraw,
            .MoneyCell__wallet_withdraw,
            .MoneyCell__deposit,
            .MoneyCell__wallet_deposit,
            .MoneyCell__compensation,
            .MoneyCell__wallet_freeze,
            .MoneyCell__wallet_refund {
              position: relative;
              padding: rem(${isRTL ? '5px 30px 5px 0' : '5px 0 5px 30px'});
              :global(svg) {
                width: rem(20px);
                height: rem(19px);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: ${isRTL ? 'auto' : '0'};
                right: ${isRTL ? '0' : 'auto'};
              }
            }
            span {
              &:first-child {
                border: none;
                width: auto;
                height: auto;
                font-size: rem(15px);
                font-weight: 400;
              }
            }
          }
          &nth-child(2) {
            :global(.MoneyCell__inner) {
              height: auto !important;
              flex-wrap: wrap;
            }
            .MoneyCell__withdraw,
            .MoneyCell__wallet_withdraw,
            .MoneyCell__deposit,
            .MoneyCell__wallet_deposit,
            .MoneyCell__compensation,
            .MoneyCell__wallet_freeze,
            .MoneyCell__wallet_refund {
              position: relative;
              padding: rem(${isRTL ? '5px 30px 5px 0' : '5px 0 5px 30px'});
              :global(svg) {
                width: rem(20px);
                height: rem(19px);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: ${isRTL ? 'auto' : '0'};
                right: ${isRTL ? '0' : 'auto'};
              }
            }
            span {
              &:first-child {
                border: none;
                width: auto;
                height: auto;
                font-size: rem(15px);
                font-weight: 400;
              }
            }
          }
        }
      }

      .ResponsiveTable__preview-description {
        .ResponsiveTable__row-item {
          justify-content: flex-start;
          padding-left: 10%;
        }
        :global(.StatusCell) {
          font-size: rem(12px) !important;
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 15%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PaymentsTable {
      .ReactTable {
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &:nth-child(1) {
                  a {
                    color: ${colors.black.hex};
                  }
                }

                &:nth-child(2) {
                  span {
                    &:first-child {
                      color: ${colors.success.hex};
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ResponsiveTable__tbody {
        .ResponsiveTable__row-item {
          &:first-child {
            span {
              &:first-child {
                color: ${colors.success.hex};
              }
            }
          }
        }
      }

      .MoneyCell__withdraw,
      .MoneyCell__wallet_withdraw {
        .MoneyCell__inner span {
          color: ${colors.error.hex} !important;
        }
        :global(svg) {
          fill: ${colors.error.hex};
          :global(path) {
            fill: ${colors.error.hex};
          }
        }
      }
      .MoneyCell__deposit,
      .MoneyCell__wallet_deposit,
      .MoneyCell__compensation,
      .MoneyCell__wallet_freeze,
      .MoneyCell__wallet_refund {
        .MoneyCell__inner span {
          color: ${colors.success.hex} !important;
        }
        :global(svg) {
          fill: ${colors.success.hex};
          :global(path) {
            fill: ${colors.success.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
