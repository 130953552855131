import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { BasicModal, DefaultButton } from 'common/components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetUserOTP, showResetOtpSuccessModal } from '../../_redux';
import { staticStyles } from './style';

const TwoFactorAuthorization = ({
  id,
  resetUserOTP,
  resetUserOTPIsLoading,
  showResetOtpSuccessModal,
  resetOTPIsSuccess,
}) => (
  <div className="TwoFactorAuth">
    <DefaultButton
      textId="resetTwoFactorAuth"
      onClick={() => {
        resetUserOTP(id);
      }}
      disabled={resetUserOTPIsLoading}
      loading={resetUserOTPIsLoading}
      filled
    />
    <BasicModal
      captionId="resetTwoFactorAuthSuccess"
      isOpen={resetOTPIsSuccess}
      onRequestClose={() => showResetOtpSuccessModal(false)}>
      {resetOTPIsSuccess && (
        <div className="ResetTwoFactorAuthSuccess">
          <DefaultButton textId="justDone" butColor="success" onClick={() => showResetOtpSuccessModal(false)} />
        </div>
      )}
    </BasicModal>

    <style jsx>{staticStyles}</style>
  </div>
);

TwoFactorAuthorization.propTypes = {
  id: PropTypes.string.isRequired,
  resetUserOTP: PropTypes.func.isRequired,
  resetUserOTPIsLoading: PropTypes.bool.isRequired,
  showResetOtpSuccessModal: PropTypes.func.isRequired,
  resetOTPIsSuccess: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      resetUserOTPIsLoading: state.backoffice.users.resetUserOTPIsLoading,
      resetOTPIsSuccess: state.backoffice.users.resetOTPIsSuccess,
    }),
    {
      resetUserOTP: userId => resetUserOTP.request(userId),
      showResetOtpSuccessModal: isSuccess => showResetOtpSuccessModal(isSuccess),
    }
  )
)(TwoFactorAuthorization);

export { TwoFactorAuthorization };
