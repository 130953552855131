import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FormMessageHOC } from 'common/HOCs';
import { DefaultButton, ReduxSelectField, CheckboxField, RadioButton } from 'common/components';

import { withdrawPartnershipAccount, withdrawPartnershipWalletAccount } from '../../_redux';

import { staticStyles } from './style';

const PartnerAccountWithdrawalForm = ({
  accounts,
  walletAccounts,
  isMultiWalletEnabled,
  isWalletAccountAddonEnabled,
  partnerAccountId,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const [withdrawFrom, setWithdrawFrom] = useState('account');

  const [toWalletSelected, setToWalletSelected] = useState(false);
  const submit = handleSubmit(({ toWallet, accountId }) => {
    const finalValues = toWallet ? { toWallet } : { accountId };
    return isMultiWalletEnabled
      ? withdrawPartnershipWalletAccount({ ...finalValues, partnerAccountId }, dispatch)
      : withdrawPartnershipAccount({ ...finalValues, partnerAccountId }, dispatch);
  });

  return (
    <form className="PartnerAccountWithdrawalForm" onSubmit={submit}>
      {isMultiWalletEnabled && (
        <div className="PartnerAccountWithdrawalForm__radio">
          <div>
            <RadioButton
              name="withdrawFrom"
              textId="newWithdrawToAccount"
              value="withdrawAccount"
              onChange={() => setWithdrawFrom('account')}
              checked={withdrawFrom === 'account'}
            />
          </div>
          <div>
            <RadioButton
              name="withdrawFrom"
              textId="newWithdrawToWallet"
              value="withdrawWalletAccount"
              onChange={() => setWithdrawFrom('withdrawWalletAccount')}
              checked={withdrawFrom === 'withdrawWalletAccount'}
            />
          </div>
        </div>
      )}
      <FormattedMessage id="partnerAccountWithdrawalDescription">
        {txt => <p className="PartnerAccountWithdrawalForm__description">{txt}</p>}
      </FormattedMessage>
      {isMultiWalletEnabled && (
        <>
          {withdrawFrom === 'account' && (
            <Field
              component={ReduxSelectField}
              name="accountId"
              textId="clientAccount"
              options={accounts.map(account => ({
                value: account.id,
                label: account.login,
              }))}
              disabled={submitting}
              maxMenuHeight={90}
            />
          )}
          {withdrawFrom === 'withdrawWalletAccount' && (
            <Field
              component={ReduxSelectField}
              name="accountId"
              textId="justWalletTitle"
              options={walletAccounts.map(account => ({
                value: account.id,
                label: `${account.login} ${account.balance?.currency}`,
              }))}
              disabled={submitting}
              maxMenuHeight={90}
            />
          )}
        </>
      )}
      {!isMultiWalletEnabled && (
        <Field
          component={ReduxSelectField}
          name="accountId"
          textId="clientAccount"
          options={accounts.map(account => ({
            value: account.id,
            label: account.login,
          }))}
          disabled={toWalletSelected || submitting}
          maxMenuHeight={90}
        />
      )}
      {isWalletAccountAddonEnabled && !isMultiWalletEnabled && (
        <Field
          component={CheckboxField}
          onChange={() => {
            setToWalletSelected(prev => !prev);
          }}
          name="toWallet"
          textId="toWallet"
          disabled={submitting}
        />
      )}
      <DefaultButton
        textId="justTransfer"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

PartnerAccountWithdrawalForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  partnerAccountId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  isWalletAccountAddonEnabled: PropTypes.bool.isRequired,
  walletAccounts: PropTypes.array,
  isMultiWalletEnabled: PropTypes.bool,
};

PartnerAccountWithdrawalForm.defaultProps = {
  walletAccounts: [],
  isMultiWalletEnabled: false,
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(state => ({
    isWalletAccountAddonEnabled: state.interfaceConfig?.addons?.some(addon => addon === 'wallet'),
    isMultiWalletEnabled: state.interfaceConfig.addons.includes('walletAccounts'),
  })),
  reduxForm({
    form: 'partner-account-withdrawal-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleWithdrawalModal();
    },
  })
)(PartnerAccountWithdrawalForm);
export { PartnerAccountWithdrawalForm };
