import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserAccounts {
    margin-bottom: 35px;
    h2 {
      margin-bottom: 15px;
    }

    :global(.LoginCell__name) {
      font-size: 13px !important;
      line-height: 16px !important;
      position: relative;
      top: 1px;
      @include respond-to(lg) {
        display: none !important;
      }
    }

    :global(.LoginCell__type) {
      @include respond-to(lg) {
        display: none !important;
      }
    }
  }

  :global(.UserAccounts__PartnerCodeCell) {
    display: flex;
    width: 160px;
    padding-right: 40px;
    justify-content: space-between;
  }

  :global(.UserAccounts__ActionsButtonsCell) {
    display: flex;
    width: 45px;
  }

  .UserAccounts--noAccounts {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px 25px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .UserAccounts__body {
    :global(.ReactTable) {
      :global(.rt-thead),
      :global(.rt-tr-group) {
        min-width: 1050px !important;

        @include respond-to(lg) {
          min-width: 888px !important;
        }

        @include respond-to(md) {
          min-width: 763px !important;
        }
      }

      /* head */
      :global(.rt-thead.-header) {
        :global(.rt-tr) {
          margin: 0 15px;
          :global(.rt-th) {
            &:first-child {
              flex: 320 0 auto !important;
              min-width: 320px !important;

              @include respond-to(lg) {
                min-width: 120px !important;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              flex: 90 0 auto !important;
              min-width: 90px !important;
            }
            &:nth-child(4) {
              flex: 90 0 auto !important;
              min-width: 90px !important;

              @include respond-to(md) {
                display: none;
              }
            }
            &:nth-child(5) {
              flex: 160 0 auto !important;
              min-width: 160px !important;
            }
          }
        }
      }

      /* body */
      :global(.rt-tbody) {
        overflow: visible;

        :global(.rt-tr-group) {
          margin: 0 0 2px 0;

          :global(.rt-tr) {
            padding: 10px 18px;
            :global(.rt-td) {
              &:first-child {
                flex: 320 0 auto !important;
                min-width: 320px !important;

                @include respond-to(lg) {
                  min-width: 120px !important;
                }
              }
              &:nth-child(2),
              &:nth-child(3) {
                flex: 90 0 auto !important;
                min-width: 90px !important;
              }
              &:nth-child(4) {
                flex: 90 0 auto !important;
                min-width: 90px !important;

                @include respond-to(md) {
                  display: none;
                }
              }
              &:nth-child(5) {
                flex: 160 0 auto !important;
                min-width: 160px !important;
              }

              :global(.Tooltip) {
                background: #ffffff !important;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                border-radius: 5px;

                pre {
                  color: #000;
                }

                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .UserAccounts--partnerCode {
    .UserAccounts__body {
      :global(.ReactTable) {
        :global(.rt-thead.-header) {
          min-width: 680px !important;
          :global(.rt-tr) {
            :global(.rt-th) {
              &:nth-child(5) {
                flex: 80 0 auto !important;
                width: 80px !important;
              }
              &:nth-child(6) {
                flex: 210 0 auto !important;
                width: 210px !important;
              }
            }
          }
        }
        :global(.rt-tbody) {
          min-width: 680px !important;
          :global(.rt-tr-group) {
            :global(.rt-tr) {
              :global(.rt-td) {
                &:nth-child(5) {
                  flex: 80 0 auto !important;
                  width: 80px !important;
                  font-size: rem(16px);
                }
                &:nth-child(6) {
                  flex: 210 0 auto !important;
                  width: 210px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  :global(.UserAccounts__buttons-inner) {
    :global(button) {
      margin-right: 10px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserAccounts--noAccounts {
      background-color: ${colors.white.hex};
    }

    :global(.LoginCell__name) {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
