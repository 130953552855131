import queryString from 'query-string';
import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NoItemsPanel, PageContentPreloader, PageLayout } from 'common/components';
import { getChallenges } from 'client/challenges/_redux';
import noPayments from 'common/images/noPayments.svg';
import { ChallengesLeaderboardTable, LeaderboardSelector } from '../../_components';
import { getChallegesLeaderboard } from '../../_redux';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengesLeaderboardPage = ({
  challenges,
  challengesAreLoaded,
  challengesLeaderboard,
  getChallenges,
  challengesLeaderboardAreLoaded,
  getChallegesLeaderboard,
  location,
  history,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(query => getChallegesLeaderboard({ ...query }), [getChallegesLeaderboard]);

  useEffect(() => {
    if (!challengesAreLoaded) {
      getChallenges();
    }
  }, []);

  useEffect(() => {
    getChallegesLeaderboard({ search: query });
  }, [getList, query]);

  return (
    <PageLayout captionSlug="justChallengesLeaderboard" captionSlugClassName="challenge-text" showReload={false}>
      <PageContentPreloader ready={challengesLeaderboardAreLoaded} type="bigLine" firstLaunchOnly>
        <LeaderboardSelector challenges={challenges} />
        {challengesLeaderboard.count ? (
          <ChallengesLeaderboardTable
            data={challengesLeaderboard}
            loading={!challengesLeaderboardAreLoaded}
            location={location}
            history={history}
            getList={() => {}}
            params={{
              search: query,
            }}
          />
        ) : (
          <NoItemsPanel
            icon={noPayments}
            captionSlugClassName="challenge-text"
            captionSlug={isHero ? 'noChallengesMatchingThisParameterHERO10X' : 'noChallengesMatchingThisParameter'}
          />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

ChallengesLeaderboardPage.propTypes = {
  challengesLeaderboard: PropTypes.object.isRequired,
  challengesLeaderboardAreLoaded: PropTypes.bool.isRequired,
  getChallegesLeaderboard: PropTypes.func.isRequired,
  getChallenges: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      challenges: state.client.challenges.challenges,
      challengesAreLoaded: state.client.challenges.challengesAreLoaded,
      challengesLeaderboard: state.client.challenges.challengesLeaderboard,
      challengesLeaderboardAreLoaded: state.client.challenges.challengesLeaderboardAreLoaded,
    }),
    {
      getChallegesLeaderboard: data => getChallegesLeaderboard.request(data),
      getChallenges: () => getChallenges.request(),
    }
  )
)(ChallengesLeaderboardPage);
