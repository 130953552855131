import { call, fork, put, take, takeLatest } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import { getProfileSaga } from '../../../interface/user/_redux/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getChallengesSaga() {
  try {
    const { data } = yield call(api.getChallenges);
    yield put(ActionTypes.getChallenges.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getChallenges.failure());
  }
}

export function* getChallengesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CHALLENGES[REQUEST]);
    yield call(getChallengesSaga);
  }
}

export function* getUserChallengesSaga() {
  try {
    const { data } = yield call(api.getUserChallenges);
    yield put(ActionTypes.getUserChallenges.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserChallenges.failure());
  }
}

export function* getUserChallengesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_USER_CHALLENGES[REQUEST]);
    yield call(getUserChallengesSaga);
  }
}

export function* activateChallengeSaga({ id, withdrawAccount, account }) {
  try {
    const { data, status } = yield call(api.activateChallenge, id, withdrawAccount, account);

    console.log({ status });

    if (status < 300) {
      console.log('succ', { status });
      yield put(ActionTypes.activateChallenge.success(data));
      yield call(getUserChallengesSaga);
      yield call(getProfileSaga);
    } else {
      // FIXME: Ошибка не отображается в форме, а форма сама закрывается
      console.log('fail');
      const error = data.detail ? data.detail : data;
      yield put(acceptError(error, false));
      // yield put(ActionTypes.activateChallenge.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.activateChallenge.failure());
  }
}

export function* activateChallengeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.activateChallenge.REQUEST);
    yield call(activateChallengeSaga, payload);
  }
}

export function* getChallengeSaga({ id }) {
  try {
    const { data } = yield call(api.getChallenge, id);
    yield put(ActionTypes.getChallenge.success(data));
  } catch (e) {
    yield put(acceptError(e, false));
    yield put(ActionTypes.getChallenge.failure());
  }
}

export function* getChallengeWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_CHALLENGE[REQUEST]);
    yield call(getChallengeSaga, payload);
  }
}

export function* changeRefundableFeeRecieveMethodSaga({ challengeId, withdrawAccount }) {
  try {
    const { data, status } = yield call(api.changeRefundableFeeRecieveMethod, challengeId, withdrawAccount);

    console.log({ status });

    if (status < 300) {
      yield put(ActionTypes.changeRefundableFeeRecieveMethod.success(data));
      yield call(getChallengeSaga, { id: challengeId });
    } else {
      const error = data.detail ? data.detail : data;
      yield put(acceptError(error, false));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changeRefundableFeeRecieveMethod.failure());
  }
}

export function* changeRefundableFeeRecieveMethodWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.changeRefundableFeeRecieveMethod.REQUEST);
    console.log(11, { payload });
    yield call(changeRefundableFeeRecieveMethodSaga, payload);
  }
}

export function* restartChallengeSaga({ id }) {
  try {
    const { data, status } = yield call(api.restartChallenge, id);
    if (status < 300) {
      yield put(ActionTypes.restartChallenge.success(data));
    } else {
      yield put(acceptError(data?.detail, false));
    }
    yield call(getUserChallengesSaga);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.restartChallenge.failure());
  }
}

export function* restartChallengeWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.RESTART_CHALLENGE[REQUEST]);
    yield call(restartChallengeSaga, payload);
  }
}

export function* archiveChallengeSaga({ id }) {
  try {
    const { data } = yield call(api.archiveChallenge, id);
    yield put(ActionTypes.archiveChallenge.success(data));
    yield call(getUserChallengesSaga);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.archiveChallenge.failure());
  }
}

export function* archiveChallengeWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.ARCHIVE_CHALLENGE[REQUEST]);
    yield call(archiveChallengeSaga, payload);
  }
}

export function* getChallegesLeaderboardSaga({ search }) {
  try {
    const { status, data } = yield call(api.getChallegesLeaderboard, search);
    if (status < 300) {
      yield put(ActionTypes.getChallegesLeaderboard.success(data));
    } else {
      yield put(ActionTypes.getChallegesLeaderboard.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getChallegesLeaderboard.failure());
  }
}

export function* getChallegesLeaderboardWatcherSaga() {
  yield takeLatest(ActionTypes.GET_CHALLENGES_LEADERBOARD[REQUEST], getChallegesLeaderboardSaga);
}

export function* manualWithdrawProfitFromChallengeSaga({ userChallengeId, amount }) {
  try {
    const { data, status } = yield call(api.manualWithdrawProfitFromChallenge, userChallengeId, amount);

    if (status < 300) {
      yield put(ActionTypes.manualWithdrawProfitFromChallenge.success(data));
      yield call(getProfileSaga);
      yield call(getUserChallengesSaga);
    } else {
      const error = data.detail ? data.detail : data;
      const manualError = typeof error === 'string' ? [error] : error;
      yield put(ActionTypes.manualWithdrawActionError(manualError));
      yield put(ActionTypes.manualWithdrawProfitFromChallenge.failure());
      // yield put(acceptError(error, false));
    }
  } catch (e) {
    console.log('catch');
    yield put(acceptError(e, true));
    yield put(ActionTypes.manualWithdrawProfitFromChallenge.failure());
  }
}

export function* manualWithdrawProfitFromChallengeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.manualWithdrawProfitFromChallenge.REQUEST);
    yield call(manualWithdrawProfitFromChallengeSaga, payload);
  }
}

export default [
  fork(manualWithdrawProfitFromChallengeWatcherSaga),
  fork(changeRefundableFeeRecieveMethodWatcherSaga),
  fork(getChallengesWatcherSaga),
  fork(getUserChallengesWatcherSaga),
  fork(activateChallengeWatcherSaga),
  fork(getChallengeWatcherSaga),
  fork(restartChallengeWatcherSaga),
  fork(archiveChallengeWatcherSaga),
  fork(getChallegesLeaderboardWatcherSaga),
];
