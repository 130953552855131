import * as ActionTypes from './actions';

export const initialState = {
  authorized: !!localStorage.getItem('jwtToken'),
  authStep: '',
  authError: '',
  codeTimer: 0,
  resendingOTP: false,
  isAutologinEmailConfirmError: false,
  telegramAuthData: null,
  telegramAuthError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.getOTPToken.FAILURE:
      return { ...state, isAutologinEmailConfirmError: !!action.payload.emailOrPhone };

    case ActionTypes.resendOTPToken.REQUEST:
      return { ...state, resendingOTP: true };
    case ActionTypes.resendOTPToken.SUCCESS:
    case ActionTypes.resendOTPToken.FAILURE:
      return { ...state, resendingOTP: false };

    case ActionTypes.CHANGE_AUTH_STEP:
      return { ...state, authStep: action.step };

    case ActionTypes.REDUCE_CODE_TIMER:
      return { ...state, codeTimer: action.codeTimer };

    case ActionTypes.getJWTToken.SUCCESS:
      return { ...state, authorized: true };

    case ActionTypes.SET_AUTHORIZED:
      return { ...state, authorized: true };

    case ActionTypes.SET_NON_AUTHORIZED:
      return { ...state, authorized: false };

    case ActionTypes.RESET_AUTH:
      return {
        ...initialState,
        authorized: state.authorized,
        authError: action.error,
      };

    case ActionTypes.LOGOUT_SUCCESS:
      return { ...initialState, authorized: false };

    case ActionTypes.LOGIN_WITH_TELEGRAM.FAILURE:
      return { ...state, telegramAuthError: true };

    default:
      return state;
  }
};

export default reducer;
