import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DefaultButton, ReduxTextField } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { setAuthorized } from 'auth/login/_redux';

import { staticStyles, getDynamicStyles } from './style';

const TwoStepGoogleForm = ({
  isNewSetting,
  isRegister,
  isReset,
  isLogin,
  withdrawalId,
  rememberUser,
  onClose,
  onSubmit,
  tfaContent,
  handleSubmit,
  dispatch,
  submitting,
  theme,
  error,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(values => {
    if (rememberUser) {
      return onSubmit({ ...values, rememberMe: rememberUser }, dispatch);
    }

    if (withdrawalId) {
      return onSubmit({ id: withdrawalId, ...values }, dispatch);
    }

    if (isReset || isLogin) {
      return onSubmit(values, dispatch);
    }

    return onSubmit({ ...values, otpType: 'totp' }, dispatch);
  });

  const handleClose = () => {
    onClose();

    if (isRegister) {
      dispatch(setAuthorized());
    }
  };

  return (
    <div className="TwoStepGoogleForm__wrapper">
      <form className="TwoStepGoogleForm" onSubmit={submit}>
        <div className="TwoStepGoogleForm__header">
          <FormattedMessage
            id="authYouWillNeedGoogleAuthenticator"
            values={{
              link: (
                <a
                  className="TwoStepGoogleForm__header__link"
                  rel="noreferrer"
                  href="https://support.google.com/accounts/answer/1066447"
                  target="_blank">
                  <FormattedMessage id="authAuthenticatorApp" />
                </a>
              ),
            }}
          />
        </div>
        {(isNewSetting || isRegister) && tfaContent.qrCode && (
          <div className="TwoStepGoogleForm__qr-code">
            <FormattedMessage id="authScanQrCode">
              {txt => <span className="TwoStepGoogleForm__qr-code__title">{txt}</span>}
            </FormattedMessage>
            <img
              className="TwoStepGoogleForm__qr-code__image"
              src={`data:image/png;base64, ${tfaContent.qrCode}`}
              alt="QR"
            />
          </div>
        )}
        {(isNewSetting || isRegister) && tfaContent.secret && (
          <div className="TwoStepGoogleForm__secret-key">
            <FormattedMessage id="authUseSecretKey">
              {txt => (
                <span className="TwoStepGoogleForm__secret-key__caption">
                  {txt}: {tfaContent.secret}
                </span>
              )}
            </FormattedMessage>
          </div>
        )}
        <div className="TwoStepGoogleForm__field">
          <FormattedMessage id="authEnterAuthenticationCode">{txt => <span>{txt}:</span>}</FormattedMessage>
          <div className="TwoStepGoogleForm__field__code">
            <Field
              component={ReduxTextField}
              name="otp"
              textId="authCode"
              disabled={submitting}
              validate={[required()]}
            />
            <DefaultButton
              textId="justVerify"
              type="submit"
              filled
              className="TwoStepGoogleForm__submit-button"
              disabled={submitting}
            />
          </div>
          {error && <strong className="formError">{error}</strong>}
        </div>
      </form>
      <DefaultButton textId="justCancel" className="TwoStepGoogleForm__cancel-button" onClick={handleClose} />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TwoStepGoogleForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isNewSetting: PropTypes.bool,
  isRegister: PropTypes.bool,
  isReset: PropTypes.bool,
  isLogin: PropTypes.bool,
  withdrawalId: PropTypes.number,
  rememberUser: PropTypes.bool,
  tfaContent: PropTypes.object,
  error: PropTypes.string,
  theme: PropTypes.object,
};

TwoStepGoogleForm.defaultProps = {
  tfaContent: {},
  theme: {},
  isNewSetting: false,
  isRegister: false,
  isReset: false,
  isLogin: false,
  withdrawalId: null,
  rememberUser: false,
  error: '',
};

export default compose(
  withTheme(),
  connect(state => ({
    tfaContent: state.auth.tfa.tfaContent,
  })),
  reduxForm({
    form: 'TwoStepGoogleForm',
    onSubmitSuccess: (result, dispatch, { onClose, onSuccess, isChangingFirstStep }) => {
      if (isChangingFirstStep) {
        onSuccess();

        return;
      }

      onClose();
      onSuccess?.('authCodesWillBeUsedGoogle');
    },
  })
)(TwoStepGoogleForm);
