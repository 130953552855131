import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { getCookie, setCookie } from 'common/utils/helpers';
import { PageTabs, TranslatedNavLink } from 'common/components';
import { getCountries } from 'client/KYC/_redux';
import { TwoStepRegisterModal } from 'auth/twoStepAuth/_components';

import RegistrationForm from '../_components/RegistrationForm';
import ThankForRegistration from '../_components/ThankForRegistration';
import { staticStyles, getDynamicStyles } from './style';

const RegistrationPage = ({
  isEmailConfirmationEnabled,
  isCorporateAccountEnabled,
  location,
  history,
  telegramAuthError,
  theme,
  partnershipConfig,
  countries,
  countriesAreLoaded,
  getCountries,
  tfAuthSettings,
}) => {
  const { defaultPartnerCodeParamName, partnerCodeParamNames } = partnershipConfig;
  const { enabled: isTfaEnabled, mandatory: isTfaRequired } = tfAuthSettings;
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const partnerCodeCookie = getCookie(defaultPartnerCodeParamName);

  const partnerCodeParamName = Object.entries(queryString.parse(location.search)).find(([paramKey]) =>
    partnerCodeParamNames.some(param => param === paramKey)
  )?.[0];

  /* eslint-disable */
  const [isRegistered, setRegistered] = useState(false);
  const [isTfaModalOpened, setIsTfaModalOpened] = useState(false);
  const { telegramAuth, click_id: clickId, gclid } = queryString.parse(location.search);
  const queryPartnerCode = queryString.parse(location.search)[partnerCodeParamName];
  const partnerCode = queryPartnerCode || localStorage.getItem('partnerCode') || partnerCodeCookie;
  const isTelegramAuth = telegramAuth === 'true' || telegramAuthError;
  const telegramDataJson = sessionStorage.getItem('telegramData');
  const telegramData = JSON.parse(telegramDataJson || '{}');
  const initialValues =
    telegramData && isTelegramAuth
      ? {
          firstName: telegramData.telegramFirstName ?? '',
          lastName: telegramData.telegramLastName ?? '',
          partnerCode: partnerCode,
        }
      : { partnerCode: partnerCode };

  /* eslint-enable */

  const handleTfaModalToggle = () => {
    setIsTfaModalOpened(isOpened => !isOpened);
  };

  useEffect(() => {
    if (clickId) {
      setCookie('click_id', clickId, 30);
    }

    if (gclid) {
      setCookie('gclid', gclid, 30);
    }
  }, []);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (isEmailConfirmationEnabled && isRegistered) {
      history.push('/auth/register/check-email');
    }
  }, [isRegistered, isEmailConfirmationEnabled]);

  return (
    <>
      <PageTabs forAuth>
        <TranslatedNavLink
          navLinkId="registration-page-nav-to-login-page"
          id="authLogin"
          to={`/auth/login${isTelegramAuth ? `?telegramAuth=true` : ''}`}
        />
        <TranslatedNavLink
          navLinkId="registration-page-nav-to-registration-page-active"
          id="authRegister"
          to="/auth/register"
        />
      </PageTabs>
      {isTelegramAuth && !isRegistered && (
        <div className="Auth__message">
          <FormattedMessage id="telegramRegisterMessage">{txt => <p>{txt}</p>}</FormattedMessage>
        </div>
      )}
      {isEmailConfirmationEnabled && isRegistered ? (
        <ThankForRegistration />
      ) : (
        <RegistrationForm
          initialValues={initialValues}
          displayThankBlock={() => setRegistered(true)}
          displayTfaModal={handleTfaModalToggle}
          isTfaRequired={isTfaEnabled && isTfaRequired}
          isCorporate={isCorporateAccountEnabled}
          isEmailConfirmationEnabled={isEmailConfirmationEnabled}
          history={history}
          isTelegramAuth={isTelegramAuth}
          telegramData={telegramData}
          countries={countries}
          countriesAreLoaded={countriesAreLoaded}
        />
      )}
      {isTfaEnabled && isTfaRequired && (
        <TwoStepRegisterModal isOpen={isTfaModalOpened} onRequestClose={handleTfaModalToggle} />
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </>
  );
};

RegistrationPage.propTypes = {
  isEmailConfirmationEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  telegramAuthError: PropTypes.bool.isRequired,
  isCorporateAccountEnabled: PropTypes.bool,
  tfAuthSettings: PropTypes.object,
  theme: PropTypes.object,
};

RegistrationPage.defaultProps = {
  tfAuthSettings: {},
  location: {},
  theme: {},
  isCorporateAccountEnabled: false,
};
export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      isEmailConfirmationEnabled: state.interfaceConfig.emailConfirmation.enabled,
      isCorporateAccountEnabled: state.interfaceConfig?.kycSettings?.corporate_account_enabled,
      tfAuthSettings: state.interfaceConfig.tfAuthSettings,
      telegramAuthError: state.auth.registration.telegramAuthError,
      partnershipConfig: state.interfaceConfig.partnership,
      countries: state.client.kyc.countries,
      countriesAreLoaded: state.client.kyc.countriesAreLoaded,
    }),
    {
      getCountries: () => getCountries.request(),
    }
  )
)(RegistrationPage);
export { RegistrationPage };
