import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import {
  // getCRMManagersFiltersList,
  // createCRMManagersCustomFilter,
  // deleteCRMManagersCustomFilter,
  // editCRMManagersCustomFilter,
  getCRMManagersFiltersOptions,
  getCRMManagersFilter,
} from 'crm/analytics/_redux';

import { SearchBar } from 'crm/_components';
import { staticStyles } from './style';

const ManagersActionsTab = ({
  // crmManagersFilter,
  // crmManagersFilterIsLoaded,
  // filtersManagersOptions,
  // filtersOptionsManagersPending,
  location,
  history,
}) => {
  const query = useMemo(() => ({ ...queryString.parse(location.search) }), [location.search]);

  const handleSearchChange = searchValue => {
    history.replace({
      ...location,
      search: queryString.stringify({
        ...query,
        search: searchValue,
      }),
    });
  };

  return (
    <div className="ManagersActionsTab">
      <div className="ManagersActionsTab__Top">
        <div className="ManagersActionsTab__TopLeft">
          <SearchBar onChange={handleSearchChange} />
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ManagersActionsTab.propTypes = {
  // filtersManagersOptions: PropTypes.object.isRequired,
  // filtersOptionsManagersPending: PropTypes.bool.isRequired,
  // crmManagersFilter: PropTypes.object.isRequired,
  // crmManagersFilterIsLoaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    state => ({
      filtersManagersOptions: state.crm.analytics.filtersOptions,
      filtersOptionsManagersPending: state.crm.analytics.filtersOptionsPending,
      crmManagersFilter: state.crm.analytics.crmFilter,
      crmManagersFilterIsLoaded: state.crm.analytics.crmFilterIsLoaded,
    }),
    {
      getCRMManagersFiltersOptions: () => getCRMManagersFiltersOptions.request(),
      getCRMManagersFilter: id => getCRMManagersFilter.request(id),
    }
  )
)(ManagersActionsTab);
