import { SubmissionError } from 'redux-form';
import { put, call, fork, take } from 'redux-saga/effects';
import { getProfileSaga } from 'interface/user/_redux/sagas';
import { getPaymentsSaga } from 'client/payments/_redux/sagas';

import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* requestChangeTfaTypeSaga() {
  try {
    const { status, data } = yield call(api.requestChangeTfaType);
    if (status < 300) {
      yield put(ActionTypes.requestChangeTfaType.success(data));
    } else {
      yield put(ActionTypes.requestChangeTfaType.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.requestChangeTfaType.failure(e));
  }
}
export function* requestChangeTfaTypeWatcherSaga() {
  while (true) {
    yield take(ActionTypes.requestChangeTfaType.REQUEST);
    yield call(requestChangeTfaTypeSaga);
  }
}

export function* resetTfaTypeSaga(payload) {
  try {
    const { status, data } = yield call(api.resetTfaType, payload);
    if (status < 300) {
      yield put(ActionTypes.resetTfaType.success(data));
      yield call(getProfileSaga);
    } else {
      yield put(ActionTypes.resetTfaType.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.resetTfaType.failure(e));
  }
}
export function* resetTfaTypeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.resetTfaType.REQUEST);
    yield call(resetTfaTypeSaga, payload);
  }
}

export function* setTfaTypeSaga(payload) {
  try {
    const { status, data } = yield call(api.setTfaType, payload);
    if (status < 300) {
      yield put(ActionTypes.setTfaType.success(data));

      if (payload.otpType === 'email') {
        yield put(ActionTypes.setEmailVerifyStep());
      }
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.setTfaType.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.setTfaType.failure(e));
  }
}
export function* setTfaTypeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.setTfaType.REQUEST);
    yield call(setTfaTypeSaga, payload);
  }
}

export function* verifyTfaTypeSaga(payload) {
  try {
    const { status, data } = yield call(api.verifyTfaType, payload);
    if (status < 300) {
      yield put(ActionTypes.verifyTfaType.success(data));
      yield call(getProfileSaga);
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.verifyTfaType.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.verifyTfaType.failure(e));
  }
}
export function* verifyTfaTypeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.verifyTfaType.REQUEST);
    yield call(verifyTfaTypeSaga, payload);
  }
}

export function* verifyWithdrawalRequestSaga({ id, ...payload }) {
  try {
    const { status, data } = yield call(api.verifyWithdrawalRequest, id, payload);
    if (status < 300) {
      yield put(ActionTypes.verifyWithdrawalRequest.success(data));
      yield call(getPaymentsSaga, { id });
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.verifyWithdrawalRequest.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.verifyWithdrawalRequest.failure(e));
  }
}
export function* verifyWithdrawalRequestWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.verifyWithdrawalRequest.REQUEST);
    yield call(verifyWithdrawalRequestSaga, payload);
  }
}

export function* verifyWithdrawalResendSaga({ id }) {
  try {
    const { status, data } = yield call(api.verifyWithdrawalResend, id);
    if (status < 300) {
      yield put(ActionTypes.verifyWithdrawalResend.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.verifyWithdrawalResend.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.verifyWithdrawalResend.failure(e));
  }
}
export function* verifyWithdrawalResendWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.verifyWithdrawalResend.REQUEST);
    yield call(verifyWithdrawalResendSaga, payload);
  }
}

export default [
  fork(requestChangeTfaTypeWatcherSaga),
  fork(resetTfaTypeWatcherSaga),
  fork(setTfaTypeWatcherSaga),
  fork(verifyTfaTypeWatcherSaga),
  fork(verifyWithdrawalRequestWatcherSaga),
  fork(verifyWithdrawalResendWatcherSaga),
];
