import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { Tooltip } from 'common/components';
import { staticStyles, getDynamicStyles } from './style';

const LoginCell = ({ login, title, inTable, challenge, noColor, theme, noType, fullName, tooltipId }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <Tooltip id={tooltipId} textId={tooltipId && login && title && `${login} ${title}`} noTranslate>
      <div
        className={classNames('LoginCell__inner', {
          LoginCell__inTable: inTable,
          LoginCell__challenge: challenge,
          LoginCell__noColor: noColor,
          noType,
          fullName,
        })}>
        <span className="LoginCell__type">
          <i>{title[0]}</i>
        </span>
        {!!login && <span className="LoginCell__number">{login}</span>}
        <span className="LoginCell__name">{title}</span>

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    </Tooltip>
  );
};

const baseProps = {
  login: PropTypes.string,
  title: PropTypes.string.isRequired,
};

LoginCell.propTypes = {
  ...baseProps,
  inTable: PropTypes.bool,
  theme: PropTypes.object,
  tooltipId: PropTypes.string,
};

LoginCell.defaultProps = {
  inTable: false,
  theme: {},
  tooltipId: null,
};

const ThemedLoginCell = withTheme()(LoginCell);

ThemedLoginCell.propTypes = baseProps;

export default ThemedLoginCell;
export { LoginCell };
