import React, { useEffect, useMemo } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PageLayout, PageContentPreloader, NoItemsPanel, SearchPanel } from 'common/components';
import noInfo from 'common/images/noInfo.svg';
import { withTheme } from 'common/styling/theme';
import { getBackofficeHistory } from '../_redux';
import { HistoryTable } from '../_components';
import { staticStyles, getDynamicStyles } from './style';

const HistoryPage = ({ history, historyIsLoaded, getHistory, intl, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const location = useLocation();
  const routerHistory = useHistory();

  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const isSearchPanelShown = history.count || !!Object.keys(query).length;

  useEffect(() => {
    getHistory({ search: query });
  }, []);

  const eventTypesOptions = history?.event_types
    ? Object.entries(history.event_types).map(([value, event]) => ({ value, label: event }))
    : [];

  const filters = [
    {
      title: 'justAction',
      accessor: 'event_type',
      options: [{ value: '', label: intl.formatMessage({ id: 'justAll' }) }, ...eventTypesOptions],
      searchable: true,
    },
  ];

  const inputs = [
    {
      title: 'justPerformedBy',
      accessor: 'performed_by',
    },
  ];

  const dateRangeField = {
    title: 'justPeriod',
    queryStartDate: 'from_date',
    queryEndDate: 'to_date',
  };

  return (
    <PageLayout captionSlug="boHistory">
      <PageContentPreloader ready={historyIsLoaded} type="bigLine" firstLaunchOnly>
        {isSearchPanelShown && (
          <SearchPanel
            location={location}
            loading={!historyIsLoaded}
            history={routerHistory}
            getList={getHistory}
            filters={filters}
            inputs={inputs}
            dateRangeField={dateRangeField}
            params={{ search: query }}
          />
        )}
        {history.count ? (
          <HistoryTable
            data={history}
            loading={!historyIsLoaded}
            location={location}
            history={routerHistory}
            getList={getHistory}
            params={{ search: query }}
          />
        ) : (
          <NoItemsPanel
            icon={noInfo}
            captionSlug={Object.keys(query).length > 1 ? 'noHistoryMatchingTheParameters' : 'noHistoryYet'}
          />
        )}
        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </PageContentPreloader>
    </PageLayout>
  );
};

HistoryPage.propTypes = {
  history: PropTypes.object.isRequired,
  getHistory: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  historyIsLoaded: PropTypes.bool,
};

HistoryPage.defaultProps = {
  historyIsLoaded: false,
};

export default compose(
  withTheme(),
  injectIntl,
  connect(
    state => ({
      history: state.dashboards.boHistory,
      historyIsLoaded: state.dashboards.boHistoryIsLoaded,
    }),
    {
      getHistory: search => getBackofficeHistory.request(search),
    }
  )
)(HistoryPage);

export { HistoryPage };
