import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { DateCell, PaginationTable } from 'common/components';
import { injectIntl } from 'react-intl';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const HistoryTable = ({ data, loading, location, history, getList, className, params, intl, theme, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  /* eslint-disable */
  const columns = [
    {
      Header: () => null,
      id: 'avatar',
      Cell: ({ original: { user } }) => (user?.email ? <Gravatar md5={md5(user.email)} size={35} /> : null),
      isPreviewCell: true,
    },
    {
      key: 'justFullName',
      Cell: ({ original: { user } }) => (
        <span className="HistoryTable__fullName">{user?.fullName ? user.fullName : '—'}</span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justPerformedBy',
      Cell: ({ original: { performedBy } }) => (
        <span className="HistoryTable__performedBy">{performedBy?.fullName ? performedBy.fullName : '—'}</span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justAction',
      Cell: ({ original: { description } }) => <span className="HistoryTable__action">{description}</span>,
      isPreviewCell: true,
    },
    {
      key: 'justDate',
      accessor: 'at',
      Cell: ({ original: { at } }) => <DateCell value={at} />,
    },
  ];
  /* eslint-enable */

  return (
    <div className={classNames('HistoryTable', className)}>
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

HistoryTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  className: PropTypes.string,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

HistoryTable.defaultProps = {
  onRowClick: null,
  loading: false,
  className: '',
  theme: {},
};

export default compose(injectIntl, withTheme())(HistoryTable);
export { HistoryTable };
