import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .StartChallengeFormHERO10X__HowDoesWork__Container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .StartChallengeFormHERO10X {
    @media only screen and (min-width: 1000px) {
      width: 700px;
    }
  }

  .StartChallengeFormHERO10X__TopText {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    margin-bottom: 16px;
  }

  .StartChallengeFormHERO10X__RefundableFee {
    display: flex;
    align-items: center;
    gap: 12px;
    @media only screen and (min-width: 1200px) {
      grid-column: 1/1;
      grid-row: 1/1;
    }
  }

  .StartChallengeFormHERO10X__RefundableFee > span {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .StartChallengeFormHERO10X__RefundableFee > div {
    color: #0aa1e2;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .StartChallengeFormHERO10X__LeftText {
    color: rgba(0, 0, 0, 0.5);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    @media only screen and (min-width: 1200px) {
      grid-column: 1/1;
      grid-row: 2/2;
    }
  }

  .StartChallengeFormHERO10X__bottom {
    display: grid;
    row-gap: 8px;
    column-gap: 60px;
    @media only screen and (min-width: 1200px) {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
    }
  }

  .StartChallengeFormHERO10X__bottom > .DivWithDefButton {
    min-width: 200px;
    @media only screen and (min-width: 1200px) {
      grid-column: 2/2;
      grid-row: 1/3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .StartChallengeFormHERO10X__HowDoesWork__Container table {
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .StartChallengeFormHERO10X__HowDoesWork__Container table td {
    height: 40px;
    border: 1.5px solid black;
    text-align: center;
    align: center;
  }

  .StartChallengeFormHERO10X__HowDoesWork__Container table .firstChild {
    font-weight: 600;
  }
`;

export { staticStyles };
