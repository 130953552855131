import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PayCommissionAllForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(25px);

    .PayCommissionAllForm__line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: rem(10px 0);

      &:last-child {
        margin-top: rem(30px);
      }

      span,
      :global(.StatusCell),
      :global(.ReduxTextField) {
        flex: 1;
      }

      :global(.DefaultButton) {
        width: 140px;
        &:first-child {
          margin-right: rem(40px);

          @include respond-to(xs) {
            margin-right: rem(10px);
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .PayCommissionAllForm__title {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
