import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const getDynamicStyles = ({ colors }) => {
  return css`
    .Auth__message {
      background: ${rgba([colors.warning.rgb, 0.35])};
      padding: 15px 20px;
      margin: 25px 0 0;
    }

    .Auth__message_hint {
      opacity: 0.7;
      margin-top: 15px;
    }

    :global(.ReactModal__Content) {
      max-width: 620px !important;
      width: 100%;
    }
  `;
};

export { getDynamicStyles };
