import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { EditIcon } from 'common/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PageLayout, ActionsButton, DefaultButton, LoginCell, BasicModal } from 'common/components';

import { ChallengeItem } from 'client/accounts/_components';
import { ModalHOC } from 'common';

import AccountPageOnlyTradingTable from 'client/accounts/AccountPageOnlyTradingTable';

import { getTradingAccountList } from '../../../_redux/actions';

import {
  ChallengePageChart,
  ChangeRecieveRefundableFeeMethod,
  ManualWithdrawProfitFromChallengeForm,
} from '../../_components';
import { staticStyles } from './style';
import { getChallenge } from '../../_redux';
import CheckIcon from './CheckIcon';
import CrossIcon from './CrossIcon';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengePage = ({
  accounts = [],
  challenge,
  isWalletAccountAddonEnabled,
  getTradingAccountList,
  baseValue,
  challengeIsLoaded,
  getChallenge,
  showModal,
  history,
}) => {
  const currentWithdrawAccount = useMemo(() => {
    if (!challengeIsLoaded) {
      return null;
    }

    if (!challenge.withdrawAccount) {
      return null;
    }

    return challenge.withdrawAccount;
  }, [challenge, challengeIsLoaded]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  const { challengeId } = useParams();
  useEffect(() => {
    getChallenge(challengeId);
  }, []);

  const [refundFeeModal, setRefundFeeModal] = useState(false);
  const [manuallyWithdrawModal, setManuallyWithdrawModal] = useState(false);

  if (!challengeIsLoaded) {
    return null;
  }

  return (
    <PageLayout
      captionSlug={`${isHero ? '' : 'Challenge'} ${challenge.challenge?.title}`}
      showReload
      onReloadClick={() => {
        getChallenge(challengeId);
      }}
      reloading={false}>
      <div className="ChallengePageWrapper">
        <div className="ChallengePage__ChallengeConditions">
          <FM id={isHero ? 'justChallengeConditionsHERO10X' : 'justChallengeConditions'}>
            {txt => <h3 className="challenge-text">{txt}</h3>}
          </FM>

          <DefaultButton
            className="challenge-button"
            textId={isHero ? 'justAboutChallengesHERO10X' : 'justAboutChallenges'}
            onClick={() => {
              showModal({
                caption: { id: isHero ? 'justAboutChallengesHERO10X' : 'justAboutChallenges' },
                content: {
                  id: isHero ? 'justAboutChallengesTogetherHERO10X' : 'justAboutChallengesTogether',
                },
                actionButton: !isHero
                  ? {
                      className: 'challenge-button',
                      textId: 'justGoToChallengePage',
                      handler: () => {
                        history.push('/challenges/');
                      },
                    }
                  : null,
                cancelButton: {
                  textId: 'justClose',
                },
              });
            }}
          />
        </div>

        <div className="ChallengePage__Description">{challenge.challenge.description}</div>

        <table className="ChallengePage__Table" width="100%">
          <tr>
            <td>
              <FM id="justMinimalTradingDays" />
            </td>
            <td>
              <FM id={isHero ? 'justMaximumLoss' : 'justMaximumDailyLoss'} />
            </td>
            <td>
              <FM id="justMaximumLoss" />
            </td>

            <td className="displayNoneHero">
              <FM id="justProfitTarget" />
            </td>
          </tr>
          <tr>
            <td>{challenge.level.minimum_trading_days}</td>
            <td>{`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}</td>
            <td>{`${challenge.level.maximum_total_loss.amount} ${challenge.level.maximum_total_loss.currency}`}</td>
            <td className="displayNoneHero">{`${challenge.level.target.amount} ${challenge.level.target.currency}`}</td>
          </tr>
        </table>

        {challengeIsLoaded && <ChallengeItem activeChallenge={challenge} noRedirect />}

        <div className="ChallengePageChart__Under">
          <div className="ChallengePageChart__Item">
            <FM id={isHero ? 'justMaximumLoss' : 'justMaximumDailyLoss'} />
            <div className="ChallengePageChart__Value">
              {`${challenge.maximumDailyLoss?.amount} ${challenge.maximumDailyLoss?.currency}`}
              {challenge.maximumDailyLoss?.amount < challenge.level.maximum_daily_loss.amount ? (
                <CheckIcon />
              ) : (
                <CrossIcon />
              )}
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumTotalLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.maximumTotalLoss?.amount} ${challenge.maximumTotalLoss?.currency}`}
              {challenge.maximumTotalLoss?.amount > challenge.level.maximum_total_loss.amount ? (
                <CheckIcon />
              ) : (
                <CrossIcon />
              )}
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justTotalProfit" />
            <div className="ChallengePageChart__Value">
              {`${challenge.totalProfit.amount} ${challenge.totalProfit.currency}`}
              {+challenge.totalProfit.amount >= 0 ? <CheckIcon /> : <CrossIcon />}
            </div>
          </div>
        </div>
        {challenge?.challenge?.cost && (
          <>
            <div className="ChallengePageWrapper__RefundableFee">
              <FM id="justRefundableFee">{txt => <span>{txt}:</span>}</FM>
              <div>{challenge?.challenge?.cost ?? '---'}</div>
              <br />
            </div>

            <div
              className="ChallengePageWrapper__WhereToWithdraw"
              style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <FM
                id={
                  challenge.challenge.manualWithdraw
                    ? 'justRefundableFeeCanBeSentToYourAfterCompleteing'
                    : 'justRefundableFeeWillBeSentToYourAfterCompleteing'
                }
              />
              :
              {currentWithdrawAccount ? (
                <LoginCell
                  title={currentWithdrawAccount.accountTypeTitle}
                  login={currentWithdrawAccount.login}
                  challenge
                />
              ) : (
                <FM id="boWallet">{txt => <span className="RefundableFee--wallet">{txt}</span>}</FM>
              )}
              <ActionsButton
                tooltipId="Edit__tooltip"
                tooltipTextId="justEditChallengeWithdrawAccount"
                edit
                onClickFunc={() => setRefundFeeModal(true)}>
                <EditIcon />
              </ActionsButton>
            </div>
          </>
        )}
        {challenge.challenge.manualWithdraw && (
          <DefaultButton textId="justWithdrawProfit" onClick={() => setManuallyWithdrawModal(true)} />
        )}
      </div>

      {challengeIsLoaded && (
        <div className="ChallengePage__Chart">
          <ChallengePageChart chart={challenge.chart} challenge={challenge} />
        </div>
      )}
      {challengeIsLoaded && (
        <AccountPageOnlyTradingTable
          accountPlatformSlug={challenge.account.platformSlug}
          accountId={challenge.account.id}
        />
      )}
      <BasicModal
        isOpen={refundFeeModal}
        captionId="justEditChallengeWithdrawAccount"
        onRequestClose={() => setRefundFeeModal(false)}>
        <ChangeRecieveRefundableFeeMethod
          challengeId={challenge.id}
          accounts={accounts}
          toggleModal={() => setRefundFeeModal(false)}
          isWalletAccountAddonEnabled={isWalletAccountAddonEnabled}
          baseValue={baseValue}
        />
      </BasicModal>
      <BasicModal
        isOpen={manuallyWithdrawModal}
        captionId="justWithdrawProfit"
        onRequestClose={() => setManuallyWithdrawModal(false)}>
        <ManualWithdrawProfitFromChallengeForm
          userChallengeId={challenge.id}
          toggleModal={() => setManuallyWithdrawModal(false)}
        />
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

export default compose(
  withRouter,
  ModalHOC,
  connect(
    state => ({
      accounts: state.client.common.accounts,
      challenge: state.client.challenges.challenge,
      challengeIsLoaded: state.client.challenges.challengeIsLoaded,
      isWalletAccountAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'wallet'),
      baseValue: state.interfaceConfig.baseValue,
    }),
    {
      getTradingAccountList: () => getTradingAccountList.request(),
      getChallenge: id => getChallenge.request(id),
    }
  )
)(ChallengePage);
