import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ModalHOC } from 'common';
import { compose } from 'redux';

import { closeModal, openModal } from 'interface/user/_redux/actions';
import { BasicModal, Collapsible, DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';

import {
  getPaymentNotes,
  deletePaymentNote,
  removePaymentNoteDocument,
  getPaymentNoteDocument,
} from 'backoffice/_redux';
import { UserNoInfoPanel } from '../..';

import PaymentNote from '../PaymentNote';

import PaymentNoteCreateForm from '../PaymentNoteCreateForm/PaymentNoteCreateForm';
import PaymentNoteEditForm from '../PaymentNoteEditForm/PaymentNoteEditForm';
import { staticStyles, getDynamicStyles } from './style';

const PaymentNotes = ({
  id,
  paymentType,
  paymentNotes,
  paymentNotesAreLoaded,
  removePaymentNoteDocument,
  getPaymentNoteDocument,
  fullAccess,
  getPaymentNotes,
  deletePaymentNote,
  profile,
  modals,
  openModal,
  closeModal,
  showModal,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [noteId, setNoteId] = useState(null);

  const handleOpenNotes = () => {
    getPaymentNotes({ id, paymentType });
  };

  const handleDownloadNote = documentId => {
    getPaymentNoteDocument({ userId: profile.id, documentId });
  };

  const handleEditNote = noteId => {
    setNoteId(noteId);
    openModal('editNote');
  };

  const handleRemoveNoteDocument = documentId => {
    removePaymentNoteDocument({ userId: profile.id, id, documentId, paymentType });
  };

  const handleDeleteNote = noteId => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeleteNote',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deletePaymentNote({ id, noteId, paymentType }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  return (
    <div className="PaymentNotes">
      <Collapsible captionId="boNotes" handleOpen={handleOpenNotes}>
        <div className="PaymentNotes__content-inner">
          <div className="PaymentNotes__head">
            {(profile.perms.viewNotes || profile.perms.managerViewNotes) && (
              <DefaultButton onClick={() => openModal('createNote')} textId="createNote" plus filled />
            )}
          </div>
          <div className="PaymentNotes__cels">
            <FormattedMessage id="justAuthor" />
            <FormattedMessage id="justTitle" />
            <FormattedMessage id="justDate" />
            <span />
          </div>
          {paymentNotesAreLoaded && paymentNotes.length ? (
            paymentNotes.map(note => (
              <PaymentNote
                key={note.id}
                noteId={note.id}
                createdBy={note.createdBy}
                created={note.created}
                title={note.title}
                text={note.text}
                documents={note.documents}
                handleDownloadNote={handleDownloadNote}
                fullAccess={fullAccess}
                handleEditNote={handleEditNote}
                handleDeleteNote={handleDeleteNote}
                handleRemoveNoteDocument={handleRemoveNoteDocument}
              />
            ))
          ) : (
            <UserNoInfoPanel image={noInfoImage} textId="boNoNotes" />
          )}
        </div>
      </Collapsible>

      <BasicModal
        isOpen={modals.createNote}
        captionId="createPaymentNoteModalHeader"
        onRequestClose={() => closeModal('createNote')}>
        <PaymentNoteCreateForm id={id} paymentType={paymentType} onSubmitSuccess={() => closeModal('createNote')} />
      </BasicModal>

      <BasicModal
        isOpen={modals.editNote}
        captionId="editPaymentNoteModalHeader"
        onRequestClose={() => closeModal('editNote')}>
        <PaymentNoteEditForm
          id={id}
          noteId={noteId}
          paymentType={paymentType}
          onSubmitSuccess={() => closeModal('editNote')}
          initialValues={paymentNotes.find(note => note.id === noteId)}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

PaymentNotes.propTypes = {
  id: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  paymentNotes: PropTypes.array.isRequired,
  paymentNotesAreLoaded: PropTypes.bool.isRequired,
  fullAccess: PropTypes.bool.isRequired,
  getPaymentNotes: PropTypes.func.isRequired,
  removePaymentNoteDocument: PropTypes.func.isRequired,
  getPaymentNoteDocument: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deletePaymentNote: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

PaymentNotes.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      profile: state.user.profile,
      paymentNotes: state.backoffice.common.paymentNotes,
      paymentNotesAreLoaded: state.backoffice.common.paymentNotesAreLoaded,
      modals: state.user.modals,
    }),
    {
      getPaymentNoteDocument: data => getPaymentNoteDocument.request(data),
      removePaymentNoteDocument: data => removePaymentNoteDocument.request(data),
      getPaymentNotes: ({ id, paymentType }) => getPaymentNotes.request({ id, paymentType }),
      openModal: name => openModal(name),
      closeModal: name => closeModal(name),
      deletePaymentNote: data => deletePaymentNote.request(data),
    }
  )
)(PaymentNotes);
export { PaymentNotes };
