import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';

import DefaultButton from '../DefaultButton';

import { staticStyles, getDynamicStyles } from './style';

const NoItemsPanel = ({
  icon,
  captionSlug,
  descriptionSlug,
  buttonSlug,
  buttonPlus,
  onButtonClick,
  containerMaxHeight,
  className,
  captionSlugClassName,
  isError,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div
      className={classNames('NoItemsPanel', className, {
        NoItemsPanel__error: isError,
        'NoItemsPanel--ContainerMaxHeight': containerMaxHeight,
      })}>
      <div className="NoItemsPanel__inner">
        {icon && <img src={icon} alt="alt" />}
        {captionSlug && (
          <FormattedMessage id={captionSlug}>{txt => <h3 className={captionSlugClassName}>{txt}</h3>}</FormattedMessage>
        )}
        {descriptionSlug && <FormattedMessage id={descriptionSlug}>{txt => <p>{txt}</p>}</FormattedMessage>}
        {buttonSlug && <DefaultButton textId={buttonSlug} onClick={onButtonClick} filled plus={buttonPlus} />}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

NoItemsPanel.propTypes = {
  icon: PropTypes.string,
  captionSlug: PropTypes.string,
  descriptionSlug: PropTypes.string,
  buttonSlug: PropTypes.string,
  onButtonClick: PropTypes.func,
  className: PropTypes.string,
  containerMaxHeight: PropTypes.bool,
  isError: PropTypes.bool,
  buttonPlus: PropTypes.bool,
  theme: PropTypes.object,
};

NoItemsPanel.defaultProps = {
  icon: '',
  buttonSlug: '',
  captionSlug: '',
  descriptionSlug: '',
  onButtonClick: undefined,
  className: '',
  containerMaxHeight: false,
  isError: false,
  buttonPlus: false,
  theme: {},
};

const ThemedNoItemsPanel = withTheme()(NoItemsPanel);

export default ThemedNoItemsPanel;
export { NoItemsPanel };
