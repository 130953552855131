import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import md5 from 'blueimp-md5';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { withRouter } from 'react-router-dom';

import { ReloadIcon } from 'common/icons';

import { DateCell, DefaultButton, Tooltip } from 'common/components';

import { getUserPartnershipClients } from '../../api';

import { staticStyles, getDynamicStyles } from './style';

const InnerPartnershipUsersTable = ({
  colspan,
  userId,
  isShowEmail,
  isShowPhone,
  history,
  viewClientsUserTags,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [clients, setClients] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [maxDeepLevel, setMaxDeepLevel] = useState(0);
  const [maxCount, setMaxCount] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getClients = async id => {
    try {
      setLoading(true);
      const { data } = await getUserPartnershipClients(id, maxCount);
      setTotalCount(data.count);

      return data;
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getClientsAndSetData = () => {
    getClients(userId, maxCount).then(({ results }) => {
      const users = results.map(result => (result.isPartner ? { ...result, level: 2 } : result));
      setClients(users);
    });
  };

  const findClientByIdAndUpdate = (data, id, clients, level = 2) =>
    data.map(item => {
      if (item.id === id) {
        setMaxDeepLevel(level);
        return { ...item, level, clients };
      }

      if (item?.clients) {
        return { ...item, clients: findClientByIdAndUpdate(item.clients, id, clients, level + 1) };
      }

      return { ...item, level };
    });

  const handleRowClick = id => {
    setIsOpen(prev => ({ ...prev, [id]: !prev[id] }));

    if (!isOpen[id]) {
      getClients(id).then(({ results }) => {
        const users = findClientByIdAndUpdate(clients, id, results);
        setClients(users);
      });
    }
  };

  useEffect(() => {
    getClientsAndSetData();
  }, [maxCount]);

  const onRowClick = userId => {
    history.push(`/partnership/clients/user/${userId}/accounts/`);
  };
  /* eslint-disable */
  const renderRow = ({ id, fullName, email, phone, dateJoined, isPartner, clients, level, tags }) => (
    <div
      key={id}
      className={classNames('InnerPartnershipUsersTable__wrapper', {
        'InnerPartnershipUsersTable__wrapper--max-level': level > 6,
      })}>
      <div
        onClick={() => {
          onRowClick(id);
        }}
        className={classNames('InnerPartnershipUsersTable__row', {
          'InnerPartnershipUsersTable__row--with-field': (isShowEmail && !isShowPhone) || (!isShowEmail && isShowPhone),
          'InnerPartnershipUsersTable__row--short': !isShowEmail && !isShowPhone,
        })}>
        <div className="InnerPartnershipUsersTable__cell">
          <div className="InnerPartnershipUsersTable__user">
            {isShowEmail && <Gravatar md5={md5(email)} size={35} />}
            <span>{fullName}</span>
          </div>
        </div>
        <div className="InnerPartnershipUsersTable__cell">
          <span>{isPartner ? `Sub_IB level ${level ?? maxDeepLevel + 1}` : 'Client'}</span>
        </div>
        {isShowEmail && (
          <div className="InnerPartnershipUsersTable__cell">
            <span>{email ?? '一'}</span>
          </div>
        )}
        {isShowPhone && (
          <div className="InnerPartnershipUsersTable__cell">
            <span>{phone ?? '一'}</span>
          </div>
        )}
        <div className="InnerPartnershipUsersTable__cell">
          <DateCell value={dateJoined} />
        </div>
        {viewClientsUserTags && (
          <div className="PartnershipUsersTable__tags" style={{ marginTop: '15px' }}>
            {tags.map((tag, index) => (
              <Tooltip id={`UserTag__${index}`} textId={tag.description} key={index} noTranslate place="bottom">
                <div className="UserTagsPanelClient__tag">
                  <p>{tag.name}</p>
                </div>
              </Tooltip>
            ))}
          </div>
        )}
        <div className="InnerPartnershipUsersTable__cell">
          {isPartner && (
            <DefaultButton
              textId={isOpen[id] ? 'justHideClients' : 'justShowClients'}
              type="button"
              noBorder
              onClick={e => {
                e.stopPropagation();
                handleRowClick(id);
              }}
            />
          )}
        </div>
      </div>

      {clients && isOpen[id] && clients.map(renderRow)}
    </div>
  );

  return (
    <td
      key={`Custom-table-InnerPartnershipUsersTable-${userId}`}
      colSpan={colspan}
      className="InnerPartnershipUsersTable">
      {clients.length ? (
        <div className="InnerPartnershipUsersTable__list">
          <div className="InnerPartnershipUsersTable__client">{clients.map(renderRow)}</div>
          {totalCount >= maxCount && (
            <div className="InnerPartnershipUsersTable__load">
              <button
                className={classNames('InnerPartnershipUsersTable__load-btn', { loading })}
                type="button"
                onClick={() => setMaxCount(prev => prev + 10)}
                disabled={loading}>
                <ReloadIcon />
              </button>
            </div>
          )}
        </div>
      ) : (
        <FormattedMessage id="justNoClients">
          {txt => <div className="InnerPartnershipUsersTable__no-clients">{txt}</div>}
        </FormattedMessage>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </td>
  );
};

InnerPartnershipUsersTable.propTypes = {
  colspan: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isShowEmail: PropTypes.bool.isRequired,
  isShowPhone: PropTypes.bool.isRequired,
  history: PropTypes.object,
  theme: PropTypes.object,
};
InnerPartnershipUsersTable.defaultProps = {
  theme: {},
  history: {},
};

export default withTheme()(withRouter(InnerPartnershipUsersTable));
