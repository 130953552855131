import queryString from 'query-string';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BasicModal, NoItemsPanel, PageContentPreloader, PageLayout, SearchPanel } from 'common/components';
import { useLocation, useHistory } from 'react-router-dom';
import noPayments from 'common/images/noPayments.svg';
import { getWalletAccountsList } from 'client/multiWallet/_redux';
import { getTradingAccountList } from '../../../_redux';
import { InvestRatingTable } from '../../_components';
import InvestStrategyForm from '../../_components/InvestStrategyForm';
import { staticStyles } from './style';
import { getInvestStrategiesNew } from '../../_redux';

const InvestRatingPageNew = ({
  profile,
  investStrategiesNew,
  investStrategiesNewAreLoaded,
  getInvestStrategiesNew,
  getTradingAccountList,
  accounts,
  getWalletAccounts,
  walletAccounts,
  investAgreements,
  addons,
}) => {
  console.log({
    profile,
    investStrategiesNew,
    investStrategiesNewAreLoaded,
    getInvestStrategiesNew,
    getTradingAccountList,
    accounts,
    addons,
  });
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  console.log({ investStrategiesNew, investStrategiesNewAreLoaded, getInvestStrategiesNew });
  const getList = useCallback(
    query => getInvestStrategiesNew({ requestType: 'all', ...query }),
    [getInvestStrategiesNew]
  );
  const [investModal, setInvestModal] = useState({ isOpen: false, payload: {} });
  const toggleInvestModal = (strategyId, strategyCurrency, accountId, minInvestmentAmount) =>
    setInvestModal({
      isOpen: !investModal.isOpen,
      payload: {
        strategyId,
        strategyCurrency,
        accountId,
        minInvestmentAmount,
      },
    });

  useEffect(() => {
    getList({ search: query });
  }, [getList, query]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  useEffect(() => {
    if (addons.includes('walletAccounts')) {
      getWalletAccounts();
    }
  }, [getWalletAccounts]);

  const handleRowClick = (_rowId, rowInfo) => {
    const strategyId = rowInfo?.original?.id ?? rowInfo.id;
    history.push(`/investNew/${strategyId}`);
  };

  const handleSubmitSuccess = () => {
    toggleInvestModal();
    getList({ search: query });
  };
  const justInvestNew = 'justInvest';
  const investRatingNew = 'investRatingNew';

  return (
    <PageLayout externalClass="Algotrading" captionSlug={investRatingNew} showReload={false}>
      <PageContentPreloader ready={investStrategiesNewAreLoaded} type="bigLine" firstLaunchOnly>
        <SearchPanel location={location} history={history} getList={getList} params={{ search: query }} />
        {investStrategiesNew.count ? (
          <InvestRatingTable
            data={investStrategiesNew}
            loading={!investStrategiesNewAreLoaded}
            location={location}
            history={history}
            getList={getList}
            toggleInvestModal={toggleInvestModal}
            onRowClick={handleRowClick}
            params={{
              search: query,
            }}
          />
        ) : (
          <NoItemsPanel icon={noPayments} captionSlug="noStrategiesMatchingTheParameters" />
        )}
        <BasicModal captionId={justInvestNew} isOpen={investModal.isOpen} onRequestClose={toggleInvestModal}>
          <InvestStrategyForm
            strategyId={investModal.payload.strategyId}
            strategyAccountId={investModal.payload.accountId}
            strategyCurrency={investModal.payload.strategyCurrency}
            minInvestmentAmount={investModal.payload.minInvestmentAmount}
            investAgreements={investAgreements}
            accounts={accounts}
            walletAccounts={walletAccounts}
            walletBalance={profile.walletBalance}
            isWallet={addons.includes('wallet')}
            isMultiWallet={addons.includes('walletAccounts')}
            isRollover={investModal.payload.isRollover}
            tpslInRollovers={investModal.payload.tpslInRollovers}
            afterSubmitSuccess={handleSubmitSuccess}
          />
        </BasicModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

InvestRatingPageNew.propTypes = {
  profile: PropTypes.object.isRequired,
  investStrategiesNew: PropTypes.object.isRequired,
  investStrategiesNewAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategiesNew: PropTypes.func.isRequired,
  getTradingAccountList: PropTypes.func.isRequired,
  getWalletAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  addons: PropTypes.array.isRequired,
  walletAccounts: PropTypes.array,
  investAgreements: PropTypes.array.isRequired,
};

InvestRatingPageNew.defaultProps = {
  walletAccounts: [],
};

export default connect(
  state => ({
    profile: state.user.profile,
    investStrategiesNew: state.client.investNew.investStrategiesNew,
    investStrategiesNewAreLoaded: state.client.investNew.investStrategiesNewAreLoaded,
    accounts: state.client.common.accounts.filter(
      account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
    ),
    walletAccounts: state.client.multiWallet.walletAccounts,
    investAgreements: state.interfaceConfig.invest_crm.agreements,
    addons: state.interfaceConfig.addons,
  }),
  {
    getInvestStrategiesNew: data => getInvestStrategiesNew.request(data),
    getTradingAccountList: () => getTradingAccountList.request(),
    getWalletAccounts: () => getWalletAccountsList.request(),
  }
)(InvestRatingPageNew);
