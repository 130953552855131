import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { ReduxTextField, RadioButton, DefaultButton, MoneyCell, ReduxSelectField } from 'common/components';

import { setInvestmentsDepositNew } from 'client/investNew/_redux';

import staticStyles from './style';

const InvestmentDepositFormNew = ({
  accounts,
  walletAccounts,
  strategyId,
  strategyAccountId,
  strategyCurrency,
  isWallet,
  isMultiWallet,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const [investFrom, setInvestFrom] = useState('account');
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState('');
  const [exchangeCoefficient, setExchangeCoefficient] = useState(1);
  const [amountToInvest, setAmountToInvest] = useState(null);
  const [availableAmount, setAvailableAmount] = useState('');

  useEffect(() => {
    if (investFrom === 'wallet' && !isMultiWallet) {
      axios
        .get('/internal_transfers/transfer/invest_crm_from_wallet/', {
          params: { recipientAccount: strategyAccountId },
        })
        .then(({ data }) => {
          setAvailableAmount(data.availableAmount);
          setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
        });
    }
  }, [investFrom]);

  const submit = handleSubmit(values =>
    setInvestmentsDepositNew(
      {
        strategy: strategyId,
        account: values.account,
        amount: {
          amount: values.amount,
          currency: selectedAccountCurrency || 'USD',
        },
      },
      dispatch
    )
  );

  const handleChangeAccount = value => {
    const selectedAccount = accounts.find(account => account.id === value);
    setSelectedAccountCurrency(selectedAccount.balance.currency);
    axios
      .get('/internal_transfers/transfer/invest_crm/', {
        params: { senderAccount: selectedAccount.id, recipientAccount: strategyAccountId },
      })
      .then(({ data }) => {
        setAvailableAmount(data.availableAmount);
        setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
      });
  };

  const handleChangeWalletAccount = value => {
    const selectedAccount = walletAccounts.find(account => account.id === value);
    setSelectedAccountCurrency(selectedAccount.balance.currency);
    axios
      .get('/internal_transfers/transfer/invest_crm_from_wallet_account/', {
        params: { senderAccount: selectedAccount.id, recipientAccount: strategyAccountId },
      })
      .then(({ data }) => {
        setAvailableAmount(data.availableAmount);
        setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
      });
  };

  return (
    <div className="InvestmentDepositForm__content">
      <form className="InvestmentDepositForm" onSubmit={submit}>
        <div className="InvestmentDepositForm__inputs-inner">
          <Field
            component={ReduxTextField}
            type="text"
            name="strategy"
            textId="justStrategy"
            validate={[required()]}
            disabled
          />

          {(isWallet || isMultiWallet) && (
            <>
              <div>
                <RadioButton
                  name="investFrom"
                  textId="newDepositFromAccount"
                  value="all"
                  onChange={() => setInvestFrom('account')}
                  checked={investFrom === 'account'}
                />
                <p style={{ visibility: 'hidden' }} className="InvestStrategyForm__sub">
                  <FormattedMessage id="rammWithdrawCloseStrategyDescNew" />
                </p>
              </div>
              <div>
                <RadioButton
                  name="investFrom"
                  textId="newDepositFromWallet"
                  value="all"
                  onChange={() => setInvestFrom('wallet')}
                  checked={investFrom === 'wallet'}
                />
                <p style={{ visibility: 'hidden' }} className="InvestStrategyForm__sub">
                  <FormattedMessage id="rammWithdrawCloseStrategyDescNew" />
                </p>
              </div>
            </>
          )}

          {investFrom === 'account' && (
            <Field
              component={ReduxSelectField}
              name="account"
              maxMenuHeight={140}
              textId="justAccount"
              options={accounts.map(account => ({
                value: account.id,
                label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
              }))}
              disabled={submitting}
              validate={[required()]}
              onChange={handleChangeAccount}
              searchable
            />
          )}

          {investFrom === 'wallet' && isMultiWallet && (
            <Field
              component={ReduxSelectField}
              name="account"
              maxMenuHeight={140}
              textId="justWalletTitle"
              options={walletAccounts.map(account => ({
                value: account.id,
                label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
              }))}
              disabled={submitting}
              validate={[required()]}
              onChange={handleChangeWalletAccount}
              searchable
            />
          )}

          <Field
            component={ReduxTextField}
            type="number"
            name="amount"
            textId="justAmount"
            validate={[numericality({ '>=': 0, '<=': availableAmount }), required()]}
            disabled={submitting}
            onChange={newValue => setAmountToInvest(Number(newValue))}
            description={investFrom === 'wallet' && !isMultiWallet ? 'USD' : selectedAccountCurrency}
          />
        </div>

        {investFrom === 'wallet' && !isMultiWallet && (
          <div className="InvestmentDepositForm__desc">
            <FormattedMessage id="rammAccountAmount" />
            <MoneyCell value={{ amount: availableAmount, currency: 'USD' }} />
          </div>
        )}

        {typeof amountToInvest === 'number' && amountToInvest !== 0 && (
          <div className="InvestmentDepositForm__desc">
            <FormattedMessage id="clinetYouWillInvestAround" />
            <MoneyCell value={{ amount: amountToInvest * exchangeCoefficient, currency: strategyCurrency }} />
          </div>
        )}
        <div className="InvestmentDepositForm__btns">
          <DefaultButton
            textId="justSubmit"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
        {error && <strong className="formError">{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestmentDepositFormNew.propTypes = {
  accounts: PropTypes.array.isRequired,
  isWallet: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  strategyAccountId: PropTypes.number.isRequired,
  strategyCurrency: PropTypes.string.isRequired,
  error: PropTypes.string,
  walletAccounts: PropTypes.array,
  isMultiWallet: false,
};

InvestmentDepositFormNew.defaultProps = {
  walletAccounts: [],
  isMultiWallet: false,
  error: '',
};

export default reduxForm({
  form: 'InvestmentDepositFormNew',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(InvestmentDepositFormNew);
