import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserNotes__tabs {
    margin-bottom: rem(30px);

    @include respond-to(sm) {
      width: 100%;
    }
  }

  .UserNote__tab {
    font-size: 18px;
    line-height: 23px;
    margin-right: ${isRTL ? '0' : '35px'};
    margin-left: ${isRTL ? '35px' : '0'};
    padding-bottom: 15px;
    cursor: pointer;
    border-bottom: 3px solid transparent;

    &:last-child {
      margin-right: 0;
    }

    @include respond-to(sm) {
      font-size: 15px;
      line-height: normal;
      padding-bottom: 5px;
      border-bottom-width: 2px;
      margin-right: ${isRTL ? '0' : '15px'};
      margin-left: ${isRTL ? '15px' : '0'};
    }
  }

  .UserNotes__cels {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr)) minmax(100px, 0.3fr) 0.5fr minmax(100px, 0.2fr);
    margin: rem(10px 20px);

    @include respond-to(sm) {
      display: none;
    }

    :global(span) {
      font-size: rem(11px);
      line-height: rem(18px);
      text-transform: uppercase;
      padding: rem(0 5px);
    }
  }

  .UserNotes__head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(25px);
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserNotes__cels {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }

    .UserNote__tab {
      &.active {
        color: ${colors.primary.hex};
        border-bottom-color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
