import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader } from 'common/components';
import { WalletPayments, WalletInfoBlock } from 'client/multiWallet/_components';
import { getWalletAccountInOutList, getWalletAccountInternalTransfersList } from 'client/multiWallet/_redux';
import { getTradingAccount } from 'client/_redux';

import { staticStyles } from './style';

const MultiWalletDetailPage = ({
  walletAccount,
  walletAccountIsLoaded,
  getWalletAccount,
  getInOutList,
  getInternalTransferList,
  inOutList,
  inOutListIsLoaded,
  internalTransferList,
  internalTransferListIsLoaded,
}) => {
  const { operation, id } = useParams();

  useEffect(() => {
    getWalletAccount(id);
  }, [getWalletAccount, id]);

  const getList = data => {
    if (operation !== 'internalTransfers') getInOutList(data);
    else getInternalTransferList(data);
  };

  const handleReload = () => {
    getWalletAccount();
  };

  return (
    <PageLayout
      captionSlug="justMulticurrencyWalletTitle"
      captionValues={{ currency: walletAccount?.currency || '' }}
      showReload
      reloading={!walletAccountIsLoaded}
      onReloadClick={handleReload}>
      <PageContentPreloader ready={walletAccountIsLoaded} type="bigLine" firstLaunchOnly>
        <WalletInfoBlock
          walletLogin={walletAccount?.login}
          balance={walletAccount?.balance}
          currency={walletAccount?.currency}
        />
        <WalletPayments
          getList={getList}
          paymentsData={inOutList}
          transfersData={internalTransferList}
          paymentsLoading={inOutListIsLoaded}
          transfersLoading={internalTransferListIsLoaded}
          isDetailPage
        />
        <style jsx>{staticStyles}</style>
      </PageContentPreloader>
    </PageLayout>
  );
};

MultiWalletDetailPage.propTypes = {
  getWalletAccount: PropTypes.func.isRequired,
  getInOutList: PropTypes.func.isRequired,
  getInternalTransferList: PropTypes.func.isRequired,
  inOutList: PropTypes.object.isRequired,
  inOutListIsLoaded: PropTypes.bool.isRequired,
  internalTransferList: PropTypes.object.isRequired,
  internalTransferListIsLoaded: PropTypes.bool.isRequired,
  walletAccount: PropTypes.object,
  walletAccountIsLoaded: PropTypes.bool,
};

MultiWalletDetailPage.defaultProps = {
  walletAccount: {},
  walletAccountIsLoaded: false,
};

export default compose(
  connect(
    state => ({
      walletAccount: state.client.common.currentAccount,
      walletAccountIsLoaded: state.client.common.currentAccountIsLoaded,
      inOutList: state.client.multiWallet.walletAccountInOutList,
      inOutListIsLoaded: state.client.multiWallet.walletAccountInOutListIsLoaded,
      internalTransferList: state.client.multiWallet.walletAccountInternalTransfersList,
      internalTransferListIsLoaded: state.client.multiWallet.walletAccountInternalTransfersListIsLoaded,
    }),
    {
      getWalletAccount: id => getTradingAccount.request(id),
      getInOutList: data => getWalletAccountInOutList.request(data),
      getInternalTransferList: data => getWalletAccountInternalTransfersList.request(data),
    }
  )
)(MultiWalletDetailPage);
