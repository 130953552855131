import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { getChallenges, getUserChallenges } from 'client/challenges/_redux';

import { DefaultButton } from 'common/components';
import NoChallengesPanel from 'client/_components/NoChallengesPanel';
import { ChallengeHistoryItem } from './_components';

import { staticStyles } from './style';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengesHistoryBlock = ({
  getChallenges,
  userChallenges,
  userChallengesAreLoaded,
  getUserChallenges,
  isAccountsPage,
}) => {
  useEffect(() => {
    if (isAccountsPage) {
      getChallenges();
      getUserChallenges();
    }
  }, [getChallenges, getUserChallenges]);
  return (
    <div className="ChallengesHistoryBlock">
      <div className="ChallengesHistoryBlock__head">
        <FormattedMessage id="justArchivedChallenges">{txt => <h3>{txt}</h3>}</FormattedMessage>
        <div className="ChallengesHistoryBlock__head-right-buttons">
          {isAccountsPage && (
            <DefaultButton
              className="challenge-button"
              textId={isHero ? 'justAboutChallengesHERO10X' : 'justAboutChallenges'}
            />
          )}
        </div>
      </div>
      <div className="ChallengesHistoryBlock__body">
        {!userChallenges.filter(({ status }) => status !== 'active').length && (
          <NoChallengesPanel textId={isHero ? 'justNoActiveChallengesPangelHERO10X' : 'justNoActiveChallengesPangel'} />
        )}
      </div>
      {userChallengesAreLoaded &&
        userChallenges
          .filter(({ status }) => status !== 'active')
          .map(archiveChallenge => (
            <ChallengeHistoryItem key={`${archiveChallenge.name} archive`} archiveChallenge={archiveChallenge} />
          ))}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(
  withTheme(),
  connect(
    state => ({
      challenges: state.client.challenges.challenges,
      challengesAreLoaded: state.client.challenges.challengesAreLoaded,
      userChallenges: state.client.challenges.userChallenges,
      userChallengesAreLoaded: state.client.challenges.userChallengesAreLoaded,
    }),
    {
      getChallenges: () => getChallenges.request(),
      getUserChallenges: () => getUserChallenges.request(),
    }
  )
)(ChallengesHistoryBlock);
