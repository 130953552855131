import React, { useState, useMemo, useEffect } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { ActionsButton, DefaultButton } from '../index';

import { staticStyles, getDynamicStyles } from './style';

const pageSizeDelimiters = [5, 10, 20, 25, 50, 100];

const Pagination = ({ getList, count, params, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const history = useHistory();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const [pageSize, setPageSize] = useState(query.page_size || 10);
  const [currentPage, setCurrentPage] = useState(query.page || 1);

  const pagesCount = useMemo(() => Math.ceil(count / pageSize), [count, pageSize]);
  const currentPageInputLength = useMemo(() => {
    const pagesCountLength = pagesCount.toString().length;
    if (pagesCountLength < 3) {
      return '33px';
    }
    return `${pagesCountLength * 11}px`;
  }, [pagesCount]);

  const handleChange = (queryName, pageParam) => {
    history.replace({
      ...location,
      search: queryString.stringify({
        ...query,
        [queryName]: pageParam,
      }),
    });

    getList({
      search: { page_size: pageSize, ...query, [queryName]: pageParam },
      ...params,
    });

    if (queryName === 'page') {
      setCurrentPage(pageParam);
    } else if (queryName === 'page_size') {
      setCurrentPage(1);
      setPageSize(pageParam);
    }
  };

  const handleInputChange = ({ target }) => {
    if (target.value === '' || (target.value >= 1 && target.value <= pagesCount)) {
      setCurrentPage(target.value);
    }
  };

  // If page is too high -> show last page
  if (query.page > pagesCount) {
    history.replace({
      ...location,
      search: queryString.stringify({ ...query, page: pagesCount }),
    });
  }

  useEffect(() => {
    if (pagesCount === 0) {
      setCurrentPage(0);
    } else if (pagesCount !== 0 && currentPage <= 0) {
      setCurrentPage(1);
    }
  }, [setCurrentPage, pagesCount, currentPage]);

  return (
    <div className="Pagination">
      <div className="Pagination__select-wrap">
        <select
          onChange={({ target }) => handleChange('page_size', target.value)}
          value={pageSize}
          className="Pagination__select">
          {pageSizeDelimiters.map(delimiter => (
            <option value={delimiter} key={delimiter}>
              {delimiter}{' '}
            </option>
          ))}
        </select>
      </div>
      <div className="Pagination__pagination-nav">
        <div className="Pagination__nav-info">
          {isTabletOrMobile && (
            <>
              <ActionsButton
                onClickFunc={() => handleChange('page', Number(currentPage) - 1)}
                disabled={currentPage <= 1}
              />
              <ActionsButton
                onClickFunc={() => handleChange('page', Number(currentPage) + 1)}
                disabled={currentPage >= pagesCount}
              />
            </>
          )}
          <FormattedMessage id="justPage">{txt => <span>{txt}</span>}</FormattedMessage>
          <input
            className="Pagination__nav-input"
            type="number"
            style={{ width: currentPageInputLength }}
            value={currentPage}
            onChange={handleInputChange}
            onBlur={() => handleChange('page', currentPage)}
          />
          <FormattedMessage id="justOf">
            {txt => (
              <span>
                {txt} {pagesCount}
              </span>
            )}
          </FormattedMessage>
        </div>
        {!isTabletOrMobile && (
          <>
            <DefaultButton
              textId="justPrevious"
              onClick={() => handleChange('page', Number(currentPage) - 1)}
              disabled={currentPage <= 1}
              previous
            />
            <DefaultButton
              textId="justNext"
              onClick={() => handleChange('page', Number(currentPage) + 1)}
              disabled={currentPage >= pagesCount}
              next
            />
          </>
        )}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

Pagination.propTypes = {
  getList: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  params: PropTypes.object,
  theme: PropTypes.object,
};

Pagination.defaultProps = {
  params: {},
  theme: {},
};

export default withTheme()(Pagination);
