import { fork, put, call, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

function* getAdminEventListSaga({ search }) {
  try {
    const { data } = yield call(api.getAdminEventList, search);
    yield put(ActionTypes.getAdminEventList.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAdminEventList.failure(e));
  }
}

function* getaAdminEventListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_ADMIN_EVENT_LIST[REQUEST]);
    yield call(getAdminEventListSaga, data);
  }
}

export default [fork(getaAdminEventListWatcherSaga)];
