import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { required, email, length } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { DefaultButton, ReduxTextField, CheckboxField } from 'common/components';
import { withTheme } from 'common/styling/theme';
import TelegramAuthWidget from '../../../_components/TelegramAuthWidget';

import { changeAuthStep, login, loginWithTelegram, resetAuth, setAuthorized } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const LoginForm = ({
  setRememberUser,
  loginWithTelegram,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  authError,
  error,
  dispatch,
  isTelegramAuth,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const telegramDataJson = sessionStorage.getItem('telegramData');
  const telegramData = JSON.parse(telegramDataJson || '{}');
  const [autofilled, setAutofilled] = useState(false);
  const [ifDisabled, setIfDisabled] = useState(true);

  const submit = handleSubmit(formValues =>
    login({ ...formValues, ...(isTelegramAuth ? telegramData : {}) }, dispatch)
  );

  const handleTelegramAuth = data => loginWithTelegram(data);

  const handleRememberUserChange = e => {
    setRememberUser(e.target.checked);
  };

  useEffect(() => {
    setIfDisabled(true);
    if (autofilled === 'onAutoFillStart') {
      setIfDisabled(false);
    }
  }, [autofilled]);

  return (
    <form id="main-login-form" className="LoginForm" onSubmit={submit}>
      <div className="LoginForm__inputs-inner">
        <Field
          component={ReduxTextField}
          type="email"
          name="emailOrPhone"
          textId="authEmail"
          validate={[required(), email()]}
          disabled={submitting}
          onAutofill={setAutofilled}
        />
        <Field
          component={ReduxTextField}
          type="password"
          name="password"
          textId="authPassword"
          validate={[required(), length(6)]}
          disabled={submitting}
          onAutofill={setAutofilled}
        />
        <Field
          component={CheckboxField}
          name="rememberMe"
          disabled={submitting}
          textId="rememberMe"
          onChange={handleRememberUserChange}
        />
      </div>
      <div className="ForgotPass__inner">
        <FormattedMessage id="authForgotPassword">
          {txt => <Link to="/auth/password-reset">{txt}</Link>}
        </FormattedMessage>
        <FormattedMessage id="authNoEmailConfirmation">
          {txt => <Link to="/auth/resend-email-confirmation">{txt}</Link>}
        </FormattedMessage>
      </div>
      <DefaultButton
        id="login-page-sign-in-button"
        textId="authDoLogin"
        type="submit"
        loading={submitting}
        filled
        disabled={ifDisabled ? invalid || pristine || submitting : false}
      />
      <TelegramAuthWidget handleTelegramAuth={handleTelegramAuth} />
      {pristine && authError && <span className="formError">{authError}</span>}
      {error && <strong className="formError">{error}</strong>}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </form>
  );
};

LoginForm.propTypes = {
  setRememberUser: PropTypes.func.isRequired,
  loginWithTelegram: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  authError: PropTypes.string,
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isTelegramAuth: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

LoginForm.defaultProps = {
  authError: '',
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(null, {
    loginWithTelegram: data => loginWithTelegram.request(data),
  }),
  reduxForm({
    form: 'login',
    onSubmitSuccess: (result, dispatch, { history }) => {
      dispatch(reset('login'));

      if (result === 'success') {
        dispatch(resetAuth(''));
        dispatch(setAuthorized());
        history.push('/');
      } else {
        dispatch(changeAuthStep(result));
      }
    },
  })
)(LoginForm);
export { LoginForm };
