import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import {
  PageLayout,
  NoItemsPanel,
  PageContentPreloader,
  SearchPanel,
  TransfersTable,
  DefaultButton,
  DateRangeField,
  BaseSelectField,
  BasicModal,
} from 'common/components';
import { withTheme } from 'common/styling/theme';

import noPaymentsImage from 'common/images/noPayments.svg';

import { createBackOfficePaymentExport } from 'backoffice/payments/_redux';
import Checkbox from 'backoffice/partnership/_components/Checkbox';

import { getInternalTransfers } from '../_redux';
import { staticStyles, getDynamicStyles } from './style';

const yearsOptions = [...Array(10).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const InternalTransfersPage = ({
  internalTransfers,
  internalTransfersAreLoaded,
  getInternalTransfers,
  createBackOfficePaymentExport,
  hasPermsToExport,
  history,
  location,
  intl,
  features,
  theme,
}) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  /* eslint-disable */
  useEffect(() => {
    getInternalTransfers({ search: query });
  }, [getInternalTransfers]);
  /* eslint-enable */

  const getReloadItems = () => {
    getInternalTransfers({ search: query });
  };

  const handleRowClick = id => history.push(`/backoffice/transfers/${id}/`);
  const hasPaymentOperationManagers = features.includes('paymentOperationManager');

  const filters = [
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'IN_PROGRESS', label: intl.formatMessage({ id: 'status_in_progress' }) },
        { value: 'WAITING_FOR_APPROVAL', label: intl.formatMessage({ id: 'status_waiting_for_approval' }) },
        { value: 'COMPLETED', label: intl.formatMessage({ id: 'status_completed' }) },
        { value: 'CANCELED', label: intl.formatMessage({ id: 'status_canceled' }) },
      ],
    },
    {
      title: 'boTransferType',
      accessor: 'transferType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'INNER', label: intl.formatMessage({ id: 'boInnerTransfer' }) },
        { value: 'TO_TRADER', label: intl.formatMessage({ id: 'boAnotherTrader' }) },
      ],
    },
  ];

  const pushToExportsFunc = () => history.push(`/backoffice/transfers/exports`);

  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('');
  const [exportExt, setExportExt] = useState(null);
  const [onlyExecutedChecked, setOnlyExecutedChecked] = useState(false);

  useEffect(() => {
    if (isExportMenuModalOpen) {
      setSelectedTimePeriod(
        `${moment(moment().add(-7, 'day').startOf('day')).format('YYYY-MM-DD')}_${moment(
          moment().startOf('day')
        ).format('YYYY-MM-DD')}`
      );
    }
  }, [isExportMenuModalOpen]);

  const handeToggleOnlyExecutedField = () => {
    setOnlyExecutedChecked(checked => !checked);
  };

  return (
    <PageLayout
      captionSlug="boInternalTransfers"
      showReload
      onReloadClick={getReloadItems}
      reloading={!internalTransfersAreLoaded}>
      <PageContentPreloader ready={internalTransfersAreLoaded} type="smallLines" firstLaunchOnly>
        {!internalTransfers.count && !location.search ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getInternalTransfers}
            filters={filters}
            params={{ search: query }}
          />
        )}
        {internalTransfers.count ? (
          <>
            {hasPermsToExport && (
              <div className="InternalTransfer__export-buttons">
                <DefaultButton type="button" textId="showInternalTransfersExportList" onClick={pushToExportsFunc} />
                <div className="InternalTransfer__export-buttons--select">
                  <BaseSelectField
                    name="import"
                    textId="justExport"
                    options={[
                      { value: 'xlsx', label: 'Export in xlsx' },
                      { value: 'csv', label: 'Export in csv' },
                    ]}
                    onChange={option => {
                      setExportExt(option.value);
                      setIsExportMenuModalOpen(true);
                    }}
                  />
                </div>
              </div>
            )}
            <TransfersTable
              pageLink="backoffice/transfers"
              data={internalTransfers}
              loading={!internalTransfersAreLoaded}
              location={location}
              history={history}
              getList={getInternalTransfers}
              onRowClick={handleRowClick}
              params={{ search: query }}
              hasPaymentOperationManagers={hasPaymentOperationManagers}
            />
            <BasicModal
              captionId="selectTimePeriodOfExport"
              isOpen={isExportMenuModalOpen}
              onRequestClose={() => setIsExportMenuModalOpen(false)}>
              <DateRangeField
                yearsOptions={yearsOptions}
                input={{ value: selectedTimePeriod, onChange: setSelectedTimePeriod }}
                meta={{ touched: true, error: false }}
                noRedux
                relativeInner
                daysLimit={30}
              />
              <div className="InternalTransfer--onlyExecutedField">
                <Checkbox checked={onlyExecutedChecked} onClick={handeToggleOnlyExecutedField} />
                <FormattedMessage id="justSetOnlyExecuted" />
              </div>
              <DefaultButton
                textId="justExport"
                type="button"
                filled
                disabled={!selectedTimePeriod}
                onClick={() => {
                  setIsExportMenuModalOpen(false);
                  createBackOfficePaymentExport({
                    exportsType: 'internal_transfer_export',
                    pushToExportsFunc,
                    ext: exportExt,
                    onlyExecuted: onlyExecutedChecked,
                    timePeriod: selectedTimePeriod,
                  });
                }}
              />
            </BasicModal>
          </>
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={location.search ? 'noTransfersMatchingTheParameters' : 'noInternalTransfers'}
          />
        )}
        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </PageContentPreloader>
    </PageLayout>
  );
};

InternalTransfersPage.propTypes = {
  internalTransfers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  internalTransfersAreLoaded: PropTypes.bool.isRequired,
  getInternalTransfers: PropTypes.func.isRequired,
  createBackOfficePaymentExport: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  hasPermsToExport: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

InternalTransfersPage.defaultProps = {
  theme: {},
};

export default compose(
  injectIntl,
  withRouter,
  withTheme(),
  connect(
    state => ({
      internalTransfers: state.backoffice.transfers.internalTransfers,
      internalTransfersAreLoaded: state.backoffice.transfers.internalTransfersAreLoaded,
      features: state.interfaceConfig.features,
      hasPermsToExport: state.user.profile.perms.manageInternalTransferExports,
    }),
    {
      getInternalTransfers: data => getInternalTransfers.request(data),
      createBackOfficePaymentExport: ({ exportsType, pushToExportsFunc, ext, timePeriod, onlyExecuted }) =>
        createBackOfficePaymentExport.request({ exportsType, pushToExportsFunc, ext, timePeriod, onlyExecuted }),
    }
  )
)(InternalTransfersPage);
