import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .HistoryTable {
    :global(img) {
      width: 35px;
      min-width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    span {
      font-size: 13px !important;
      text-wrap: wrap;
    }

    &__fullName,
    &__performedBy {
      font-weight: 500;
    }

    :global(.ReactTable) {
      .rt-thead,
      .rt-tbody {
        .rt-tr {
          display: grid;
          gap: 8px;
          grid-template-columns: 50px 2fr 2fr 5fr 1fr;
        }
      }

      .rt-tbody {
        .rt-td {
          width: 100% !important;
        }
      }
    }

    :global(.ResponsiveTable) {
      .ResponsiveTable__tbody {
        .ResponsiveTable__row .ResponsiveTable__preview-main {
          display: grid;
          gap: 8px;
          grid-template-columns: 50px 1fr 1fr 4fr;
        }
      }

      .ResponsiveTable__thead {
        display: grid;
        gap: 8px;
        grid-template-columns: 50px 1fr 1fr 4fr;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .HistoryTable {
    }
  `;
};

export { staticStyles, getDynamicStyles };
