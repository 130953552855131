import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { closeLightBox, closeModal, openLightBox } from 'interface/user/_redux/actions';
import { EditIcon } from 'common/icons';
import { DefaultButton, StatusCell, FileField, LightBoxImage, BasicModal, ActionsButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noContentImage from 'common/images/noDocument.svg';
import imageDownload from 'common/images/documentDownload.png';
import { verifyDepositProof } from 'backoffice/payments/_redux';
import classNames from 'classnames';
import { UserNoInfoPanel } from '../../../_components';

import RejectDocumentForm from '../RejectDocumentForm';

import { verifyUserDocument, rejectUserDocument, uploadUserDocument } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';
import imagePDF from './imagePDF.svg';
import imageMSWord from './imageMSWord.svg';

const UserDocument = ({
  min,
  doc,
  documentId,
  user,
  titleId,
  statusId,
  file,
  verifyDepositProof,
  verifyUserDocument,
  rejectUserDocument,
  name,
  inputId,
  depositId,
  fileNameId,
  uploadError,
  uploadUserDocument,
  documentVerifyIsLoading,
  documentRejectIsLoading,
  openLightBox,
  profile,
  modals,
  closeModal,
  isProof,
  isDeposit,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const IE = window.navigator && window.navigator.msSaveOrOpenBlob;

  const imageExtensions = {
    'image/png': '.png',
    'image/jpeg': '.jpeg',
  };

  const documentExtensions = {
    'application/pdf': '.pdf',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  };

  const extensions = { ...imageExtensions, ...documentExtensions };

  const document = {
    url: '',
    name: '',
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleRejectDocumentVerification = () => {
    toggleModal();
  };

  if (file) {
    document.url = URL.createObjectURL(file);
    document.name = titleId + extensions[file.type];
  }

  const downloadDocument = () => {
    window.navigator.msSaveOrOpenBlob(file, document.name);
  };

  const DocumentsDownloadBlock = () => {
    if (IE) {
      return (
        <button type="button" onClick={() => downloadDocument()}>
          <img src={imageDownload} alt="Uptrader" />
        </button>
      );
    }

    const pdfType = 'application/pdf';
    const docType = 'application/msword';
    const docXType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    const getIcon = () => {
      switch (file.type) {
        case pdfType:
          return imagePDF;
        case docType:
        case docXType:
          return imageMSWord;
        default:
          return imageDownload;
      }
    };

    return (
      <a
        href={document.url}
        download={file.type !== 'application/pdf' && document.name}
        target="_blank"
        rel="noopener noreferrer">
        <img
          className={classNames({
            pdf: file.type === pdfType,
            msword: file.type === docType || file.type === docXType,
          })}
          src={getIcon()}
          alt="Uptrader"
        />
      </a>
    );
  };

  const handleVerifyDocument = (id, documentId) => {
    if (isProof) {
      verifyDepositProof(depositId);
    } else {
      verifyUserDocument(id, documentId);
    }
  };

  return (
    <div
      className={classNames('UserDocument__inner', {
        'UserDocument--min': min,
      })}>
      <div className="UserDocument__photo-inner">
        {file && statusId !== 'rejected' ? (
          statusId !== 'notUploaded' && (
            <div
              className={classNames('UserDocument__download-inner', {
                'UserDocument__download-inner--document': !Object.keys(imageExtensions).includes(file.type),
              })}>
              <div
                className={classNames('UserDocument__icon-status', {
                  'UserDocument__icon-status--verified': statusId === 'verified',
                  'UserDocument__icon-status--unverified': statusId === 'unverified',
                })}
              />
              {Object.keys(imageExtensions).includes(file.type) ? (
                <LightBoxImage
                  className="UserDocument__lightBoxImage"
                  key={1}
                  openLightBox={() => openLightBox(1, [document.url])}
                  image={document.url}
                  titleId={document.name}
                />
              ) : (
                <>
                  <DocumentsDownloadBlock />
                  {/* <FormattedMessage id="downloadDocument" /> */}
                </>
              )}
            </div>
          )
        ) : (
          <div className="UserDocument__upload-inner">
            {profile.perms.actionUser ? (
              <Field
                component={FileField}
                sendHandler={(_, file) => uploadUserDocument({ id: depositId, file, isDeposit })}
                name={name}
                formatNameId="documentsFormats"
                fileNameId={fileNameId}
                accept={Object.keys(extensions).join(',')}
                multiple={false}
                inputId={inputId}
              />
            ) : (
              <UserNoInfoPanel image={noContentImage} textId="boNoDocument" />
            )}
          </div>
        )}
      </div>

      {statusId === 'unverified' && profile.perms.actionUser && (
        <div className="UserDocument__buttons-inner">
          <DefaultButton
            onClick={() => handleVerifyDocument(user.id, documentId)}
            textId="boVerify"
            butColor="success"
            loading={documentVerifyIsLoading[documentId]}
            disabled={documentVerifyIsLoading[documentId] || documentRejectIsLoading[documentId]}
          />
          <DefaultButton
            onClick={() => handleRejectDocumentVerification(user.id, documentId)}
            textId="boReject"
            butColor="error"
            loading={documentRejectIsLoading[documentId]}
            disabled={documentVerifyIsLoading[documentId] || documentRejectIsLoading[documentId]}
          />
        </div>
      )}

      {statusId === 'verified' && (
        <div className="UserDocument__verified-inner">
          <div className="UserDocument__status">
            <StatusCell statusCode={statusId} />
          </div>
          <ActionsButton edit onClickFunc={() => handleRejectDocumentVerification(user.id, documentId)}>
            <EditIcon />
          </ActionsButton>
        </div>
      )}

      {uploadError && <span className="UserDocument__error">{uploadError}</span>}

      <BasicModal
        captionId="boRejectDocument"
        descriptionId="boRejectDocumentDescription"
        isOpen={modals[doc]}
        onRequestClose={() => closeModal(doc)}>
        {modals[doc] && (
          <DefaultButton
            onClick={() => rejectUserDocument(user.id, documentId)}
            textId="boReject"
            butColor="error"
            loading={documentRejectIsLoading[documentId]}
            disabled={documentVerifyIsLoading[documentId] || documentRejectIsLoading[documentId]}
          />
        )}
      </BasicModal>

      <BasicModal isOpen={isOpen} captionId="createRejectNotification" onRequestClose={toggleModal}>
        <RejectDocumentForm
          depositId={depositId}
          id={user.id}
          documentId={documentId}
          isProof={isProof}
          toggleModal={toggleModal}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

UserDocument.propTypes = {
  min: PropTypes.bool,
  doc: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  titleId: PropTypes.string.isRequired,
  documentId: PropTypes.number.isRequired,
  inputId: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  verifyUserDocument: PropTypes.func.isRequired,
  rejectUserDocument: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fileNameId: PropTypes.string.isRequired,
  uploadError: PropTypes.string,
  uploadUserDocument: PropTypes.func.isRequired,
  documentVerifyIsLoading: PropTypes.object.isRequired,
  documentRejectIsLoading: PropTypes.object.isRequired,
  openLightBox: PropTypes.func,
  profile: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  verifyDepositProof: PropTypes.func,
  isProof: PropTypes.bool,
  isDeposit: PropTypes.bool,
  depositId: PropTypes.number,
  theme: PropTypes.object,
};

UserDocument.defaultProps = {
  min: false,
  file: null,
  uploadError: '',
  openLightBox: null,
  isProof: false,
  isDeposit: false,
  depositId: null,
  verifyDepositProof: null,
  theme: {},
};

const ThemedUserDocument = withTheme()(
  connect(
    state => ({
      profile: state.user.profile,
      documentVerifyIsLoading: state.backoffice.users.documentVerifyIsLoading,
      documentRejectIsLoading: state.backoffice.users.documentRejectIsLoading,
      lightBoxCurrentImage: state.user.lightBoxCurrentImage,
      lightBoxImages: state.user.lightBoxImages,
      modals: state.user.modals,
    }),
    {
      verifyUserDocument: (id, documentId) => verifyUserDocument.request(id, documentId),
      rejectUserDocument: (id, documentId) => rejectUserDocument.request(id, documentId),
      uploadUserDocument: data => uploadUserDocument.request(data),
      verifyDepositProof: id => verifyDepositProof.request(id),
      openLightBox: (id, lightBoxImages) => openLightBox(id, lightBoxImages),
      closeLightBox,
      closeModal: name => closeModal(name),
    }
  )(UserDocument)
);

export default ThemedUserDocument;
export { UserDocument };
