import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, useLocation } from 'react-router-dom';
import { login } from 'auth/login/_redux';

const CheckAuthHOC = WrappedComponent => {
  const CheckAuthWrapped = ({
    profile,
    authorized,
    profileIsLoaded,
    forceVerification,
    lang,
    isEmailConfirmationEnabled,
    dispatch,
    partnershipConfig,
    ...props
  }) => {
    const { partnerCodeParamNames } = partnershipConfig;
    const token = localStorage.getItem('jwtToken');
    const isVerified = profile && profile.status !== 'incomplete';
    const location = useLocation();
    const query = useMemo(() => queryString.parse(location.search), [location.search]);

    useEffect(() => {
      if ((query.broker_token || query.user_token || query.oneoff_token) && location.pathname === '/') {
        login(query, dispatch);
      }
    }, [dispatch, location.pathname, query]);

    const requestedPageIsAuth = location.pathname.includes('/auth');
    const requestedPageIsWidgets = location.pathname.includes('/widgets');
    const requestedPageIsWelcomeSetup = location.pathname.includes('/welcome-setup');
    const allowedRoutesIfForceVerificationIsEnabled = [
      '/documents',
      '/personal_profile',
      '/support',
      '/mail',
      '/change_password',
    ];
    const requestedPageIsAllowedBeforeVerification = allowedRoutesIfForceVerificationIsEnabled.some(route =>
      location.pathname.includes(route)
    );

    const isTfaSetupRequired = Boolean(
      profile && !profile?.tfaSettings?.enabled && profile?.tfaSettings?.setupRequired
    );

    const partnerCodeParamName = Object.entries(queryString.parse(location.search)).find(([paramKey]) =>
      partnerCodeParamNames.some(param => param === paramKey)
    )?.[0];

    const partnerCode = query[partnerCodeParamName];

    if (partnerCode) {
      sessionStorage.setItem('partnerCode', partnerCode);
      localStorage.setItem('partnerCode', partnerCode);
    }

    if (token && authorized) {
      if (isTfaSetupRequired && !location.pathname.includes('change_password')) {
        return <Redirect to="/change_password" />;
      }

      if (requestedPageIsAuth) {
        return <Redirect to="/" />;
      }

      if (
        profileIsLoaded &&
        !isTfaSetupRequired &&
        !isVerified &&
        forceVerification &&
        !requestedPageIsAllowedBeforeVerification
      ) {
        if (window.location.hostname === 'my.spacemarkets.io') {
          return <Redirect to="/personal_profile" />;
        }

        return <Redirect to="/documents" />;
      }
      return <WrappedComponent {...props} key={lang} />;
    }

    if (requestedPageIsAuth || requestedPageIsWidgets || requestedPageIsWelcomeSetup) {
      return <WrappedComponent {...props} key={lang} />;
    }

    return <Redirect to="/auth/login" />;
  };

  CheckAuthWrapped.propTypes = {
    authorized: PropTypes.bool.isRequired,
    lang: PropTypes.string,
    profile: PropTypes.object,
    profileIsLoaded: PropTypes.bool.isRequired,
    forceVerification: PropTypes.bool.isRequired,
    isEmailConfirmationEnabled: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  CheckAuthWrapped.defaultProps = {
    profile: {},
    lang: 'no-lang',
  };

  return CheckAuthWrapped;
};

CheckAuthHOC.propTypes = {
  WrappedComponent: PropTypes.element,
};

export default compose(
  withRouter,
  connect(
    state => ({
      authorized: state.auth.login.authorized,
      lang: state.locales && state.locales.lang,
      forceVerification: state.interfaceConfig.kycSettings.force,
      profile: state.user.profile,
      profileIsLoaded: state.user.profileIsLoaded,
      isEmailConfirmationEnabled: state.interfaceConfig.emailConfirmation.enabled,
      partnershipConfig: state.interfaceConfig.partnership,
    }),
    dispatch => ({
      dispatch,
    })
  ),
  CheckAuthHOC
);
