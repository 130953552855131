import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_GEONAME_CONTACT_BY_GID = createRequestTypes('GET_GEONAME_CONTACT_BY_GID');
export const getGeonameContactByGID = {
  request: gid => action(GET_GEONAME_CONTACT_BY_GID[REQUEST], { gid }),
  success: data => action(GET_GEONAME_CONTACT_BY_GID[SUCCESS], { data }),
  failure: error => action(GET_GEONAME_CONTACT_BY_GID[FAILURE], { error }),
};

export const crmSetUserName = createFormAction('CRM_SET_USER_NAME');

export const crmSetUserManager = createFormAction('CRM_SET_USER_MANAGER');

export const crmSetUserIBManager = createFormAction('CRM_SET_USER_IB_MANAGER');

export const crmSetUserPartnerCode = createFormAction('CRM_SET_USER_PARTNER_CODE');

export const GET_USER_CONTACTS = createRequestTypes('GET_USER_CONTACTS');
export const getUserContacts = {
  request: search => action(GET_USER_CONTACTS[REQUEST], { search }),
  success: data => action(GET_USER_CONTACTS[SUCCESS], { data }),
  failure: error => action(GET_USER_CONTACTS[FAILURE], { error }),
};

export const GET_CRM_USER_DATA = createRequestTypes('GET_CRM_USER_DATA');
export const getCrmUserData = {
  request: (userId, noGeonamesFormat) => action(GET_CRM_USER_DATA[REQUEST], { userId, noGeonamesFormat }),
  success: data => action(GET_CRM_USER_DATA[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_DATA[FAILURE], { error }),
};

export const GET_CRM_USER_ACCOUNTS = createRequestTypes('GET_CRM_USER_ACCOUNTS');
export const getCrmUserAccounts = {
  request: data => action(GET_CRM_USER_ACCOUNTS[REQUEST], data),
  success: data => action(GET_CRM_USER_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_ACCOUNTS[FAILURE], { error }),
};

export const GET_CRM_USER_ACCOUNTS_PREFETCH = createRequestTypes('GET_CRM_USER_ACCOUNTS_PREFETCH');
export const getCrmUserAccountsPrefetch = {
  request: data => action(GET_CRM_USER_ACCOUNTS_PREFETCH[REQUEST], data),
  success: data => action(GET_CRM_USER_ACCOUNTS_PREFETCH[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_ACCOUNTS_PREFETCH[FAILURE], { error }),
};

export const GET_CRM_USER_ACCOUNT_DATA = createRequestTypes('GET_CRM_USER_ACCOUNT_DATA');
export const getCrmUserAccountData = {
  request: data => action(GET_CRM_USER_ACCOUNT_DATA[REQUEST], { ...data }),
  success: data => action(GET_CRM_USER_ACCOUNT_DATA[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_ACCOUNT_DATA[FAILURE], { error }),
};

export const GET_CRM_ACCOUNT_TRADES = createRequestTypes('GET_CRM_ACCOUNT_TRADES');
export const getCrmAccountTrades = {
  request: data => action(GET_CRM_ACCOUNT_TRADES[REQUEST], { ...data }),
  success: data => action(GET_CRM_ACCOUNT_TRADES[SUCCESS], { data }),
  failure: error => action(GET_CRM_ACCOUNT_TRADES[FAILURE], { error }),
};

export const GET_CONTACT_FAVORITES = createRequestTypes('GET_CONTACT_FAVORITES');
export const getContactFavorites = {
  request: () => action(GET_CONTACT_FAVORITES[REQUEST]),
  success: data => action(GET_CONTACT_FAVORITES[SUCCESS], { data }),
  failure: error => action(GET_CONTACT_FAVORITES[FAILURE], { error }),
};

export const SET_CONTACT_FAVORITE_CONTACT_PAGE = createRequestTypes('SET_CONTACT_FAVORITE_CONTACT_PAGE');
export const setContactFavorite = {
  request: id => action(SET_CONTACT_FAVORITE_CONTACT_PAGE[REQUEST], { id }),
  success: data => action(SET_CONTACT_FAVORITE_CONTACT_PAGE[SUCCESS], { data }),
  failure: error => action(SET_CONTACT_FAVORITE_CONTACT_PAGE[FAILURE], { error }),
};

export const DELETE_CONTACT_FAVORITE_CONTACT_PAGE = createRequestTypes('DELETE_CONTACT_FAVORITE_CONTACT_PAGE');
export const deleteContactFavorite = {
  request: id => action(DELETE_CONTACT_FAVORITE_CONTACT_PAGE[REQUEST], { id }),
  success: data => action(DELETE_CONTACT_FAVORITE_CONTACT_PAGE[SUCCESS], { data }),
  failure: error => action(DELETE_CONTACT_FAVORITE_CONTACT_PAGE[FAILURE], { error }),
};

export const GET_CRM_USER_LOGS = createRequestTypes('GET_CRM_USER_LOGS');
export const getCrmUserLogs = {
  request: data => action(GET_CRM_USER_LOGS[REQUEST], { ...data }),
  success: data => action(GET_CRM_USER_LOGS[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_LOGS[FAILURE], { error }),
};

export const GET_CRM_USER_FEED = createRequestTypes('GET_CRM_USER_FEED');
export const getCrmUserFeed = {
  request: data => action(GET_CRM_USER_FEED[REQUEST], { ...data }),
  success: data => action(GET_CRM_USER_FEED[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_FEED[FAILURE], { error }),
};

export const GET_CRM_USER_PAYMENTS = createRequestTypes('GET_CRM_USER_PAYMENTS');
export const getCrmUserPayments = {
  request: data => action(GET_CRM_USER_PAYMENTS[REQUEST], { ...data }),
  success: data => action(GET_CRM_USER_PAYMENTS[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_PAYMENTS[FAILURE], { error }),
};

export const GET_CRM_USER_INTERNAL_TRANSFERS = createRequestTypes('GET_CRM_USER_INTERNAL_TRANSFERS');
export const getCrmUserInternalTransfers = {
  request: data => action(GET_CRM_USER_INTERNAL_TRANSFERS[REQUEST], { ...data }),
  success: data => action(GET_CRM_USER_INTERNAL_TRANSFERS[SUCCESS], { data }),
  failure: error => action(GET_CRM_USER_INTERNAL_TRANSFERS[FAILURE], { error }),
};

export const GET_SALES_STATUSES = createRequestTypes('GET_SALES_STATUSES');
export const getSalesStatuses = {
  request: () => action(GET_SALES_STATUSES[REQUEST]),
  success: data => action(GET_SALES_STATUSES[SUCCESS], { data }),
  failure: error => action(GET_SALES_STATUSES[FAILURE], { error }),
};
export const crmEditContact = createFormAction('CRM_EDIT_CONTACT');

export const GET_CONTACT_NOTES = createRequestTypes('GET_CONTACT_NOTES');
export const getContactNotes = {
  request: id => action(GET_CONTACT_NOTES[REQUEST], { id }),
  success: data => action(GET_CONTACT_NOTES[SUCCESS], { data }),
  failure: error => action(GET_CONTACT_NOTES[FAILURE], { error }),
};

export const createContactDocument = createFormAction('CREATE_CONTACT_DOCUMENT');
export const createContactNote = createFormAction('CREATE_CONTACT_NOTE');
export const editContactNote = createFormAction('EDIT_CONTACT_NOTE');
export const deleteContactNote = createFormAction('DELETE_CONTACT_NOTE');

export const REMOVE_CONTACT_NOTE_DOCUMENT = createRequestTypes('REMOVE_CONTACT_NOTE_DOCUMENT');
export const removeContactNoteDocument = {
  request: data => action(REMOVE_CONTACT_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(REMOVE_CONTACT_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(REMOVE_CONTACT_NOTE_DOCUMENT[FAILURE], { error }),
};

export const REMOVE_FEED_NOTE_DOCUMENT = createRequestTypes('REMOVE_FEED_NOTE_DOCUMENT');
export const removeFeedNoteDocument = {
  request: data => action(REMOVE_FEED_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(REMOVE_FEED_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(REMOVE_FEED_NOTE_DOCUMENT[FAILURE], { error }),
};

export const GET_CONTACT_NOTE_DOCUMENT = createRequestTypes('GET_CONTACT_NOTE_DOCUMENT');
export const getContactNoteDocument = {
  request: data => action(GET_CONTACT_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(GET_CONTACT_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(GET_CONTACT_NOTE_DOCUMENT[FAILURE], { error }),
};

export const updateFeedCrmTask = createFormAction('UPDATE_FEED_CRM_TASK');
export const ADD_CONTACT_TAG = createRequestTypes('ADD_CONTACT_TAG');
export const addContactTag = {
  request: data => action(ADD_CONTACT_TAG[REQUEST], data),
  success: data => action(ADD_CONTACT_TAG[SUCCESS], { data }),
  failure: error => action(ADD_CONTACT_TAG[FAILURE], { error }),
};

export const DELETE_CONTACT_TAG = createRequestTypes('DELETE_CONTACT_TAG');
export const deleteContactTag = {
  request: data => action(DELETE_CONTACT_TAG[REQUEST], data),
  success: data => action(DELETE_CONTACT_TAG[SUCCESS], { data }),
  failure: error => action(DELETE_CONTACT_TAG[FAILURE], { error }),
};
