/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import ProvidersPopup from 'backoffice/users/_components/ProvidersPopup';
import classNames from 'classnames';

import queryString from 'query-string';
import { useMediaQuery } from 'react-responsive';

import { UpdatedCRMTable, NewCRMPagination } from 'crm/_newComponents';

import { PageContentPreloader, DateCell, MoneyCell, ActionsButton } from 'common/components';
import IconPhone from './iconPhone';
import Tags from '../Tags';
import NewFullNameCell from './NewFullNameCell/NewFullNameCell';
import SelectCheckbox from '../SelectCheckbox';

import { getContacts, getContactsFavorites, crmSetSelectedContactsIds } from '../../_redux';

import { staticStyles } from './style';

const NewContactsTable = ({
  getContacts,
  getContactsFavorites,
  onTagSelect,
  contacts,
  contactsAreLoaded,
  profilePerms,
  addons,
  location,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1050px)' });
  const newContactsTableRef = useRef();
  const dispatch = useDispatch();

  const query = useMemo(() => {
    const savedQuery = sessionStorage.getItem('fastFiltersQueryUser') || '';
    return { page_size: 10, ...queryString.parse(savedQuery) };
  }, [location.search]);

  const withClick2Call = !!profilePerms.managerViewUser && !!profilePerms.viewUser && addons.includes('click2call');

  const [isCallModalOpen, setCallModalOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState([0, 0]);
  const [modalId, setModalId] = useState(0);

  const getPopupPosition = (buttonPosition, tablePosition) => {
    const topDiff = isMobile ? 30 : 0;
    const rightDiff = isMobile ? 80 : 210;
    const top = buttonPosition.y - tablePosition.y + topDiff + 100;
    const right = buttonPosition.x - tablePosition.x - rightDiff;
    setButtonPosition([top, right]);
  };

  const toggleCallModalOpen = (id, elem, isHover) => {
    if (isHover) {
      setCallModalOpen(true);
    } else {
      setCallModalOpen(!isCallModalOpen);
    }

    if (id) {
      setModalId(id);
      getPopupPosition(elem.getBoundingClientRect(), newContactsTableRef.current.getBoundingClientRect());
    }
  };
  const totalDiff = contacts?.total_in_sum?.amount
    ? {
        // eslint-disable-next-line no-unsafe-optional-chaining
        amount: +contacts?.total_in_sum?.amount - +contacts?.total_out_sum?.amount,
        currency: contacts?.total_in_sum?.currency,
      }
    : null;

  const tableSetup = {
    columns: [
      {
        accessor: 'selectCheckBox',
        isCheckbox: true,
        Header: () => <SelectCheckbox header />,
        Cell: ({ rowData: { id } }) => <SelectCheckbox contactId={id} />,
      },
      {
        accessor: 'fullName',
        Header: 'justName',
        onTopInMobile: true,
        isSortable: true,
        Cell: ({ rowData: { id, fullName, isManager } }) => (
          <NewFullNameCell id={id} fullName={fullName} isManager={isManager} />
        ),
      },
      {
        accessor: 'lastSeen',
        isSortable: true,
        Header: 'justLastSeen',
        Cell: ({ rowData: { lastSeen } }) => (lastSeen ? <DateCell value={lastSeen} hideTime grey /> : '---'),
        removeInMobile: true,
      },
      {
        accessor: 'totalIn',
        isSortable: true,
        Header: 'justIn',
        HeaderTop: <MoneyCell heightMaxContent crmStyle value={contacts.total_in_sum} />,
        Cell: ({ rowData: { totalIn } }) => <MoneyCell crmStyle heightMaxContent value={totalIn} />,
        removeInMobile: true,
      },
      {
        accessor: 'totalOut',
        isSortable: true,
        Header: 'justOut',
        HeaderTop: <MoneyCell crmStyle heightMaxContent value={contacts.total_out_sum} />,
        Cell: ({ rowData: { totalOut } }) => <MoneyCell crmStyle heightMaxContent value={totalOut} />,
        removeInMobile: true,
      },
      {
        accessor: 'totalDiff',
        isSortable: true,
        Header: 'justInAndOut',
        HeaderTop: <MoneyCell crmStyle heightMaxContent value={totalDiff} />,
        Cell: ({ rowData: { totalDiff } }) => <MoneyCell crmStyle heightMaxContent value={totalDiff} />,
        removeInMobile: true,
      },
      {
        Header: 'justBalance',
        accessor: 'balance',
        isSortable: true,
        Cell: ({ rowData: { balance } }) => <MoneyCell crmStyle heightMaxContent value={balance} />,
        removeInMobile: true,
      },
      {
        Header: () => {},
        isSortable: false,
        onTopInMobile: true,
        Cell: ({ rowData: { id } }) =>
          withClick2Call && (
            <ActionsButton
              onClickFunc={evt => {
                evt.stopPropagation();
                toggleCallModalOpen(id, evt.target);
              }}
              onMouseEnterFunc={evt => {
                evt.stopPropagation();
                toggleCallModalOpen(id, evt.target, true);
              }}>
              <IconPhone />
            </ActionsButton>
          ),
      },
    ],
    initSubRow: [
      {
        Header: 'justTags',
        Cell: ({ rowData: { id, tags } }) => <Tags tags={tags} onTagSelect={onTagSelect} id={id} />,
      },
      {
        Header: 'justLastComment',
        Cell: ({ rowData: { lastTask } }) => (lastTask?.comment ? lastTask?.comment : '---'),
      },
    ],
  };

  useEffect(() => {
    dispatch(crmSetSelectedContactsIds([]));
    getContacts({ search: query });
  }, [query]);

  useEffect(() => {
    getContactsFavorites();
  }, []);

  return (
    <div className={classNames('NewContactsTable')} ref={newContactsTableRef}>
      <PageContentPreloader ready={contactsAreLoaded} type="crmTable" noPadding>
        <UpdatedCRMTable data={contacts.results} tableSetup={tableSetup} whereOpenSubRowIconIndex={1} />
        {!!contacts.count && <NewCRMPagination count={contacts.count} />}
      </PageContentPreloader>
      {isCallModalOpen ? (
        <ProvidersPopup id={modalId} buttonPosition={buttonPosition} toggleModalOpen={toggleCallModalOpen} />
      ) : null}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

NewContactsTable.propTypes = {
  contacts: PropTypes.object.isRequired,
  contactsAreLoaded: PropTypes.bool.isRequired,
  getContacts: PropTypes.func.isRequired,
  getContactsFavorites: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      contacts: state.crm.contacts.contacts,
      contactsAreLoaded: state.crm.contacts.contactsAreLoaded,
      profilePerms: state.user.profile.perms,
      addons: state.interfaceConfig.addons,
    }),
    {
      getContacts: search => getContacts.request(search),
      getContactsFavorites: () => getContactsFavorites.request(),
    }
  )
)(NewContactsTable);
