import { createFormAction } from 'redux-form-saga';
import { action } from 'redux-config/reduxHelpers';

export const requestChangeTfaType = createFormAction('REQUEST_CHANGE_TFA_TYPE');
export const resetTfaType = createFormAction('RESET_TFA_TYPE');
export const setTfaType = createFormAction('SET_TFA_TYPE');
export const verifyTfaType = createFormAction('VERIFY_TFA_TYPE');
export const verifyWithdrawalRequest = createFormAction('VERIFY_WITHDRAWAL_REQUEST');
export const verifyWithdrawalResend = createFormAction('VERIFY_WITHDRAWAL_RESEND');

export const SET_EMAIL_VERIFY_STEP = 'SET_EMAIL_VERIFY_STEP';
export const setEmailVerifyStep = () => action(SET_EMAIL_VERIFY_STEP);

export const SET_INITIAL_EMAIL_VERIFY_STEP = 'SET_INITIAL_EMAIL_VERIFY_STEP';
export const setInitialEmailVerifyStep = () => action(SET_INITIAL_EMAIL_VERIFY_STEP);
