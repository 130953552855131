import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .Slider {
    * {
      outline: none;
    }
    .slick-slide {
      > div {
        display: flex;
        flex-wrap: wrap;
      }
      .SliderItem {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        @media screen and (max-width: 768px) and (max-height: 400px) {
          min-height: calc(100vh - 50px);
        }
        .LightBoxGallery__pdf {
          padding: 10px;
          margin: 0 40px;
          transition: all 0.3s ease;
          img {
            height: 5rem;
          }
          span {
            display: block;
            word-break: break-all;
            white-space: normal;
            font-size: 32px;
          }
          &:hover {
            cursor: pointer;
            background-color: rgba(29, 29, 29, 0.35);
          }
        }
      }
      button {
        cursor: default;
      }
      img {
        width: auto !important;
        height: auto;
        max-height: 90vh;
        max-width: 100% !important;
        margin: 0 auto;
        @include respond-to(md) {
          max-width: 75% !important;
          max-height: 75vh;
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      color: transparent;
      height: 120px;
      width: 40px;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        padding: 10px;
        transition: all 0.3s ease;
      }
    }
    .slick-disabled {
      opacity: 0;
    }
    .slick-prev {
      left: 10px;
      &:after {
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
    .slick-next {
      right: 10px;
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Slider {
      .slick-slide {
        > div {
          height: 100vh;
          min-height: 300px;
        }
      }
      .slick-arrow {
        &:after,
        &:before {
          transition: none;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Slider {
      .slick-arrow {
        &:after {
          border: solid ${colors.white.hex};
          border-width: 0 4px 4px 0;
        }
        &:hover {
          &:after {
            border: solid ${colors.primary.hex};
            border-width: 0 4px 4px 0;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
