import { getWalletAccountsList } from 'client/multiWallet/_redux';
import { DefaultButton, RadioButton, ReduxSelectField } from 'common/components';
import { FormMessageHOC } from 'common/HOCs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { changeRefundableFeeRecieveMethod } from '../../_redux/actions';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChangeRecieveRefundableFeeMethod = ({
  challengeId,
  accounts,
  isWalletAccountAddonEnabled,
  withdrawAccount,
  walletAccounts,
  getWalletAccounts,
  isMultiWalletAddonEnabled,
  baseValue,
  handleSubmit,
  submitting,
  invalid,
  error,
  dispatch,
}) => {
  console.log({ withdrawAccount });
  const [refundMethod, setRefundMethod] = useState(isWalletAccountAddonEnabled ? 'wallet' : 'tradingAccount');

  useEffect(() => {
    if (isMultiWalletAddonEnabled) {
      getWalletAccounts();
    }
  }, []);
  const submit = handleSubmit(values =>
    changeRefundableFeeRecieveMethod(
      {
        challengeId,
        withdrawAccount: refundMethod === 'wallet' ? values.walletAccount : values.withdrawAccount,
      },
      dispatch
    )
  );

  const accountsFiltered = useMemo(
    () => accounts.filter(acc => !acc.isDemo && !acc.isBlocked && !acc.loading),
    [accounts]
  );

  const accountsOptions = accountsFiltered.map(item => {
    const balance = baseValue === 'balance' ? item.balance : item.marginFree;
    return {
      label: `${item.accountTypeTitle} : ${item.login} ${balance ? `( ${balance.amount}${balance.currency} )` : ''}`,
      value: item.id,
    };
  });

  const walletAccountsOptions = walletAccounts.map(item => ({
    label: `${item.accountTypeTitle} : ${item.login} ${
      item.balance ? `( ${item.balance.amount} ${item.balance.currency} )` : ''
    }`,
    value: item.id,
  }));

  const isSubmitButtonDisabled = useMemo(() => {
    if (!withdrawAccount && refundMethod !== 'wallet') {
      return true;
    }

    return submitting;
  }, [withdrawAccount, invalid, submitting, refundMethod]);

  return (
    <form onSubmit={submit} className="ChangeRecieveRefundableFeeMethod">
      <div
        className="StartChallengeForm__RefundMethod"
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '25px' }}>
        <FM id={isHero ? 'justRefundMethodForChallengeHERO10X' : 'justRefundMethodForChallenge'}>
          {txt => <span className="challenge-text">{txt}:</span>}
        </FM>
        {isWalletAccountAddonEnabled && (
          <RadioButton
            name="refundMethod"
            textId="justToMyWallet"
            value="wallet"
            onChange={() => setRefundMethod('wallet')}
            checked={refundMethod === 'wallet'}
          />
        )}

        {isMultiWalletAddonEnabled && (
          <>
            <RadioButton
              name="paymentMethod"
              textId="justWalletTitle"
              value="wallet"
              onChange={() => setRefundMethod('wallet')}
              checked={refundMethod === 'wallet'}
            />
            {refundMethod === 'wallet' && (
              <Field
                component={ReduxSelectField}
                name="walletAccount"
                textId="justWalletTitle"
                options={walletAccountsOptions}
              />
            )}
          </>
        )}
        <RadioButton
          name="refundMethod"
          textId="justToMyTradingAccount"
          value="tradingAccount"
          onChange={() => setRefundMethod('tradingAccount')}
          checked={refundMethod === 'tradingAccount'}
        />
        {refundMethod === 'tradingAccount' && (
          <Field component={ReduxSelectField} name="withdrawAccount" textId="justAccount" options={accountsOptions} />
        )}
      </div>

      <div className="ChangeRecieveRefundableFeeMethod__button-inner">
        <DefaultButton
          textId="justChange"
          type="submit"
          disabled={submitting || isSubmitButtonDisabled}
          loading={submitting}
          filled
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
    </form>
  );
};

ChangeRecieveRefundableFeeMethod.defaultProps = {
  error: '',
};

const selector = formValueSelector('change-recieve-refundable-fee-method');
export default compose(
  FormMessageHOC,
  connect(
    state => {
      const withdrawAccount = selector(state, 'withdrawAccount') || {};
      return {
        withdrawAccount,
        isMultiWalletAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'walletAccounts'),
        walletAccounts: state.client.multiWallet.walletAccounts.filter(
          account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
        ),
      };
    },
    { getWalletAccounts: () => getWalletAccountsList.request() }
  ),
  reduxForm({
    form: 'change-recieve-refundable-fee-method',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
    enableReinitialize: true,
  })
)(ChangeRecieveRefundableFeeMethod);
