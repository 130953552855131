import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { PageLayout, PageContentPreloader, DefaultButton, BasicModal, NoItemsPanel } from 'common/components';
import noPartnersImage from 'common/images/noPartners.svg';

import { toggleCreateIssueModal } from 'client/support/_redux';
import IssueForm from 'client/_components/IssueForm';

import { getPartnerAccounts } from '../../../_redux';
import { getPartnershipWithdrawalAccounts, getPartnershipWithdrawalWalletAccounts } from '../../_redux';

import { PartnerAccountsTable, PartnerAccountWithdrawalForm } from '../../_components';

import { staticStyles } from './style';

const PartnershipAccountsPage = ({
  getPartnerAccounts,
  partnerAccounts,
  partnerAccountsIsLoaded,
  createIssueModalIsOpen,
  partnershipWithdrawalAccounts,
  partnershipWithdrawalWalletAccounts,
  getPartnershipWithdrawalWalletAccounts,
  isMultiWalletEnabled,
  getPartnershipWithdrawalAccounts,
  toggleCreateIssueModal,
  history,
  intl,
}) => {
  useEffect(() => {
    getPartnerAccounts();
  }, [getPartnerAccounts]);

  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(0);

  const toggleWithdrawalModal = () => setIsWithdrawalModalOpen(!isWithdrawalModalOpen);
  const notSimplePartnerAccounts = partnerAccounts.filter(item => item.partnershipSystem !== 'simple');

  const handleWithdrawClick = id => {
    setSelectedAccountId(id);
    getPartnershipWithdrawalAccounts(id);

    if (isMultiWalletEnabled) {
      getPartnershipWithdrawalWalletAccounts(id);
    }

    toggleWithdrawalModal();
  };

  return (
    <PageLayout captionSlug="clientAccounts">
      <div className="PartnershipAccountsPage__button-inner">
        {!!notSimplePartnerAccounts.length && (
          <DefaultButton textId="addPartnerAccount" onClick={toggleCreateIssueModal} filled plus />
        )}
      </div>

      <PageContentPreloader ready={partnerAccountsIsLoaded}>
        {notSimplePartnerAccounts.length ? (
          <PartnerAccountsTable
            data={notSimplePartnerAccounts}
            onWithdrawClick={id => handleWithdrawClick(id)}
            onRowClick={id => history.push(`/partnership/account/${id}`)}
          />
        ) : (
          <NoItemsPanel
            icon={noPartnersImage}
            captionSlug="noPartnerAccountsCreated"
            buttonSlug="addPartnerAccount"
            buttonPlus
            onButtonClick={toggleCreateIssueModal}
          />
        )}
      </PageContentPreloader>

      <BasicModal
        isOpen={createIssueModalIsOpen}
        captionId="createIssueModalHeader"
        onRequestClose={toggleCreateIssueModal}>
        <IssueForm
          initialValues={{
            title: intl.formatMessage({ id: 'createPartnerAccountSupportTicketTitle' }),
          }}
        />
      </BasicModal>

      <BasicModal
        isOpen={isWithdrawalModalOpen}
        captionId="clientWithdrawPartnerAccount"
        onRequestClose={toggleWithdrawalModal}>
        <PartnerAccountWithdrawalForm
          partnerAccountId={selectedAccountId}
          accounts={partnershipWithdrawalAccounts}
          walletAccounts={partnershipWithdrawalWalletAccounts}
          toggleWithdrawalModal={toggleWithdrawalModal}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

PartnershipAccountsPage.propTypes = {
  partnerAccounts: PropTypes.array.isRequired,
  partnerAccountsIsLoaded: PropTypes.bool.isRequired,
  getPartnerAccounts: PropTypes.func.isRequired,
  toggleCreateIssueModal: PropTypes.func.isRequired,
  partnershipWithdrawalAccounts: PropTypes.array.isRequired,
  getPartnershipWithdrawalAccounts: PropTypes.func.isRequired,
  getPartnershipWithdrawalWalletAccounts: PropTypes.func.isRequired,
  createIssueModalIsOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  partnershipWithdrawalWalletAccounts: PropTypes.array,
  isMultiWalletEnabled: PropTypes.bool,
};

PartnershipAccountsPage.defaultProps = {
  partnershipWithdrawalWalletAccounts: [],
  isMultiWalletEnabled: false,
};

export default connect(
  state => ({
    partnerAccounts: state.client.common.partnerAccounts,
    partnerAccountsIsLoaded: state.client.common.partnerAccountsIsLoaded,
    createIssueModalIsOpen: state.client.support.createIssueModalIsOpen,
    partnershipWithdrawalAccounts: state.client.partnership.partnershipWithdrawalAccounts,
    partnershipWithdrawalWalletAccounts: state.client.partnership.partnershipWithdrawalWalletAccounts,
    isMultiWalletEnabled: state.interfaceConfig.addons.includes('walletAccounts'),
  }),
  {
    getPartnerAccounts: () => getPartnerAccounts.request(),
    getPartnershipWithdrawalAccounts: partnerAccountId =>
      getPartnershipWithdrawalAccounts.request({ partnerAccountId }),
    getPartnershipWithdrawalWalletAccounts: partnerAccountId =>
      getPartnershipWithdrawalWalletAccounts.request({ partnerAccountId }),
    toggleCreateIssueModal: () => toggleCreateIssueModal(),
  }
)(injectIntl(PartnershipAccountsPage));
export { PartnershipAccountsPage };
