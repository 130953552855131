import React, { useMemo, useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { BasicModal, PanelTabs } from 'common/components';
import { setTfaType, verifyTfaType } from 'auth/twoStepAuth/_redux';
import { setAuthorized, resendOTPToken } from 'auth/login/_redux';
import TwoStepEmailForm from '../TwoStepEmailForm';
import TwoStepGoogleForm from '../TwoStepGoogleForm';
import TwoStepSuccess from '../TwoStepSuccess';
import { staticStyles, getDynamicStyles } from './style';

const TwoStepRegisterModal = ({ isOpen, tfAuthSettings, onRequestClose, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [captionTextId, setCaptionTextId] = useState('');

  const handleSuccessModalToggle = () => {
    setIsSuccessModalOpened(isOpened => !isOpened);
  };

  const onSuccess = message => {
    setCaptionTextId(message);
    handleSuccessModalToggle();
  };

  const handleSuccessModalClose = () => {
    handleSuccessModalToggle();
    dispatch(setAuthorized());
  };

  const handleTfaModalClose = () => {
    onRequestClose();
    dispatch(setAuthorized());
  };

  const activeTabSlug = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : 'email'),
    [location.search]
  );

  useEffect(() => {
    if (activeTabSlug === 'google') {
      dispatch(setTfaType.request({ otpType: 'totp' }));
    }
  }, [activeTabSlug]);

  return (
    <>
      <BasicModal
        modalClassName="TwoStepRegisterModal"
        captionId="authTwoStepAuthentication"
        isOpen={isOpen}
        onRequestClose={handleTfaModalClose}>
        {tfAuthSettings.modes?.length > 1 && (
          <FormattedMessage id="authChooseTwoStepMethod">
            {txt => <span className="TwoStepRegisterModal__caption">{txt}</span>}
          </FormattedMessage>
        )}
        <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
          {tfAuthSettings.modes?.map(mode => {
            if (mode === 'totp') {
              return (
                <TwoStepGoogleForm
                  key="google"
                  slug="google"
                  textId="authGoogleAuthenticator"
                  isRegister
                  onClose={onRequestClose}
                  onSubmit={verifyTfaType}
                  onSuccess={onSuccess}
                />
              );
            }

            if (mode === 'email') {
              return (
                <TwoStepEmailForm
                  key="email"
                  slug="email"
                  textId="authEmail"
                  isRegister
                  onResend={() => resendOTPToken.request()}
                  onClose={onRequestClose}
                  onGetCode={() => setTfaType.request({ otpType: 'email' })}
                  onSubmit={verifyTfaType}
                  onSuccess={onSuccess}
                />
              );
            }

            return null;
          })}
        </PanelTabs>
        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </BasicModal>
      <BasicModal
        isOpen={isSuccessModalOpened}
        captionId="authTwoStepAuthentication"
        onRequestClose={handleSuccessModalClose}>
        <TwoStepSuccess
          onClose={handleSuccessModalClose}
          successTextId="authTwoStepEnabled"
          captionTextId={captionTextId}
        />
      </BasicModal>
    </>
  );
};

TwoStepRegisterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  tfAuthSettings: PropTypes.object,
  theme: PropTypes.object,
};

TwoStepRegisterModal.defaultProps = {
  tfAuthSettings: {},
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    tfAuthSettings: state.interfaceConfig.tfAuthSettings,
    tfaSetError: state.auth.tfa.isSetTfaTypeError,
  }))
)(TwoStepRegisterModal);
export { TwoStepRegisterModal };
