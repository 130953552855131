import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { PageLayout, PageContentPreloader, BasicModal, NoItemsPanel } from 'common/components';

import noBalanceOperationImage from 'common/images/noPayments.svg';
import { withTheme } from 'common/styling/theme';
import { getPartnerAccount, getPartnerAccountCommissions } from '../../../_redux/actions';
import { getPartnershipWithdrawalAccounts, getPartnershipWithdrawalWalletAccounts } from '../../_redux';
import { PartnerAccountWithdrawalForm, PartnerAccountInfoPanel, PartnerAccountInfoTable } from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const PartnershipAccountPage = ({
  getPartnerAccount,
  getPartnerAccountCommissions,
  partnershipWithdrawalAccounts,
  partnershipWithdrawalWalletAccounts,
  getPartnershipWithdrawalAccounts,
  getPartnershipWithdrawalWalletAccounts,
  isMultiWalletEnabled,
  account,
  accountLoading,
  commissions,
  commissionsIsLoaded,
  partnerAccountIsLoaded,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { accountId } = match.params;
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(
    query => getPartnerAccountCommissions({ accountId, search: query }),
    [getPartnerAccountCommissions, accountId]
  );

  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    getPartnerAccount(accountId);
    getPartnerAccountCommissions({ accountId, search: query });
  }, [getPartnerAccount, getPartnerAccountCommissions, accountId]);
  /* eslint-enable */

  const toggleWithdrawalModal = () => setIsWithdrawalModalOpen(!isWithdrawalModalOpen);

  const handleWithdrawClick = () => {
    getPartnershipWithdrawalAccounts(accountId);

    if (isMultiWalletEnabled) {
      getPartnershipWithdrawalWalletAccounts(accountId);
    }

    toggleWithdrawalModal();
  };

  const handleReloadClick = () => {
    getPartnerAccount(accountId);
    getPartnerAccountCommissions({ accountId, search: query });
  };

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  return (
    <PageLayout
      captionSlug="partnerAccount"
      extraCaptionText={account && account.partnerCode ? ` #${account.partnerCode}` : ''}
      showReload
      onReloadClick={handleReloadClick}
      reloading={accountLoading}>
      <PageContentPreloader ready={partnerAccountIsLoaded} type="line">
        {!!account.id && (
          <>
            <PartnerAccountInfoPanel
              partnerCode={account.partnerCode}
              balance={account.balance}
              withdrawBalance={account.withdrawBalance}
              loading={accountLoading}
              loadingFailed={account.loadingFailed}
              onWithdrawClick={handleWithdrawClick}
              commissions={commissions}
              commissionsIsLoaded={commissionsIsLoaded}
              disabled={parseFloat(account.withdrawBalance.amount) <= 0}
            />
            <FormattedMessage id="boHistory">
              {txt => <h3 className="PartnershipAccountPage__caption">{txt}</h3>}
            </FormattedMessage>

            <div className="PartnershipAccountPage__table">
              <PageContentPreloader ready={commissionsIsLoaded} type="line">
                {commissions.count ? (
                  <PartnerAccountInfoTable
                    data={commissions}
                    loading={!commissionsIsLoaded}
                    location={location}
                    history={history}
                    getList={getList}
                    params={{ search: query }}
                    onRowClick={() => {}}
                  />
                ) : (
                  <NoItemsPanel icon={noBalanceOperationImage} captionSlug="youHaveNoBalanceOperationYet" />
                )}
              </PageContentPreloader>
            </div>
          </>
        )}
      </PageContentPreloader>

      <BasicModal
        isOpen={isWithdrawalModalOpen}
        captionId="clientWithdrawPartnerAccount"
        onRequestClose={toggleWithdrawalModal}>
        <PartnerAccountWithdrawalForm
          partnerAccountId={accountId}
          accounts={partnershipWithdrawalAccounts}
          walletAccounts={partnershipWithdrawalWalletAccounts}
          toggleWithdrawalModal={toggleWithdrawalModal}
        />
      </BasicModal>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

PartnershipAccountPage.propTypes = {
  getPartnerAccount: PropTypes.func.isRequired,
  getPartnerAccountCommissions: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  accountLoading: PropTypes.bool.isRequired,
  partnerAccountIsLoaded: PropTypes.bool.isRequired,
  partnershipWithdrawalAccounts: PropTypes.array.isRequired,
  getPartnershipWithdrawalAccounts: PropTypes.func.isRequired,
  getPartnershipWithdrawalWalletAccounts: PropTypes.func.isRequired,
  commissions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  commissionsIsLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  partnershipWithdrawalWalletAccounts: PropTypes.array,
  isMultiWalletEnabled: PropTypes.bool,
};

PartnershipAccountPage.defaultProps = {
  partnershipWithdrawalWalletAccounts: [],
  isMultiWalletEnabled: false,
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    (state, { match: { params } }) => {
      const account =
        state.client.common.partnerAccounts.find(account => account.id === Number(params.accountId)) ||
        state.client.common.partnerAccount;

      return {
        account,
        accountLoading: !!account.loading,
        partnerAccountIsLoaded: state.client.common.partnerAccountIsLoaded,
        commissions: state.client.common.partnerAccountCommissions,
        commissionsIsLoaded: state.client.common.partnerAccountCommissionsIsLoaded,
        partnershipWithdrawalAccounts: state.client.partnership.partnershipWithdrawalAccounts,
        partnershipWithdrawalWalletAccounts: state.client.partnership.partnershipWithdrawalWalletAccounts,
        isMultiWalletEnabled: state.interfaceConfig.addons.includes('walletAccounts'),
      };
    },
    {
      getPartnerAccount: accountId => getPartnerAccount.request(accountId),
      getPartnerAccountCommissions: data => getPartnerAccountCommissions.request(data),
      getPartnershipWithdrawalAccounts: partnerAccountId =>
        getPartnershipWithdrawalAccounts.request({ partnerAccountId }),
      getPartnershipWithdrawalWalletAccounts: partnerAccountId =>
        getPartnershipWithdrawalWalletAccounts.request({ partnerAccountId }),
    }
  )
)(PartnershipAccountPage);
export { PartnershipAccountPage };
