/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { PageContentPreloader, Tooltip } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';

import classNames from 'classnames';
import UserNoInfoPanel from '../UserNoInfoPanel';

import { getUserTagsClient } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';
import arrowTopKYC from './arrowTopKYC.svg';

const UserTagsPanelClient = ({ userId, getUserTagsClient, userTags, userTagsIsLoaded, theme }) => {
  const [openBlock, setOpenBlock] = useState(false);

  useEffect(() => {
    getUserTagsClient(userId);
  }, [getUserTagsClient, userId]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="UserTagsPanelClient">
      <div
        className={classNames('UserTagsPanelClient__open-close', {
          'UserTagsPanelClient__open-close--close': !openBlock,
        })}
        onClick={() => setOpenBlock(!openBlock)}
      />
      <div
        className={classNames('UserTagsPanelClient__head', {
          'UserTagsPanelClient__head--hide': !openBlock,
        })}>
        <FormattedMessage id="boTags">{txt => <h2>{txt}</h2>}</FormattedMessage>
      </div>
      <img
        className={classNames('UserTagsPanelClient__arrowTopKYC', {
          'UserTagsPanelClient__arrowTopKYC--hide': !openBlock,
        })}
        src={arrowTopKYC}
        alt="arrowTopKYC"
        onClick={() => {
          setOpenBlock(!openBlock);
        }}
      />
      <div
        className={classNames('UserTagsPanelClient__content', {
          'UserTagsPanelClient__content--hide': !openBlock,
        })}>
        <PageContentPreloader ready={userTagsIsLoaded} firstLaunchOnly type="boUserTags">
          {userTags.length ? (
            <>
              {userTags.map((tag, index) => (
                <Tooltip id={`UserTag__${index}`} textId={tag.description} key={index} noTranslate place="bottom">
                  <div className="UserTagsPanelClient__tag">
                    <p>{tag.name}</p>
                  </div>
                </Tooltip>
              ))}
            </>
          ) : (
            <UserNoInfoPanel image={noInfoImage} textId="boTagsNoInform" />
          )}
        </PageContentPreloader>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserTagsPanelClient.propTypes = {
  getUserTagsClient: PropTypes.func.isRequired,
  userTags: PropTypes.array.isRequired,
  userTagsIsLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

UserTagsPanelClient.defaultProps = {
  theme: {},
};

const ConnectedUserTagsPanelClient = connect(
  state => ({
    userTags: state.client.partnership.userTags,
    userTagsIsLoaded: state.client.partnership.userTagsIsLoaded,
  }),
  {
    getUserTagsClient: id => getUserTagsClient.request(id),
  }
)(UserTagsPanelClient);

export default withTheme()(ConnectedUserTagsPanelClient);
export { UserTagsPanelClient };
